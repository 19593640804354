import { useEffect, useState, useCallback, useMemo, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Pagination, Skeleton } from "@mui/material";
import { TopBarContext } from "../../providers/TopBarProvider";
import OrderItem from "../molecules/OrderItem";
import CustomNoRows from "../atoms/CustomNoRows";
import OrderService from "../../services/OrderService";
import OrderClient from "../molecules/OrderClient";

const Products = () => {
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [orders, setOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const [mySearch, setMySearch] = useState("");
  const [orderClient, setOrderClient] = useState(false);
  const { setTitle, setSearchOptions, setBtnPrimary } =
    useContext(TopBarContext);
  const orderService = useMemo(() => new OrderService(), []);
  const rowsPerPage = 25;
  const navigate = useNavigate();

  const getAll = useCallback(
    async (skipCount = 0, search) => {
      try {
        setIsLoading(true);
        setOrders([]);
        const res = await orderService.getAll(
          rowsPerPage,
          skipCount * rowsPerPage,
          search
        );
        const totalCount = res.data.result.totalCount;
        const items = res.data.result.items;

        if (items.length === 0) {
          return;
        }

        setCount(Math.ceil(totalCount / rowsPerPage));
        setOrders([...items]);
      } catch {
        setError(true);
      } finally {
        setIsLoading(false);
      }
    },
    [orderService, setOrders]
  );

  const onPagination = (_, value) => {
    setOrders([]);
    setPage(0);
    getAll(value - 1, mySearch);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    setTitle("Pedidos");
    setSearchOptions({
      action: () => null,
      hidden: true,
    });
    setBtnPrimary({
      label: "Agregar pedido",
      action: () => setOrderClient(true),
      icon: "plus",
    });
  }, [setTitle, setSearchOptions, setBtnPrimary]);

  useEffect(() => {
    getAll();
  }, [getAll]);

  const classes = {
    root: {
      display: "grid",
      gridTemplateColumns: "repeat(auto-fill, 335px)",
      gridGap: 20,
      justifyContent: "center",
      my: 8,
    },
    skeleton: {
      width: "100%",
      height: 311,
    },
    customNoRows: {
      gridColumn: "6 span",
    },
    pagination: {
      width: "fit-content",
      margin: "0 auto",
    },
  };

  return (
    <>
      <Box sx={classes.root}>
        {isLoading ? (
          new Array(rowsPerPage)
            .fill(1)
            .map((_, idx) => (
              <Skeleton
                animation="wave"
                key={`skeleton-${idx}`}
                variant="rounded"
                sx={classes.skeleton}
              />
            ))
        ) : orders.length === 0 ? (
          <Box sx={classes.customNoRows}>
            <CustomNoRows
              message={
                error
                  ? "Error intentando obtener los productos"
                  : "No hay productos registrados"
              }
            />
          </Box>
        ) : (
          orders.map((order) => (
            <OrderItem
              key={`order-${order.id}`}
              reference={String(order.id)}
              company={order.companyName}
              person={order.creatorUser.fullName}
              status={order.status}
              handleOpen={() => navigate(`/orders/order?id=${order.id}`)}
              handleDelete={null}
              handlePackages={() => navigate(`/orders/packages?id=${order.id}`)}
            />
          ))
        )}
      </Box>
      {count > 1 && (
        <Pagination
          count={count}
          defaultPage={page}
          sx={classes.pagination}
          color="primary"
          onChange={onPagination}
        />
      )}
      <OrderClient
        open={orderClient}
        handleClose={() => setOrderClient(false)}
      />
    </>
  );
};

export default Products;
