import { createContext, useState, useContext } from 'react';
import Toast from '../components/atoms/Toast';
import { DrawerContext } from './DrawerProvider';

export const ToastContext = createContext({})

const ToastProvider = ({ children }) => {

    const { drawerOpen } = useContext(DrawerContext)
    const [toastMessage, setToastMessage] = useState([])
    const [showToast, setShowToast] = useState(false)

    const onClose = (reason) => {
        if (reason === 'clickaway') {
            return;
        }
        
        setShowToast(false);
        setToastMessage([])
    }

    return <ToastContext.Provider value={{
        setToastMessage,
        setShowToast
    }} >
        {children}
        <Toast open={showToast} onClose={onClose} toasts={toastMessage} drawerOpen={drawerOpen} />
    </ToastContext.Provider>

}

export default ToastProvider