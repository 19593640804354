import HttpService from "./HttpService";
import { api } from "../common/enviroment";
import TokenService from "./TokenService";

export default class AuthService {
  httpService = new HttpService();
  tokenService = new TokenService();

  login = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await this.httpService.post(
          `${api.TOKEN_SERVICE}/Authenticate`,
          data
        );
        this.tokenService.setToken(res.data.result.accessToken);
        resolve(res);
      } catch (error) {
        reject(error);
      }
    });
  };

  logout = () => {
    this.tokenService.clearToken();
  };

  isAuthenticated = () => {
    const token = this.tokenService.getToken();

    let expiresAt = token
      ? (this.tokenService.getExpiresAt(token)
          ? this.tokenService.getExpiresAt(token)
          : 0) * 1000
      : 0;

    if (new Date().getTime() < expiresAt) {
      return true;
    }

    this.tokenService.clearToken();
    return false;
  };
}
