import HttpService from "./HttpService";
import { api } from "../common/enviroment";

export default class SessionService {
  httpService = new HttpService();

  getLoginInfo = () => {
    return this.httpService.get(`/services/app${api.SESSION_SERVICE}/GetCurrentLoginInformations`);
  };

}