import HttpService from "./HttpService";
import { api } from "../common/enviroment";

export default class ClientService {
  httpService = new HttpService();

  getAll = (maxResultCount, skipCount, search) => {
    return this.httpService.get(`/services/app${api.CLIENT_SERVICE}/GetAll?${maxResultCount ? "MaxResultCount="+maxResultCount+"&" : ""}${skipCount ? "SkipCount="+skipCount+"&" : ""}${search ? "Search="+search+"&" : ""}`);
  };

  get = (id) => {
    return this.httpService.get(`/services/app${api.CLIENT_SERVICE}/Get?id=${id}`);
  };

  delete = (id) => {
    return this.httpService.delete(`/services/app${api.CLIENT_SERVICE}/Delete?Id=${id}`);
  }

  deleteAll = (datas) => {
    const reqs = datas.map(data=>this.delete(data.id))
    const reqsWithError = reqs.map((req, i) => req.then(res => ({...res, description: datas[i].description})).catch(error => ({...error, description: datas[i].description})) )
    return this.httpService.all(reqsWithError)
  }

  create = (data) => {
    return this.httpService.post(`/services/app${api.CLIENT_SERVICE}/Create`, data);
  }

  update = (data) => {
    return this.httpService.put(`/services/app${api.CLIENT_SERVICE}/Update`, data);
  }

}