import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from "@mui/material/styles";
import Theme from "./styles/Theme";
import { RouterProvider } from "react-router-dom";
import { myRoutes } from "./utils/MyRoutes"
import "./utils/iconsLibrary"
import LoadingProvider from './providers/LoadingProvider';
import PermissionsProvider from './providers/PermissionsProvider';

function App() {
  return (
    <ThemeProvider theme={Theme}>
      <CssBaseline />
      <LoadingProvider>
        <PermissionsProvider>
          <RouterProvider router={myRoutes} />
        </PermissionsProvider>
      </LoadingProvider>
    </ThemeProvider>
  );
}

export default App;
