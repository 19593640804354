import { useEffect, useState, useCallback, useMemo, useContext } from 'react'
import {  
  Box,
  Pagination,
  Skeleton
} from '@mui/material'
import { TopBarContext } from "../../providers/TopBarProvider"
import { useNavigate } from 'react-router-dom'
import PriceListItem from '../molecules/PriceListItem'
import CustomNoRows from '../atoms/CustomNoRows'
import PriceListService from '../../services/PriceListService'
import { ToastContext } from "../../providers/ToastProvider"
import CreationModal from '../molecules/CreationModal'

const PricesLists = () => {
  const [ openModal, setOpenModal ] = useState(false)
  const [ count, setCount ] = useState(0)
  const [ prices, setPrices ] = useState([])
  const [ edit, setEdit ] = useState(null)
  const [ isLoading, setIsLoading ] = useState(true)
  const [ error, setError ] = useState(false)
  const { setTitle, setSearchOptions, setBtnPrimary } = useContext(TopBarContext)
  const { setShowToast, setToastMessage } = useContext(ToastContext)
  const priceListService = useMemo(()=> new PriceListService(), [])
  const navigate = useNavigate()
  const rowsPerPage = 25

  const getAll = useCallback(async(skipCount = 0) => {

    try {
      setIsLoading(true)
      setPrices([])
      const res = await priceListService.getAll(rowsPerPage, skipCount*rowsPerPage)
      const totalCount = res.data.result.totalCount
      const items = res.data.result.items

      if (items.length === 0) {
        return
      }

      setCount(Math.ceil(totalCount / rowsPerPage))
      setPrices([...items])

    } catch {
      setError(true)
    } finally {
      setIsLoading(false)
    }

  }, [priceListService, setPrices])

  const deletePricesList = (id, name, idx) => {

    return new Promise(async(resolve, reject)=>{
      try {
      
        const res = await priceListService.delete(id)
  
        if (!res.data.success) {
          return
        }
  
        setTimeout(()=>{
          setPrices(oldPrices=>{
            oldPrices.splice(idx,1)
            return [...oldPrices]
          })
        },1500)
  
        resolve()

      } catch {
        setToastMessage([
          {
            severity: "error",
            primary: `Error al intentar eliminar la lista ${name}`
          }
        ])
        setShowToast(true)
        reject()
      }
    })

  }

  const editPricesList = (price) => {
    setEdit(price)
    setOpenModal(true)
  }

  const showPrices = (price) => {
    navigate(`/price-lists/prices?id=${price.id}`, {
      state: {
        ...price
      }
    })
  }

  const showClients = (id) => {
    navigate(`/price-lists/clients?id=${id}`)
  }

  const onPagination = (_, value) => {
    setPrices([])
    getAll(value-1)
    window.scrollTo(0, 0)
  }

  useEffect(()=>{
    setTitle("Listas de precios")
    setSearchOptions({
      label: "Buscar Lista",
      action: ()=>null
    })
    setBtnPrimary({
      label: "Agregar Lista",
      action: ()=>{
        setEdit(null)
        setOpenModal(true)
      },
      icon: "plus"
    })
  },[setTitle, setSearchOptions, setBtnPrimary, navigate])

  useEffect(()=>{
    getAll()
  }, [getAll])

  const classes = {
    root: {
      display: "grid",
      gridTemplateColumns: "repeat(auto-fill, 350px)",
      gridGap: 20,
      justifyContent: "center",
      my: 8
    },
    skeleton: {
      width: "100%",
      maxWidth: 350,
      height: 173
    },
    customNoRows: {
      gridColumn: "6 span"
    },
    pagination: {
      width: "fit-content",
      margin: "0 auto"
    }
  }

  return <>
    <Box sx={classes.root} >
      {isLoading ?
       new Array(rowsPerPage).fill(1).map((_, idx)=>(
        <Skeleton animation="wave" key={`skeleton-${idx}`} variant="rounded" sx={classes.skeleton} />
      )):
      prices.length === 0 ?
      <Box sx={classes.customNoRows}>
        <CustomNoRows message={error ? "Error intentando obtener los precios" : "No hay listas de precios registrados"} />
      </Box>:
      prices.map((price, idx) => (
        <PriceListItem 
          key={`prices-list-${price.id}`}
          name={price.name} 
          handleDelete={()=>deletePricesList(price.id, price.name, idx)}
          handleEdit={()=>editPricesList(price)}
          handlePrices={()=>showPrices(price)}
          handleClients={()=>showClients(price.id)}
        />
      ))}
    </Box>
    {count > 1 &&
      <Pagination
        count={count} 
        defaultPage={1}
        defaultValue={1}
        sx={classes.pagination}
        color="primary"
        onChange={onPagination}
      />
    }
    <CreationModal 
      open={openModal}
      handleClose={()=>setOpenModal(false)}
      primaryKey="name"
      title="agregar lista de precios"
      label="Nombre de la lista"
      keyword="lista de precios"
      edit={edit}
      female={false}
      service={priceListService}
      setRows={setPrices}
    />
  </>
  
}

export default PricesLists