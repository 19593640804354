import HttpService from "./HttpService";
import { api } from "../common/enviroment";

export default class StockService {
  httpService = new HttpService();

  get = (id) => {
    return this.httpService.get(`/services/app${api.STOCK_SERVICE}/GetProductStock?${id ? "productId="+id+"&" : ""}`);
  };

  create = (data) => {
    return this.httpService.post(`/services/app${api.STOCK_SERVICE}/CreateProductStock`, data);
  }

  update = (data) => {
    return this.httpService.put(`/services/app${api.STOCK_SERVICE}/UpdateProductStock`, data);
  }

}