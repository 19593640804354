import HttpService from "./HttpService";
import { api } from "../common/enviroment";

export default class SublineService {
  httpService = new HttpService();

  getAll = (maxResultCount, skipCount, search) => {
    return this.httpService.get(
      `/services/app${api.ORDER_SERVICE}/GetAll?${
        maxResultCount ? "MaxResultCount=" + maxResultCount + "&" : ""
      }${skipCount ? "SkipCount=" + skipCount + "&" : ""}${
        search ? "Search=" + search + "&" : ""
      }`
    );
  };

  get = (id) => {
    return this.httpService.get(
      `/services/app${api.ORDER_SERVICE}/Get?orderId=${id}`
    );
  };

  create = (data) => {
    return this.httpService.post(
      `/services/app${api.ORDER_SERVICE}/Create`,
      data
    );
  };
}
