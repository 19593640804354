import { useEffect, useState, useContext } from "react";
import {
  Avatar,
  Box,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import { DataGrid, useGridApiRef } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { TopBarContext } from "../../providers/TopBarProvider";
import CustomNoRows from "../atoms/CustomNoRows";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { packageContext } from "../../providers/PackageProvider";

const NewPackage = () => {
  const apiRef = useGridApiRef();

  const { setTitle, setSearchOptions, setBtnPrimary } =
    useContext(TopBarContext);
  const {
    refOrder,
    packeds,
    companyName,
    personInChargeName,
    deletePack,
    createPackage,
  } = useContext(packageContext);

  const navigate = useNavigate();
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });
  const columns = [
    {
      field: "product",
      headerName: "Producto",
      minWidth: 200,
      flex: 1,
      sorting: false,
      renderCell: (item) => (
        <ListItemText
          sx={{
            pl: 1,
          }}
          primary={item.row.productRef}
          primaryTypographyProps={{
            fontSize: "1.1rem",
          }}
          secondary={item.row.productDesc}
        />
      ),
    },
    {
      field: "sizeDescription",
      headerName: "Talla",
      minWidth: 200,
      flex: 1,
      sorting: false,
      renderCell: (item) => (
        <Box pl={1} sx={{ width: "100%" }}>
          {item.row.sizeDescription ?? "Sin seleccionar"}
        </Box>
      ),
    },
    {
      field: "colorDescription",
      headerName: "Color",
      minWidth: 200,
      flex: 1,
      sorting: false,
      renderCell: (item) => (
        <Box pl={1} sx={{ width: "100%" }}>
          {item.row.colorDescription ?? "Sin seleccionar"}
        </Box>
      ),
    },
    {
      field: "quantity",
      headerName: "Cantidad",
      minWidth: 200,
      flex: 1,
      sorting: false,
      renderCell: (item) => (
        <Box pl={1} sx={{ width: "100%" }}>
          {item.row.quantity}
        </Box>
      ),
    },
    {
      field: "delete",
      headerName: "Acciones",
      sortable: false,
      width: 135,
      renderCell: (item) => {
        return (
          <IconButton
            onClick={() => deletePack(item.id)}
            sx={{ float: "right", ml: 2 }}
          >
            <Box
              component={FontAwesomeIcon}
              icon="trash"
              fontSize="1rem"
              color="error.main"
            />
          </IconButton>
        );
      },
    },
  ];

  useEffect(() => {
    setTitle("Paquete");
    setSearchOptions({
      action: () => null,
      hidden: true,
    });
    setBtnPrimary({
      label: "Empacar",
      action: createPackage,
      icon: "box",
    });
  }, [setTitle, setSearchOptions, setBtnPrimary, navigate, createPackage]);

  const classes = {
    root: {
      width: "100%",
      mt: 2,
    },
    stack: {
      padding: "0px 5px",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "flex-end",
      my: 1,
    },
    datagrid: {
      height: "70vh",
      fontSize: "1.1rem",
      ".MuiDataGrid-cell--editing input": {
        fontSize: "1.1rem",
      },
      ".MuiDataGrid-cell:focus,.MuiDataGrid-cell:focus-within": {
        outline: "none",
      },
    },
  };

  return (
    <Box sx={classes.root}>
      <Stack direction={"row"} justifyContent={"start"}>
        <ListItem sx={{ width: "fit-content" }}>
          <ListItemAvatar>
            <Avatar sx={{ width: 50, height: 50 }}>
              <Box
                component={FontAwesomeIcon}
                icon="address-book"
                fontSize="1.8rem"
              />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={companyName}
            secondary={personInChargeName}
            primaryTypographyProps={{
              fontSize: "1.4rem",
              fontWeight: "500",
            }}
            secondaryTypographyProps={{
              fontSize: "1rem",
            }}
          />
        </ListItem>
      </Stack>
      <Stack sx={classes.stack}>
        <Typography variant="h5" fontWeight={"bold"}>
          #{refOrder}
        </Typography>
        {/* <IconButton onClick={() => null}>
          <Box component={FontAwesomeIcon} icon="filter" fontSize="1.2rem" />
        </IconButton> */}
      </Stack>
      <DataGrid
        sx={classes.datagrid}
        apiRef={apiRef}
        columns={columns}
        rows={packeds}
        editMode="cell"
        getRowId={(row) => row.key}
        disableColumnMenu
        disableRowSelectionOnClick
        isRowSelectable={(item) => item.row.selectable}
        isCellEditable={(item) => item.row.selectable}
        pageSizeOptions={[10]}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        localeText={{
          toolbarColumns: "Columnas",
          toolbarColumnsLabel: "Seleccionar columnas",
          noRowsLabel: "Sin productos",
          footerRowSelected: (count) =>
            count !== 1
              ? `${count.toLocaleString()} filas seleccionadas`
              : `${count.toLocaleString()} fila seleccionada`,
          MuiTablePagination: {
            labelDisplayedRows: ({ from, to, count }) =>
              `${from} - ${to} de ${count}`,
          },
        }}
        slotProps={{
          pagination: {
            labelRowsPerPage: "Filas por página",
          },
        }}
        slots={{
          noRowsOverlay: () => (
            <CustomNoRows message={"No hay productos agregados"} />
          ),
          noResultsOverlay: () => (
            <CustomNoRows message={"No hay productos agregados"} />
          ),
        }}
      />
    </Box>
  );
};

export default NewPackage;
