import { Box, Paper, CircularProgress, Typography } from '@mui/material';

const Loading = ({ message }) => {

  const classes = {
    root: {
      display: "flex",
      width: "100%",
      height: "100vh",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center"
    },
    paper: {
      textAlign: "center",
      padding: 5
    }
  }

  return <Box sx={classes.root} >
    <Paper sx={classes.paper}>
      <CircularProgress size={55}  />
      <Typography fontSize="1.1rem" color="black" marginTop={2} >{message}</Typography>
    </Paper>
  </Box>
}

export default Loading