import { useEffect, useState } from 'react'
import {  
  Box,
  Paper,
  Typography,
  Chip,
  Divider,
  CircularProgress,
  Skeleton,
  Tooltip
} from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTheme } from "@mui/material/styles"
import UserPopoverOptions from './UserPopoverOptions'

const UserItem = ({ id, username, email, isActive, nRoles, identification, names, phone, handleDelete, handleEdit, handleReset, reset }) => {

  const [ myReset, setMyReset ] = useState(false)
  const [ deleting, setDeleting ] = useState(false)
  const [ showSkeleton, setShowSkeleton ] = useState(true)
  const theme = useTheme()

  const myHandleDelete = async() => {
    setDeleting(true)
    try {
      await handleDelete()
    } catch {
      setDeleting(false)
    }
  }

  useEffect(()=>{

    setMyReset(reset.includes(id))

  },[id, reset, setMyReset])

  const classes = {
    root: {
      width: "100%",
      // maxWidth: 360,
      borderRadius: "10px",
      padding: 1
    },
    itemHeader: {
      display: "flex",
      position: "relative",
      bgColor: theme.palette.myGray.main,
      backgroundColor: theme.palette.myGray.main,
      padding: theme.spacing(1,2),
      borderRadius: "10px"
    },
    itemAvatar: {
      width: 65,
      height: 65,
      borderRadius: 65,
      mr: 2
    },
    itemOptions: {
      position: "absolute",
      top: 0,
      right: 5,
      ml: 5
    },
    itemBody: {
      padding: 2
    },
    itemChip: {
      fontWeight: "bold",
      borderRadius: 2,
      fontSize: "1rem",
      marginBottom: 1
    },
    progress: {
      ml: 2, 
      verticalAlign: "text-bottom"
    },
    icon: {
      fontSize: 20,
      mr: 1,
      verticalAlign: "text-bottom"
    }
  }

  return <Paper elevation={2} sx={classes.root} >
    <Box sx={classes.itemHeader}>
      {showSkeleton && <Skeleton animation="wave" variant="circular" width={65} height={65} sx={[classes.itemAvatar, { position: "absolute" }]} />}
      <Box sx={classes.itemAvatar} onLoad={()=>setShowSkeleton(false)} component="img" src={`https://api.dicebear.com/6.x/thumbs/svg?backgroundColor=${theme.palette.primary.main.substring(1)}&seed=${id}-${username}`} />
      <Box>
        <Tooltip title={username} placement="top-start">
          <Typography component="h4" variant='h6' fontWeight="bold">{username.length > 14 ? username.substring(0,14).concat("...") : username}</Typography>
        </Tooltip>
        <Tooltip title={email} placement="bottom-start">
          <Typography component="p" variant='subtitle1'>
            {email.length > 30 ? email.substring(0,27).concat("...") : email}
          </Typography>
        </Tooltip>
      </Box>
      <UserPopoverOptions disabled={deleting || myReset} handleDelete={myHandleDelete} handleReset={handleReset} handleEdit={handleEdit} />
    </Box>
    <Box sx={classes.itemBody}>
      <Chip 
        label={
          <Box color={isActive ? "success.main" : "error.main"}>
            <Box component={FontAwesomeIcon} icon={isActive ? "plug-circle-check" : "plug-circle-exclamation"} mr={1} />
            {isActive ? "Activo" : "Inactivo"}
          </Box>
        }
        variant="outlined" 
        sx={classes.itemChip}
      />
      <Chip
        label={
          <>
            <Box component={FontAwesomeIcon} icon="shield-halved" mr={1} />
            {nRoles} {nRoles === 1 ? "Rol" : "Roles"}
          </>
        }
        variant="outlined" 
        sx={[classes.itemChip, {ml: 1}]}
      />
      <Typography component="p" variant='subtitle1' my={1}>
        <b>ID:</b> {identification ? identification : "Sin identificación"}
      </Typography>
      <Typography component="p" variant='subtitle1' my={1}>
        <b>Nombres:</b> {names && names.length > 30 ? names.substring(0,27).concat("...") : names}
      </Typography>
      <Typography component="p" variant='subtitle1' my={1} mb={2}>
        <b>Celular:</b> {phone ? phone : "Sin celular"}
      </Typography>
      <Divider sx={{borderWidth: 1}} />
      {deleting ?
        <Typography component="p" variant='h6' mt={2} color="error">
          Eliminando <CircularProgress color='error' size={25} sx={classes.progress} />
        </Typography>:
        myReset ?
        <Typography component="p" variant='h6' mt={2} color="warning.main">
          Reseteando <CircularProgress color='warning' size={25} sx={classes.progress} />
        </Typography>:
        <Typography component="p" variant='h6' fontSize="1rem" mt={2} color="light.main">
        <Box component={FontAwesomeIcon} sx={classes.icon} icon="circle-info" />  Barra Informativa
      </Typography>
      }     
    </Box>
  </Paper>

}

export default UserItem