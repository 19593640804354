const prod = {
  API_URL: "http://35.232.189.87/api",
  SESSION_SERVICE: "/Session",
  TOKEN_SERVICE: "/TokenAuth",
  USER_SERVICE: "/User",
  ROLE_SERVICE: "/Role",
  COLOR_SERVICE: "/Color",
  SIZE_SERVICE: "/Size",
  LINE_SERVICE: "/Line",
  SUBLINE_SERVICE: "/Subline",
  PRODUCT_SERVICE: "/Product",
  CLIENT_SERVICE: "/Client",
  PRICE_LIST_SERVICE: "/PriceList",
  STOCK_SERVICE: "/Stock",
  PACKAGE_SERVICE: "/Packages",
};

const dev = {
  API_URL: "https://localhost:44311/api",
  SESSION_SERVICE: "/Session",
  TOKEN_SERVICE: "/TokenAuth",
  USER_SERVICE: "/User",
  ORDER_SERVICE: "/Order",
  ROLE_SERVICE: "/Role",
  COLOR_SERVICE: "/Color",
  SIZE_SERVICE: "/Size",
  LINE_SERVICE: "/Line",
  SUBLINE_SERVICE: "/Subline",
  PRODUCT_SERVICE: "/Product",
  CLIENT_SERVICE: "/Client",
  PRICE_LIST_SERVICE: "/PriceList",
  STOCK_SERVICE: "/Stock",
  PACKAGE_SERVICE: "/Packages",
};

export const api = process.env.NODE_ENV === "development" ? dev : prod;
