import { GridFooterContainer, GridFooter } from "@mui/x-data-grid";
import { getCurrency } from "../../common/globalFunctions";

const OrderFooter = ({ total }) => {
  const classes = {
    root: {
      pl: 3,
      flexDirection: "row-reverse",
    },
  };

  return (
    <GridFooterContainer sx={classes.root}>
      <GridFooter />
      <p>
        <b>Total:</b> {getCurrency(total)}
      </p>
    </GridFooterContainer>
  );
};

export default OrderFooter;
