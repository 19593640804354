import { useEffect, useContext, useMemo, useCallback, useState } from 'react'
import {
  Box,
  Paper,
  Typography,
  Button,
  useMediaQuery
} from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import IconSwitch from "../atoms/IconSwitch"
import { useTheme } from "@mui/material/styles"
import { useNavigate, useLocation } from 'react-router-dom'
import { DataGrid } from "@mui/x-data-grid"
import { TopBarContext } from "../../providers/TopBarProvider"
import { ToastContext } from "../../providers/ToastProvider"
import RoleService from "../../services/RoleService"
import CustomNoRows from '../atoms/CustomNoRows'
import RoleCreator from '../molecules/RoleCreator'

const Role = () => {

  const [ permissions, setPermissions ] = useState([])
  const [ rolePermissions, setRolePermissions ] = useState([])
  const [ role, setRole ] = useState({
    displayName: "Cargando...",
    description: "",
    grantedPermissions: [],
    loaded: false,
    edit: false
  })
  const [ error, setError ] = useState(false)
  const [ isLoading, setIsLoading ] = useState(false)
  const [ openModal, setOpenModal ] = useState(false)
  const { setTitle, setSearchOptions, setBtnPrimary } = useContext(TopBarContext)
  const { setShowToast, setToastMessage } = useContext(ToastContext)
  const roleService = useMemo(()=>new RoleService(), [])
  const location = useLocation()
  const navigate = useNavigate()
  const theme = useTheme()
  const bpMdDown = useMediaQuery(theme.breakpoints.down("md"))

  const columns = [
    { 
      field: 'displayName',
      headerName: 'Permisos',
      minWidth: 200,
      flex: 1,
      sorting: false,
      renderCell: (item) => (
        <>
          <Box component={FontAwesomeIcon} icon="key" color="secondary.main" sx={{fontSize: "1.2rem", mr: 2}} />
          {item.row.displayName}
        </>
      )
    },
    {
      field: 'edit',
      headerName: '',
      sortable: false,
      width: 135,
      headerAlign: 'center',
      align:'center',
      renderHeader: () => <Button
        variant="text" 
        size="small"
        color='success'
        onClick={updatePermissions}
        disabled={(!role.loaded) || (role.grantedPermissions.length === rolePermissions.length && role.grantedPermissions.findIndex(per => !rolePermissions.includes(per)) === -1)}
      >
        <Box component={FontAwesomeIcon} icon="rotate" mr={1} /> Actualizar
      </Button>,
      renderCell: (item) => {

        const isChecked = role.grantedPermissions.findIndex(per=>per === item.row.name) !== -1

        return <IconSwitch 
          defaultChecked={isChecked}
          onChange={(_, value)=>onChangeSwitch(item.row.name, value)} 
          color="success" 
        />
      }
    }
  ];

  const onChangeSwitch = (newPer, value) => {

    setRolePermissions(oldRolePermissions=>value ? 
      [...oldRolePermissions, newPer] : 
      [...oldRolePermissions.filter(per=>per !== newPer)]
    )

  }

  const updatePermissions = async () => {

    try {
      
      setIsLoading(true)

      const payload = {
        ...role,
        grantedPermissions: [...rolePermissions]
      }

      const res = await roleService.update(payload)

      if (!res.data.success) {
        return
      }

      setRole(oldRole=>({
        ...oldRole,
        edit: true,
        grantedPermissions: [...rolePermissions]
      }))

    } catch (error) {
      setToastMessage([
        {
          severity: "error",
          primary: `Error al intentar actualizar los permisos`
        }
      ])
      setShowToast(true)
    } finally {
      setIsLoading(false)
    }

  }

  const getAllPermissions = useCallback(async() => {

    try {
      setIsLoading(true)
      const res = await roleService.getAllPermissions()
      
      if (!res.data.success) {
        return
      }

      setPermissions([...res.data.result.items.map((item, idx)=>({
        ...item,
        id: idx
      }))])

    } catch {
      setError(true)
    } finally {
      setIsLoading(false)
    }

  }, [roleService, setPermissions])

  const getRole = useCallback(async() => {
    const id = Number(location.search.split("=")[1])
    setRole(oldRole=>({
      ...oldRole,
      ...location.state
    }))

    setIsLoading(true)

    try {
      
      const res = await roleService.get(id)

      if (!res.data.success) {
        return
      }

      setRole(oldRole=>({
        ...oldRole,
        loaded: true,
        ...res.data.result
      }))

      setRolePermissions([...res.data.result.grantedPermissions])

    } catch {
      setToastMessage([
        {
          severity: "error",
          primary: `Error al intentar obtener los datos del rol`
        }
      ])
      setShowToast(true)
      navigate("/roles")
    }

  },[location, navigate, roleService, setRole, setToastMessage, setShowToast])

  useEffect(()=>{
    getRole()
  }, [getRole])

  useEffect(()=>{
    if (role.loaded) {
      getAllPermissions()
    }
  },[role, getAllPermissions])

  useEffect(()=>{
    setTitle(role.displayName)
    setSearchOptions({
      action: null
    })
    setBtnPrimary({
      label: "Editar rol",
      action: () => setOpenModal(true),
      icon: "pencil"
    })
  },[role, setTitle, setSearchOptions, setBtnPrimary])

  const classes = {
    root: {
      maxWidth: "md",
      ml: bpMdDown ? 0 : 8,
    },
    datagrid: {
      marginTop: 5,
      fontSize: "1.1rem",
      height: 400,
      ".MuiDataGrid-cell:focus,.MuiDataGrid-cell:focus-within": {
        outline: "none"
      }
    }
  }

return <>
    <Box sx={classes.root}>
      <Typography component="p" ml={bpMdDown ? 9 : 1}>
        {role.description}
      </Typography>
      <Paper elevation={2}>
        <DataGrid
          sx={classes.datagrid}
          columns={columns}
          rows={permissions}
          hideFooter={true}
          autoHeight={permissions.length > 0}
          disableColumnMenu
          disableRowSelectionOnClick
          loading={isLoading}
          slots={{
            noRowsOverlay: ()=><CustomNoRows message={error ? "Error obteniendo los permisos" : "No hay permisos registrados"} />,
            noResultsOverlay: ()=><CustomNoRows message={error ? "Error obteniendo obtener los permisos" : "No hay permisos registrados"} />
          }}
        />
      </Paper>
    </Box>
    <RoleCreator 
      open={openModal}
      handleClose={()=>setOpenModal(false)}
      service={roleService}
      setRoles={setRole}
      editRole={{
        ...role,
        edit: true
      }}
    />
  </>
}

export default Role