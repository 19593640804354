import React, { createContext, useState, useContext, useEffect, useCallback, useMemo } from 'react';
import AuthService from "../services/AuthService"
import SessionService from '../services/SessionService';
import { Backdrop } from '@mui/material';
import { LoadingContext } from './LoadingProvider';
import MyAlert from '../components/atoms/MyAlert';

export const PermissionsContext = createContext({})

const PermissionsProvider = ({ children }) => {

    const [pers, setPers] = useState([])
    const [error, setError] = useState(false)
    const authService = useMemo(()=>new AuthService(), [])
    const sessionService = useMemo(()=>new SessionService(), [])
    const { setOpenLoading, setTextLoading } = useContext(LoadingContext)

    const getLoginInfo = useCallback(async()=>{
        try {
                
            setTextLoading("Obteniendo permisos")
            setOpenLoading(true)
            const res = await sessionService.getLoginInfo()
            const pers = res.data.result.user.permissionNames

            setPers([...pers.map(per=>per.toLowerCase())])

        } catch (error) {
            setError(true)
        } finally {
            setOpenLoading(false)
        }
    }, [])

    useEffect(()=>{
        if (pers.length === 0 && authService.isAuthenticated())
            getLoginInfo()
    },[getLoginInfo])

    return <PermissionsContext.Provider value={{
        pers,
        setPers
    }} >
        {children}
        <Backdrop
            sx={{ color: '#fff', zIndex: "tooltip" }}
            open={error}
        >
            <MyAlert 
                title="Hubo un error de permisos"
                message="No se pudo obtener los permisos del usuario"
                postdata="Intenta recargar la página"
            />
        </Backdrop>
    </PermissionsContext.Provider>

}

export default PermissionsProvider