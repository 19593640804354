import { useEffect, useContext, useMemo, useCallback, useState } from "react";
import {
  Box,
  Paper,
  Typography,
  IconButton,
  CircularProgress,
  useMediaQuery,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTheme } from "@mui/material/styles";
import { useNavigate, useLocation } from "react-router-dom";
import { DataGrid, GridCellModes, useGridApiRef } from "@mui/x-data-grid";
import { TopBarContext } from "../../providers/TopBarProvider";
import { ToastContext } from "../../providers/ToastProvider";
import ProductService from "../../services/ProductService";
import StockService from "../../services/StockService";
import CustomNoRows from "../atoms/CustomNoRows";

const Stock = () => {
  const apiRef = useGridApiRef();
  const [focuseds, setFocuseds] = useState({});
  const [product, setProduct] = useState({
    reference: "Cargando...",
    description: "",
  });
  const [stock, setStock] = useState([]);
  const [error, setError] = useState(false);
  const [count, setCount] = useState(0);
  const [itemsLoading, setItemsLoading] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 25,
    page: 0,
  });
  const { setTitle, setSearchOptions, setBtnPrimary } =
    useContext(TopBarContext);
  const { setShowToast, setToastMessage } = useContext(ToastContext);
  const productService = useMemo(() => new ProductService(), []);
  const stockService = useMemo(() => new StockService(), []);
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const bpMdDown = useMediaQuery(theme.breakpoints.down("md"));

  const columns = [
    {
      field: "size",
      headerName: "Talla",
      minWidth: 200,
      // maxWidth: theme.breakpoints.values.xl,
      flex: 1,
      sorting: false,
      editable: true,
      renderCell: (item) => (
        <Box pl={1} sx={{ width: "100%" }}>
          {item.row.size}
        </Box>
      ),
    },
    {
      field: "color",
      headerName: "Color",
      minWidth: 200,
      // maxWidth: theme.breakpoints.values.xl,
      flex: 1,
      sorting: false,
      editable: true,
      renderCell: (item) => <Box sx={{ width: "100%" }}>{item.row.color}</Box>,
    },
    {
      field: "quantity",
      headerName: "Stock",
      minWidth: 200,
      // maxWidth: theme.breakpoints.values.xl,
      flex: 1,
      sorting: false,
      editable: true,
      renderCell: (item) => (
        <Box pl={1} sx={{ width: "100%" }}>
          {item.row.quantity}
        </Box>
      ),
    },
    {
      field: "edit",
      headerName: "Acciones",
      sortable: false,
      width: 135,
      renderCell: (row) => {
        const item = itemsLoading.find((i) => i.id === row.id);
        if (item?.id && item?.edit) {
          return (
            <Box mr={1}>
              Editando{" "}
              <CircularProgress
                color="primary"
                size={25}
                sx={{ verticalAlign: "text-bottom" }}
              />
            </Box>
          );
        }

        if (item?.id && !item?.edit) {
          return (
            <Box mr={1}>
              Eliminando{" "}
              <CircularProgress
                color="error"
                size={25}
                sx={{ verticalAlign: "text-bottom" }}
              />
            </Box>
          );
        }

        return (
          <IconButton
            onClick={() => focusToInput(row.id)}
            sx={{ float: "right" }}
          >
            {focuseds[row.id]?.quantity?.mode === GridCellModes.Edit ? (
              <Box
                component={FontAwesomeIcon}
                icon="check"
                fontSize="1rem"
                color="success.main"
              />
            ) : (
              <Box
                component={FontAwesomeIcon}
                icon="pencil"
                fontSize="1rem"
                color="secondary.main"
              />
            )}
          </IconButton>
        );
      },
    },
  ];

  const getProduct = useCallback(async () => {
    const id = Number(location.search.split("=")[1]);
    setProduct({ ...location.state });

    setIsLoading(true);

    try {
      const res = await productService.get(id);

      if (!res.data.success) {
        return;
      }

      setProduct({ ...res.data.result });
    } catch {
      setToastMessage([
        {
          severity: "error",
          primary: `Error al intentar obtener el stock`,
        },
      ]);
      setShowToast(true);
      navigate("/products");
    } finally {
      setIsLoading(false);
    }
  }, [
    location,
    navigate,
    productService,
    setProduct,
    setToastMessage,
    setShowToast,
  ]);

  const getStock = useCallback(async () => {
    const id = Number(location.search.split("=")[1]);

    setIsLoading(true);

    try {
      const res = await stockService.get(id);
      const totalCount = res.data.result.totalCount;
      const items = res.data.result;

      if (!res.data.success) {
        return;
      }

      setCount(totalCount);
      setStock([
        ...stock,
        ...items.map((item) => ({
          ...item,
          id: Number(item.stockId),
          selectable: true,
        })),
      ]);
    } catch {
      setError(true);
    } finally {
      setIsLoading(false);
    }
  }, [location, stock, stockService]);

  const focusToInput = (id) => {
    setFocuseds((oldFocuseds) => ({
      ...oldFocuseds,
      [id]: {
        quantity: {
          mode:
            oldFocuseds[id]?.quantity?.mode === GridCellModes.Edit
              ? GridCellModes.View
              : GridCellModes.Edit,
        },
      },
    }));
  };

  const editStock = async (id, oldValue) => {
    try {
      const cell = await apiRef.current.getCellElement(id, "quantity");
      const value = cell.firstChild.firstChild.value;

      if (value === oldValue) {
        return;
      }

      setItemsLoading((oldItems) => [
        ...oldItems,
        {
          id,
          edit: true,
        },
      ]);

      const regex = /^[0-9]\d*$/g;
      if (!regex.test(value)) {
        throw new Error("Pattern");
      }

      const payload = [
        {
          stockId: id,
          quantity: Number(value),
        },
      ];

      const res = await stockService.update(payload);

      if (!res.data.success) {
        return;
      }

      setStock((oldStock) => {
        const idx = oldStock.findIndex((aSotck) => aSotck.id === id);
        oldStock[idx].quantity = value;
        return [...oldStock];
      });
    } catch (error) {
      if (error.message === "Pattern") {
        await apiRef.current.setEditCellValue({
          id,
          field: "quantity",
          value: oldValue,
        });
      } else {
        setStock((oldStocks) => [...oldStocks]);
      }

      setToastMessage([
        {
          severity: "error",
          primary:
            error.message === "Pattern"
              ? "Entrada invalida"
              : "Error al intentar editar el stock",
        },
      ]);
      setShowToast(true);
    } finally {
      setItemsLoading((oldItems) => [
        ...oldItems.filter((item) => item.id !== id),
      ]);
    }
  };

  useEffect(() => {
    setTitle(product.reference);
    setSearchOptions({
      action: null,
      // hidden: true
    });
    setBtnPrimary({
      action: null,
    });
  }, [setTitle, setSearchOptions, setBtnPrimary, product]);

  useState(() => {
    getProduct();
  }, [getProduct]);

  useState(() => {
    getStock();
  }, [getStock]);

  const classes = {
    root: {},
    description: {
      ml: bpMdDown ? 0 : 8,
    },
    paper: {
      height: "70vh",
    },
    datagrid: {
      marginTop: 5,
      height: "100%",
      fontSize: "1.1rem",
      ".MuiDataGrid-cell--editing input": {
        fontSize: "1.1rem",
      },
      ".MuiDataGrid-cell:focus,.MuiDataGrid-cell:focus-within": {
        outline: "none",
      },
    },
  };

  return (
    <>
      <Box sx={classes.root}>
        <Typography
          sx={classes.description}
          component="p"
          ml={bpMdDown ? 9 : 1}
        >
          {product.description}
        </Typography>
        <Paper sx={classes.paper} elevation={2}>
          <DataGrid
            sx={classes.datagrid}
            apiRef={apiRef}
            columns={columns}
            rows={stock}
            rowCount={count}
            editMode="cell"
            getRowId={(row) => row.id}
            cellModesModel={focuseds}
            disableColumnMenu
            disableRowSelectionOnClick
            isRowSelectable={(item) => item.row.selectable}
            isCellEditable={(item) => item.row.selectable}
            pageSizeOptions={[25]}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            loading={isLoading}
            localeText={{
              toolbarColumns: "Columnas",
              toolbarColumnsLabel: "Seleccionar columnas",
              noRowsLabel: "Sin Stock",
              footerRowSelected: (count) =>
                count !== 1
                  ? `${count.toLocaleString()} filas seleccionadas`
                  : `${count.toLocaleString()} fila seleccionada`,
              MuiTablePagination: {
                labelDisplayedRows: ({ from, to, count }) =>
                  `${from} - ${to} de ${count}`,
              },
            }}
            slotProps={{
              pagination: {
                labelRowsPerPage: "Filas por página",
              },
            }}
            slots={{
              noRowsOverlay: () => (
                <CustomNoRows
                  message={
                    error ? "Error intentando obtener el stock" : "No hay stock"
                  }
                />
              ),
              noResultsOverlay: () => (
                <CustomNoRows
                  message={
                    error
                      ? "Error intentando obtener los stock"
                      : "No hay stock"
                  }
                />
              ),
            }}
            onCellEditStop={(item, event) => {
              if (focuseds[item.id]?.quantity?.mode === GridCellModes.Edit) {
                focusToInput(item.id);
              }
              editStock(item.id, item.row.quantity);
            }}
          />
        </Paper>
      </Box>
    </>
  );
};

export default Stock;
