import { useEffect, useContext, useState, useMemo } from "react";
import {
  Modal,
  Paper,
  Typography,
  TextField,
  Box,
  FormControl,
  FormHelperText,
  Button,
  CircularProgress
} from "@mui/material";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DrawerContext } from "../../providers/DrawerProvider"
import { ToastContext } from "../../providers/ToastProvider"
import { validateField } from '../../common/globalFunctions'
import { useForm } from "react-hook-form"
import { useTheme } from "@mui/material/styles"
import SearchableSelect from "../atoms/SearchableSelect";
import LineService from "../../services/LineService";
import SublineService from "../../services/SublineService";

const SublineCreator = ({ open, handleClose, setRows }) => {

  const [ isLoading, setIsLoading ] = useState(false)
  const { drawerOpen } = useContext(DrawerContext)
  const { setShowToast, setToastMessage } = useContext(ToastContext)
  const { register, handleSubmit, setValue, watch, reset, formState: { errors } } = useForm()
  const lineService = useMemo(()=>new LineService(), [])
  const sublineService = useMemo(()=>new SublineService(), [])
  const lineId = watch("lineId")
  const theme = useTheme()

  const onSubmit = async(data) => {
    
    try {

      setIsLoading(true)

      const res = await sublineService.create(data)
      if (!res.data.success) {
        return
      }

      setRows(oldRows=>[{...data, ...res.data.result, selectable: true}, ...oldRows])

    } catch (error) {
      setToastMessage(oldToasts=>[...oldToasts,
        {
          severity: "error",
          primary: `Error agregando la sublínea ${data.description}`
        }
      ])
      setShowToast(true)
    } finally {
      setIsLoading(false)
      handleClose()
    }

  }

  const onLineSelect = (option) => {
    setValue("lineId",option.props.value)
    setValue("lineName",option.props.children)
  }

  const keyPress = (e) => {
    if(e.keyCode === 13){
      handleSubmit(onSubmit)()
    }
  }

  useEffect(()=>{
    reset()
  },[reset, open])

  const classes = {
    root: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      left: drawerOpen ? theme.drawerWidth : 0,

      "& .MuiModal-backdrop": {
        left: drawerOpen ? theme.drawerWidth : 0, 
      }

    },
    paper: {
      width: "90%",
      maxWidth: 400,
      textAlign: "center",
      position: "relative",
      top: -50,
      pt: 3,
      pb: 5,
      px: 6
    },
    actions: {
      display: "flex", 
      justifyContent: "center",
      gap: 2
    }
  }

  return <Modal
    open={open}
    onClose={handleClose}
    sx={classes.root}
  >
    <Paper sx={classes.paper}>
      <Box component="form" autoComplete='off' noValidate onSubmit={handleSubmit(onSubmit)}>
        <Typography variant="h6" component="h3" mb={3}>
          <Box component={FontAwesomeIcon} icon="plus" mr={2} /> Agregar Sublínea
        </Typography>
        <FormControl fullWidth>
          <TextField
            label="Nombre de la sublínea"
            variant="filled"
            autoFocus
            autoComplete="off"
            disabled={isLoading}
            onKeyDown={keyPress}
            {...register("description", { required: true, pattern: /^[a-zA-Z][a-zA-Z0-9 ]?/ })}
            error={errors["description"] !== undefined}
            helperText={validateField(errors["description"])}
          />
        </FormControl>
        <FormControl 
          fullWidth
          variant="filled" 
          sx={{ mt: 3 }} 
          {...register("lineId", {required: true})}
          error={errors["lineId"] !== undefined && lineId === ""}
        >
          <SearchableSelect 
            service={lineService}
            label="Línea"
            value={lineId}
            initialValue={[]}
            loadingMessage="Obteniendo líneas"
            errorMessage="Error obteniendo las líneas"
            disabled={false}
            handleChange={onLineSelect}
          />
          {errors["lineId"] !== undefined && lineId === "" && <FormHelperText>Este campo es requerido</FormHelperText>}
        </FormControl>
        <Box mt={5} sx={classes.actions}>
          <Button 
            type="submit"
            variant="contained" 
            disabled={isLoading}
            sx={{width: isLoading ? "100%" : "fit-content"}}
          >
            {isLoading ? 
              <>
                Agregando sublínea <CircularProgress sx={{ml: 1}} thickness={3} size={20} color='secondary' />
              </>:
              "Aceptar"
            }
          </Button>
          {!isLoading && <Button variant="contained" color="error" onClick={handleClose}>Cancelar</Button>}
        </Box>
      </Box>
    </Paper>
  </Modal>
}

export default SublineCreator