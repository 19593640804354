import { useEffect, useContext, useState } from "react";
import {
  Modal,
  Paper,
  Typography,
  TextField,
  Box,
  Button,
  CircularProgress
} from "@mui/material";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DrawerContext } from "../../providers/DrawerProvider"
import { ToastContext } from "../../providers/ToastProvider"
import { validateField, toCapitalize } from '../../common/globalFunctions'
import { useForm } from "react-hook-form"
import { useTheme } from "@mui/material/styles"

const CreationModal = ({ open, handleClose, primaryKey="description", edit, keyword, female, title, label, service, setRows }) => {

  const [ isLoading, setIsLoading ] = useState(false)
  const { drawerOpen } = useContext(DrawerContext)
  const { setShowToast, setToastMessage } = useContext(ToastContext)
  const { register, handleSubmit, reset, setValue, formState: { errors } } = useForm()
  const theme = useTheme()

  const onSubmit = async(data) => {

    try {
      
      setIsLoading(true)
      let res;

      if (edit) {
        res = await service.update({...edit, ...data})
      } else {
        res = await service.create(data)
      }

      if (!res.data.success) {
        return
      }

      setRows(oldRows=>{
        if (edit) {
          return [...oldRows.map(row=>{
            if (edit.id === row.id) {
              return {
                ...row,
                ...data
              }
            } else {
              return row
            }
          })]
        } else {
          return [{...res.data.result}, ...oldRows]
        }
      })

    } catch (error) {
      setToastMessage(oldToasts=>[...oldToasts,
        {
          severity: "error",
          primary: `Error agregando ${female ? "la" : "el"} ${keyword} ${data.description}`
        }
      ])
      setShowToast(true)
    } finally {
      setIsLoading(false)
      handleClose()
    }

  }

  const keyPress = (e) => {
    if(e.keyCode === 13){
      handleSubmit(onSubmit)()
    }
  }

  useEffect(()=>{
    if (edit) {
      setValue(primaryKey,edit[primaryKey])
    } else {
      reset()
    }
  },[reset, open, edit, primaryKey, setValue])

  const classes = {
    root: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      left: drawerOpen ? theme.drawerWidth : 0,

      "& .MuiModal-backdrop": {
        left: drawerOpen ? theme.drawerWidth : 0, 
      }

    },
    paper: {
      width: "90%",
      maxWidth: 400,
      textAlign: "center",
      position: "relative",
      top: -50,
      pt: 3,
      pb: 5,
      px: 6,
    },
    actions: {
      display: "flex",
      justifyContent: "center",
      gap: 2
    }
  }

  return <Modal
    open={open}
    onClose={handleClose}
    sx={classes.root}
  >
    <Paper sx={classes.paper}>
      <Typography variant="h6" component="h3" mb={3}>
        <Box component={FontAwesomeIcon} icon="plus" mr={2} /> {toCapitalize(title)}
      </Typography>
      <TextField
        label={label}
        variant="filled"
        autoFocus
        autoComplete="off"
        disabled={isLoading}
        onKeyDown={keyPress}
        sx={{width: "100%"}}
        {...register(primaryKey, { required: true, pattern: /^[a-zA-Z][a-zA-Z0-9 ]?/ })}
        error={errors[primaryKey] !== undefined}
        helperText={validateField(errors[primaryKey])}
      />
      <Box mt={3} sx={classes.actions}>
        <Button 
          variant="contained" 
          disabled={isLoading}
          onClick={handleSubmit(onSubmit)}
          sx={{width: isLoading ? "100%" : "fit-content"}}
        >
          {isLoading ? 
            <>
              {edit ? "Actualizando" : "Agregando"} {keyword} <CircularProgress sx={{ml: 1}} thickness={3} size={20} color='secondary' />
            </>:
            "Aceptar"
          }
        </Button>
        {!isLoading && <Button variant="contained" color="error" onClick={handleClose}>Cancelar</Button>}
      </Box>
    </Paper>
  </Modal>
}

export default CreationModal