import { useContext } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { PermissionsContext } from "../providers/PermissionsProvider";
import TopBarLayout from "../templates/TopBarLayout";
import OrderProvider from "../providers/OrderProvider";
import Orders from "../components/organisms/Orders";
import Order from "../components/organisms/Order";
import NewOrder from "../components/organisms/NewOrder";
import NewOrderProduct from "../components/organisms/NewOrderProduct";
import Packages from "../components/organisms/Packages";
import Package from "../components/organisms/Package";
import Clients from "../components/organisms/Clients";
import NewClient from "../components/organisms/NewClient";
import PriceLists from "../components/organisms/PriceLists";
import Prices from "../components/organisms/Prices";
import PriceClients from "../components/organisms/PriceClients";
import Products from "../components/organisms/Products";
import NewProduct from "../components/organisms/NewProduct";
import Stock from "../components/organisms/Stock";
import Lines from "../components/organisms/Lines";
import Sublines from "../components/organisms/Sublines";
import Sizes from "../components/organisms/Sizes";
import Colors from "../components/organisms/Colors";
import Roles from "../components/organisms/Roles";
import Role from "../components/organisms/Role";
import Users from "../components/organisms/Users";
import NewUser from "../components/organisms/NewUser";
import NewPackage from "../components/organisms/NewPackage";
import PackageProvider from "../providers/PackageProvider";

const MainPage = () => {
  const location = useLocation();
  const { pers } = useContext(PermissionsContext);

  return (
    <Routes>
      <Route exact path="/" element={<Navigate to="/dashboard" />} />
      <Route exact path="/dashboard" element={<h1>Hola mundo</h1>} />
      <Route path="/" element={<TopBarLayout />}>
        {true && <Route path="/orders" element={<Orders />} />}
        {true && (
          <Route path="/orders/order" element={<PackageProvider />}>
            <Route path="/orders/order/new-package" element={<NewPackage />} />
            <Route path="/orders/order" element={<Order />} />
          </Route>
        )}
        {true && (
          <Route path="/orders/new-order" element={<OrderProvider />}>
            <Route
              path="/orders/new-order/products"
              element={
                location.state?.byMe ? (
                  <NewOrderProduct />
                ) : (
                  <Navigate to="/dashboard" />
                )
              }
            />
            <Route
              path="/orders/new-order"
              element={
                location.state?.client ? (
                  <NewOrder />
                ) : (
                  <Navigate to="/dashboard" />
                )
              }
            />
          </Route>
        )}
        {true && (
          <>
            <Route path="/orders/packages/package" element={<Package />} />
            <Route path="/orders/packages" element={<Packages />} />
          </>
        )}
        {(pers.includes("pages.clients") ||
          pers.includes("pages.clients.getall")) && (
          <Route path="/clients" element={<Clients />} />
        )}
        {pers.includes("pages.clients") && (
          <Route path="/clients/new-client" element={<NewClient />} />
        )}
        {pers.includes("pages.clients.update") && (
          <Route
            path="/clients/edit-client"
            element={<NewClient edit={true} />}
          />
        )}
        {pers.includes("pages.pricelist") && (
          <Route path="/price-lists" element={<PriceLists />} />
        )}
        {pers.includes("pages.pricelist") && (
          <Route path="/price-lists/prices" element={<Prices />} />
        )}
        {pers.includes("pages.pricelist") && (
          <Route path="/price-lists/clients" element={<PriceClients />} />
        )}
        {pers.includes("pages.products") && (
          <Route path="/products" element={<Products />} />
        )}
        {pers.includes("pages.products") && (
          <Route path="/products/new-product" element={<NewProduct />} />
        )}
        {pers.includes("pages.products") && (
          <Route
            path="/products/edit-product"
            element={<NewProduct edit={true} />}
          />
        )}
        {pers.includes("pages.stock") && (
          <Route path="/products/stock" element={<Stock />} />
        )}
        {pers.includes("pages.lines") && (
          <Route path="/lines" element={<Lines />} />
        )}
        {pers.includes("pages.sublines") && (
          <Route path="/sublines" element={<Sublines />} />
        )}
        {pers.includes("pages.sizes") && (
          <Route path="/sizes" element={<Sizes />} />
        )}
        {pers.includes("pages.colors") && (
          <Route path="/colors" element={<Colors />} />
        )}
        {pers.includes("pages.roles") && (
          <Route path="/roles" element={<Roles />} />
        )}
        {pers.includes("pages.roles") && (
          <Route path="/roles/role" element={<Role />} />
        )}
        {pers.includes("pages.users") && (
          <Route path="/users" element={<Users />} />
        )}
        {pers.includes("pages.users") && (
          <Route path="/users/new-user" element={<NewUser />} />
        )}
        {pers.includes("pages.users") && (
          <Route path="/users/edit-user" element={<NewUser edit={true} />} />
        )}
        {/* <Route path='*' element={<Navigate to="/dashboard" />} /> */}
      </Route>
    </Routes>
  );
};

export default MainPage;
