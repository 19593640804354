import { useMemo, useState, useContext } from 'react'
import {  
  Grid,
  Paper,
  Box,
  Typography,
  Button,
  FormControl,
  TextField,
  CircularProgress,
  Alert
} from '@mui/material'
import { useTheme } from "@mui/material/styles"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useForm } from "react-hook-form"
import { useNavigate } from 'react-router-dom'
import Logo from "../../assets/imgs/logoOrange.svg"
import AuthService from "../../services/AuthService"
import { PermissionsContext } from '../../providers/PermissionsProvider'

const Login = () => {

  const [ loading, setLoading ] = useState(false)
  const [ error, setError ] = useState(-1)
  const { register, watch, handleSubmit } = useForm({
    defaultValues: {
      username: "",
      password: ""
    }
  })
  const { setPers } = useContext(PermissionsContext)
  const theme = useTheme()
  const navigate = useNavigate()
  const username = watch("username")
  const password = watch("password")
  const authService = useMemo(()=>new AuthService(),[])

  const onSubmit = async (data) => {
    setLoading(true)
    const body = {
      userNameOrEmailAddress: data.username,
      password: data.password,
      rememberClient: true
    }

    try {
      const res = await authService.login(body)

      if (!res.data.success) {
        return
      }

      const pers = res.data.result.user.permissionNames

      setPers([...pers.map(per=>per.toLowerCase())])

      navigate("/dashboard")

    } catch (error) {
      if (error.code === "ERR_NETWORK") {
        setError(0)
      } else {
        setError(1)
      }
    } finally {
      setLoading(false)
    }

  }

  const showMessageError = () => {
    switch (error) {

      case 0: 
        return <Alert severity="error" sx={{marginTop: 5}}>Error con la conexión de la red</Alert>

      case 1:
        return <Alert severity="error" sx={{marginTop: 5}}>Usuario y/o Contraseña incorrecta</Alert>

      default:
        return <Alert severity="error" sx={{marginTop: 5, visibility: "hidden"}}>Invisible</Alert>

    }
  }

  const classes = {
    root: {
      overflow: "hidden",
      minHeight: "100vh",
      backgroundColor: theme.palette.myGray.main
    },
    login: {
      width: "98%",
      maxWidth: theme.breakpoints.values.sm,
      borderRadius: "10px",
      zIndex: "modal",
      textAlign: "center",
      paddingBottom: 18
    },
    loginHead: {
      padding: 2,
      backgroundColor: theme.palette.secondary.main,
      borderRadius: "10px 10px 0 0"
    },
    loginForm: {
      width: "98%",
      maxWidth: 400,
      margin: "0 auto",
      padding: 4
    },
    loginBtnIcon: {
      fontSize: "1.2rem",
      marginLeft: 1
    }
  }

  return <Grid container justifyContent="center" alignContent="center" sx={classes.root} >
    <Paper elevation={2} sx={classes.login}>
      <Box color="secondary" sx={classes.loginHead}>
        <Typography variant='h5' textAlign="left" color="white" >Login</Typography>
      </Box>
      <Box component="form" autoComplete='off' noValidate sx={classes.loginForm} onSubmit={handleSubmit(onSubmit)}>
        <Box component="img" src={Logo} width="100%" marginBottom={6} />
        <FormControl fullWidth>
          <TextField disabled={loading} label="Usuario" variant="outlined" sx={{marginBottom: 4}} {...register("username", { required: true })} />
        </FormControl>
        <FormControl fullWidth>
          <TextField disabled={loading} label="Contraseña" variant="outlined" type="password" sx={{marginBottom: 5}} {...register("password", { required: true })} />
        </FormControl>
        <Button 
          variant="contained" 
          size="medium" 
          type='submit' 
          disabled={username.length === 0 || password.length === 0 || loading}
          endIcon={loading ? 
            <CircularProgress thickness={3} size={20} color='secondary' /> 
            :
            <Box component={FontAwesomeIcon} icon="right-to-bracket" sx={classes.loginBtnIcon} />
          }
        >
          {loading ? "Iniciando sesión" : "Iniciar Sesión"}
        </Button>
        {showMessageError()}
      </Box>
    </Paper>
  </Grid>

}

export default Login