import { useEffect, useState, useCallback, useMemo, useContext } from 'react'
import {  
  Box,
  Pagination,
  Skeleton
} from '@mui/material'
import { TopBarContext } from "../../providers/TopBarProvider"
import { useNavigate } from 'react-router-dom'
import ProductItem from '../molecules/ProductItem'
import CustomNoRows from '../atoms/CustomNoRows'
import ProductService from '../../services/ProductService'
import { ToastContext } from "../../providers/ToastProvider"

const Products = () => {
  const [ count, setCount ] = useState(0)
  const [ page, setPage ] = useState(1)
  const [ products, setProducts ] = useState([])
  const [ isLoading, setIsLoading ] = useState(true)
  const [ error, setError ] = useState(false)
  const [ mySearch, setMySearch ] = useState("")
  const { setTitle, setSearchOptions, setBtnPrimary } = useContext(TopBarContext)
  const { setShowToast, setToastMessage } = useContext(ToastContext)
  const productService = useMemo(()=> new ProductService(), [])
  const navigate = useNavigate()
  const rowsPerPage = 25

  const getAll = useCallback(async(skipCount = 0, search) => {

    try {
      setIsLoading(true)
      setProducts([])
      const res = await productService.getAll(rowsPerPage, skipCount*rowsPerPage, search)
      const totalCount = res.data.result.totalCount
      const items = res.data.result.items

      if (items.length === 0) {
        return
      }

      setCount(Math.ceil(totalCount / rowsPerPage))
      setProducts([...items])

    } catch {
      setError(true)
    } finally {
      setIsLoading(false)
    }

  }, [productService, setProducts])

  const deleteProduct = (id, reference, idx) => {

    return new Promise(async(resolve, reject)=>{
      try {
      
        const res = await productService.delete(id)
  
        if (!res.data.success) {
          return
        }
  
        setTimeout(()=>{
          setProducts(oldProducts=>{
            oldProducts.splice(idx,1)
            return [...oldProducts]
          })
        },1500)
  
        resolve()

      } catch {
        setToastMessage([
          {
            severity: "error",
            primary: `Error al intentar eliminar el producto ${reference}`
          }
        ])
        setShowToast(true)
        reject()
      }
    })

  }

  const editProduct = (product) => (
    navigate(`/products/edit-product?id=${product.id}`, {state: {...product}})
  )

  const stockProduct = (product) => (
    navigate(`/products/stock?id=${product.id}`, {state: {...product}})
  )

  const onPagination = (_, value) => {
    setProducts([])
    setPage(0)
    getAll(value-1, mySearch)
    window.scrollTo(0, 0)
  }

  useEffect(()=>{
    setTitle("Productos")
    setSearchOptions({
      label: "Buscar por referencia",
      action: (search)=>{
        setMySearch(search)
        getAll(0, search)
      }
    })
    setBtnPrimary({
      label: "Agregar Producto",
      action: ()=>navigate("/products/new-product"),
      icon: "plus"
    })
  },[setTitle, setSearchOptions, setBtnPrimary, getAll, navigate])

  useEffect(()=>{
    getAll()
  }, [getAll])

  const classes = {
    root: {
      display: "grid",
      gridTemplateColumns: "repeat(auto-fill, 335px)",
      gridGap: 20,
      justifyContent: "center",
      my: 8
    },
    skeleton: {
      width: "100%",
      height: 311
    },
    customNoRows: {
      gridColumn: "6 span"
    },
    pagination: {
      width: "fit-content",
      margin: "0 auto"
    }
  }

  return <>
    <Box sx={classes.root} >
      {isLoading ?
       new Array(rowsPerPage).fill(1).map((_, idx)=>(
        <Skeleton animation="wave" key={`skeleton-${idx}`} variant="rounded" sx={classes.skeleton} />
      )):
      products.length === 0 ?
      <Box sx={classes.customNoRows}>
        <CustomNoRows message={error ? "Error intentando obtener los productos" : "No hay productos registrados"} />
      </Box>:
      products.map((product, idx) => (
        <ProductItem 
          key={`product-${product.id}`}
          reference={product.reference} 
          description={product.description} 
          line={product.lineName}
          subline={product.sublineName}
          sizes={product.sizes.map(size=>size.description)} 
          colors={product.colors.map(size=>size.description)} 
          handleDelete={()=>deleteProduct(product.id, product.reference, idx)}
          handleEdit={()=>editProduct(product)}
          handleStock={()=>stockProduct(product)}
        />
      ))}
    </Box>
    {count > 1 &&
      <Pagination
        count={count} 
        defaultPage={page}
        sx={classes.pagination}
        color="primary"
        onChange={onPagination}
      />
    }
  </>
  
}

export default Products