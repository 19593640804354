import { useEffect, useState, useCallback, useMemo, useContext } from "react";
import { Box, Pagination, Skeleton } from "@mui/material";
import { TopBarContext } from "../../providers/TopBarProvider";
import { useNavigate } from "react-router-dom";
import RoleItem from "../molecules/RoleItem";
import RoleService from "../../services/RoleService";
import { ToastContext } from "../../providers/ToastProvider";
import RoleCreator from "../molecules/RoleCreator";
import CustomNoRows from "../atoms/CustomNoRows";

const Roles = () => {
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [roles, setRoles] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const [mySearch, setMySearch] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [editPayload, setEditPayload] = useState({
    edit: false,
  });
  const { setTitle, setSearchOptions, setBtnPrimary } =
    useContext(TopBarContext);
  const { setShowToast, setToastMessage } = useContext(ToastContext);
  const roleService = useMemo(() => new RoleService(), []);
  const navigate = useNavigate();
  const rowsPerPage = 25;

  const getAll = useCallback(
    async (skipCount = 0, search) => {
      try {
        setIsLoading(true);
        setRoles([]);
        const res = await roleService.getAll(
          rowsPerPage,
          skipCount * rowsPerPage,
          search
        );
        const totalCount = res.data.result.totalCount;
        const items = res.data.result.items;

        if (items.length === 0) {
          return;
        }

        setCount(Math.ceil(totalCount / rowsPerPage));
        setRoles([
          ...items.map((item) => ({
            ...item,
            nPer: item.grantedPermissions,
          })),
        ]);
      } catch {
        setError(true);
      } finally {
        setIsLoading(false);
      }
    },
    [roleService, setRoles]
  );

  const deleteRol = (id, nit, idx) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await roleService.delete(id);

        if (!res.data.success) {
          return;
        }

        setTimeout(() => {
          setRoles((oldClients) => {
            oldClients.splice(idx, 1);
            return [...oldClients];
          });
        }, 1500);

        resolve();
      } catch {
        setToastMessage([
          {
            severity: "error",
            primary: `Error al intentar eliminar el rol ${nit}`,
          },
        ]);
        setShowToast(true);
        reject();
      }
    });
  };

  const editRole = (role) => {
    setEditPayload({
      ...role,
      edit: true,
    });
    setOpenModal(true);
  };

  const showPermissions = (role) =>
    navigate(`/roles/role?id=${role.id}`, { state: { ...role } });

  const onPagination = (_, value) => {
    setRoles([]);
    setPage(0);
    getAll(value - 1, mySearch);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    setTitle("Roles");
    setSearchOptions({
      label: "Buscar Rol",
      action: (search) => {
        console.log(search);
        setMySearch(search);
        getAll(0, search);
      },
    });
    setBtnPrimary({
      label: "Agregar Rol",
      action: () => setOpenModal(true),
      icon: "plus",
    });
  }, [setTitle, setSearchOptions, setBtnPrimary, getAll, navigate]);

  useEffect(() => {
    getAll();
  }, [getAll]);

  const classes = {
    root: {
      display: "grid",
      gridTemplateColumns: "repeat(auto-fill, 335px)",
      gridGap: 20,
      justifyContent: "center",
      my: 8,
    },
    skeleton: {
      width: "100%",
      height: 207,
    },
    customNoRows: {
      gridColumn: "6 span",
    },
    pagination: {
      width: "fit-content",
      margin: "0 auto",
    },
  };

  return (
    <>
      <Box sx={classes.root}>
        {isLoading ? (
          new Array(rowsPerPage)
            .fill(1)
            .map((_, idx) => (
              <Skeleton
                animation="wave"
                key={`skeleton-${idx}`}
                variant="rounded"
                sx={classes.skeleton}
              />
            ))
        ) : roles.length === 0 ? (
          <Box sx={classes.customNoRows}>
            <CustomNoRows
              message={
                error
                  ? "Error intentando obtener los roles"
                  : "No hay roles registrados"
              }
            />
          </Box>
        ) : (
          roles.map((role, idx) => (
            <RoleItem
              key={`rol-${role.name}`}
              id={role.id}
              name={role.displayName}
              description={role.description}
              nPer={role.nPer}
              handleDelete={() => deleteRol(role.id, role.displayName, idx)}
              handleEdit={() => editRole(role)}
              handleAction={() => showPermissions(role)}
            />
          ))
        )}
      </Box>
      {count > 1 && (
        <Pagination
          count={count}
          defaultPage={page}
          sx={classes.pagination}
          color="primary"
          onChange={onPagination}
        />
      )}
      <RoleCreator
        open={openModal}
        handleClose={() => {
          setEditPayload({
            edit: false,
          });
          setOpenModal(false);
        }}
        service={roleService}
        setRoles={setRoles}
        editRole={editPayload}
      />
    </>
  );
};

export default Roles;
