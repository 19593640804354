import { createContext, useState } from 'react';
import DrawerLayout from '../templates/DrawerLayout';

export const DrawerContext = createContext({})

const DrawerProvider = ({ children }) => {
    
    const [drawerOpen, setDrawerOpen] = useState(true)

    return <DrawerContext.Provider value={{
      drawerOpen,
      setDrawerOpen
    }}>
      <DrawerLayout open={drawerOpen}>
        {children}
      </DrawerLayout>
    </DrawerContext.Provider>

}

export default DrawerProvider