import { useEffect, useState, useContext, useMemo } from "react";
import {
  Drawer,
  Box,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
  IconButton,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DrawerContext } from "../../providers/DrawerProvider";
import { LoadingContext } from "../../providers/LoadingProvider";
import { PermissionsContext } from "../../providers/PermissionsProvider";
import AuthService from "../../services/AuthService";
import Logo from "../../assets/imgs/logoOrange.svg";
import { useTheme } from "@mui/material/styles";
import { useNavigate, useLocation } from "react-router-dom";
import MyCollapse from "../molecules/MyCollapse";
import ConfirmationModal from "../molecules/ConfirmationModal";

const MyDrawer = ({ open }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const location = useLocation();
  const [active, setActive] = useState(null);
  const [openB, setOpenB] = useState(false);
  const [openC, setOpenC] = useState(false);
  const [openS, setOpenS] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const { drawerOpen, setDrawerOpen } = useContext(DrawerContext);
  const { setOpenLoading, setTextLoading } = useContext(LoadingContext);
  const { pers } = useContext(PermissionsContext);
  const authService = useMemo(() => new AuthService(), []);

  const createOption = (per, name, path, icon) => {
    return per
      ? {
          name,
          path,
          icon,
        }
      : null;
  };

  const bOptions = [
    createOption(true, "Pedidos", "/orders", "tags"),
    createOption(
      pers.includes("pages.clients"),
      "Clientes",
      "/clients",
      "address-book"
    ),
    createOption(
      pers.includes("pages.pricelist"),
      "Listas de precios",
      "/price-lists",
      "hand-holding-dollar"
    ),
  ].filter((op) => op !== null);

  const cOptions = [
    createOption(
      pers.includes("pages.products"),
      "Productos",
      "/products",
      "store"
    ),
    createOption(
      pers.includes("pages.sublines"),
      "Sublíneas",
      "/sublines",
      "cubes"
    ),
    createOption(pers.includes("pages.lines"), "Líneas", "/lines", "cube"),
    createOption(pers.includes("pages.sizes"), "Tallas", "/sizes", "ruler"),
    createOption(
      pers.includes("pages.colors"),
      "Colores",
      "/colors",
      "palette"
    ),
  ].filter((op) => op !== null);

  const sOptions = [
    createOption(
      pers.includes("pages.roles"),
      "Roles",
      "/roles",
      "user-shield"
    ),
    createOption(pers.includes("pages.users"), "Usuarios", "/users", "users"),
  ].filter((op) => op !== null);

  const handlerClick = (path) => {
    navigate(path);
  };

  const logout = () => {
    setTextLoading("Cerrando sesión");
    setOpenLoading(true);
    authService.logout();

    setTimeout(() => {
      setOpenLoading(false);
      navigate("/login");
    }, 2000);
  };

  useEffect(() => {
    setActive(location.pathname.split("/")[1]);
  }, [location]);

  const classes = {
    root: {
      width: theme.drawerWidth,
      flexShrink: 0,
      border: "none",
      "& .MuiDrawer-paper": {
        width: theme.drawerWidth,
        boxSizing: "border-box",
        backgroundColor: theme.palette.myGray.main,
      },
    },
    DrawerHeader: {
      width: theme.drawerWidth * 0.96,
      height: "fit-content",
      pt: 5,
      position: "fixed",
      zIndex: "drawer",
      backgroundColor: theme.palette.myGray.main,
    },
    logoContainer: {
      width: theme.drawerWidth * 0.9,
      pl: 1,
    },
    itemButton: {
      pb: 2,
      mb: 1.5,
    },
    itemIcon: {
      minWidth: "fit-content",
      marginRight: 2,
      color: theme.palette.secondary.main,
      fontSize: "1.5rem",
    },
    itemText: {
      margin: 0,
      mb: -1.2,
      color: theme.palette.secondary.main,

      "& > span": {
        fontWeight: 500,
        fontSize: "1.1rem",
      },
    },
    menuBtn: {
      position: "absolute",
      top: 30,
      left: drawerOpen ? theme.drawerWidth + 10 : 11,
      transition: theme.transitions.create("left", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    logoutBtn: {
      transform: "rotate(-180deg)",
      fontSize: "1.5rem !important",
      color: "error.main",
    },
    active: {
      color: "primary.main",
    },
  };

  return (
    <>
      <Drawer sx={classes.root} variant="persistent" anchor="left" open={open}>
        <Box sx={classes.DrawerHeader}>
          <Box component="img" src={Logo} sx={classes.logoContainer} />
          <Divider sx={{ mt: 2.5 }} />
        </Box>
        <List sx={{ paddingX: 1.5, mt: 23 }}>
          <Box sx={{ minHeight: window.outerHeight * 0.47 }}>
            <ListItemButton
              sx={classes.itemButton}
              onClick={() => handlerClick("/")}
            >
              <ListItemIcon
                sx={[
                  classes.itemIcon,
                  active === "dashboard" && classes.active,
                ]}
              >
                <Box component={FontAwesomeIcon} icon="chart-simple" />
              </ListItemIcon>
              <ListItemText
                sx={[
                  classes.itemText,
                  active === "dashboard" && classes.active,
                ]}
                primary="Dashboard"
              />
            </ListItemButton>
            <MyCollapse
              active={active}
              title="Negocio"
              icon="cash-register"
              options={bOptions}
              open={openB}
              setOpen={setOpenB}
            />
            <MyCollapse
              active={active}
              title="Catálogo"
              icon="boxes-packing"
              options={cOptions}
              open={openC}
              setOpen={setOpenC}
            />
          </Box>
          <Box sx={{ mt: 2 }}>
            <Divider sx={{ mb: 2 }} />
            <MyCollapse
              active={active}
              title="Ajustes"
              icon="gear"
              options={sOptions}
              open={openS}
              setOpen={setOpenS}
            />
            <ListItemButton
              sx={[classes.itemButton, { py: 1, mb: 10 }]}
              onClick={() => setOpenModal(true)}
            >
              <ListItemIcon sx={classes.itemIcon}>
                <Box
                  component={FontAwesomeIcon}
                  icon="right-to-bracket"
                  sx={classes.logoutBtn}
                />
              </ListItemIcon>
              <ListItemText
                sx={[classes.itemText, { mb: 0, color: "error.main" }]}
                primary="Cerrar Sesión"
              />
            </ListItemButton>
          </Box>
        </List>
      </Drawer>
      <IconButton
        size="medium"
        sx={classes.menuBtn}
        onClick={() => setDrawerOpen((open) => !open)}
      >
        <Box component={FontAwesomeIcon} icon="bars" />
      </IconButton>
      <ConfirmationModal
        icon="right-to-bracket"
        open={openModal}
        handleClose={() => setOpenModal(false)}
        handleAccept={logout}
        title="cerrar sesión"
        message="Esta seguro de cerrar sesión"
      />
    </>
  );
};

export default MyDrawer;
