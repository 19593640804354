import { useEffect, useContext } from "react";
import {
  Modal,
  Paper,
  Typography,
  FormControl,
  TextField,
  Box,
  Button
} from "@mui/material";
import { DrawerContext } from "../../providers/DrawerProvider"
import { ToastContext } from "../../providers/ToastProvider"
import { validateField, validatePasswd } from '../../common/globalFunctions'
import { useForm } from "react-hook-form"
import { useTheme } from "@mui/material/styles"

const ResetModal = ({ userId, userName, handleClose, handleLoading, service }) => {

  const { drawerOpen } = useContext(DrawerContext)
  const { setShowToast, setToastMessage } = useContext(ToastContext)
  const { register, handleSubmit, reset, watch, formState: { errors } } = useForm()
  const theme = useTheme()
  const passwd = watch("newPassword")

  useEffect(()=>{
    reset()
  },[userId, reset])

  const onSubmit = async(data) => {

    try {
      handleClose()
      handleLoading(userId)
      const res = await service.resetPassword({ ...data, userId })

      if (!res.data.success) {
        return
      }

    } catch (error) {
      setToastMessage(oldToasts=>[...oldToasts,
        {
          severity: "error",
          primary: `Error reseteando clave del usuario ${userName}`
        }
      ])
      setShowToast(true)
    } finally {
      handleLoading(userId)
    }

  }

  const keyPress = (e) => {
    if(e.keyCode === 13){
      handleSubmit(onSubmit)()
    }
  }

  const classes = {
    root: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      left: drawerOpen ? theme.drawerWidth : 0,

      "& .MuiModal-backdrop": {
        left: drawerOpen ? theme.drawerWidth : 0, 
      }

    },
    paper: {
      width: "90%",
      maxWidth: 400,
      textAlign: "center",
      position: "relative",
      top: -50,
      pt: 3,
      pb: 5,
      px: 6,
    },
    actions: {
      display: "flex",
      justifyContent: "center",
      gap: 2
    }
  }

  return <Modal
    open={userId !== -1}
    onClose={handleClose}
    sx={classes.root}
  >
    <Paper sx={classes.paper}>
      <Box component="form" autoComplete='off' noValidate onSubmit={handleSubmit(onSubmit)}>
        <Typography variant="h6" component="h3" mb={3}>
          Resetear Clave
        </Typography>
        <FormControl fullWidth>
          <TextField
            label="Clave del administrador"
            variant="filled"
            type="password"
            autoFocus
            autoComplete="off"
            onKeyDown={keyPress}
            {...register("adminPassword", { required: true })}
            error={errors["adminPassword"] !== undefined}
            helperText={validateField(errors["adminPassword"])}
          />
        </FormControl>
        <FormControl fullWidth sx={{ mt: 3 }}>
          <TextField
            label="Nueva clave del usuario"
            variant="filled"
            type="password"
            autoComplete="off"
            onKeyDown={keyPress}
            {...register("newPassword", { required: true, pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/ })}
            error={errors["newPassword"] !== undefined}
            helperText={validatePasswd(errors["newPassword"]?.type, passwd)}
          />
        </FormControl>
        <Box mt={3} sx={classes.actions}>
          <Button
            type="submit"
            variant="contained" 
          >
            Aceptar
          </Button>
          <Button variant="contained" color="error" onClick={handleClose}>Cancelar</Button>
        </Box>
      </Box>
    </Paper>
  </Modal>
}

export default ResetModal