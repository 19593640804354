import { useEffect, useCallback, useMemo, useState, useContext } from "react";
import {
  Box,
  IconButton,
  Button,
  CircularProgress,
  ListItemText,
} from "@mui/material";
import { DataGrid, GridCellModes, useGridApiRef } from "@mui/x-data-grid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate, useLocation } from "react-router-dom";
import { TopBarContext } from "../../providers/TopBarProvider";
import { ToastContext } from "../../providers/ToastProvider";
import PriceListService from "../../services/PriceListService";
import PriceCreator from "../molecules/PriceCreator";
import CustomNoRows from "../atoms/CustomNoRows";
import { getCurrency, toCapitalize } from "../../common/globalFunctions";

const Prices = () => {
  const apiRef = useGridApiRef();
  const [priceListId, setPriceListId] = useState(-1);
  const [openModal, setOpenModal] = useState(false);
  const [focuseds, setFocuseds] = useState({});
  const [prices, setPrices] = useState([]);
  const [error, setError] = useState(false);
  const [count, setCount] = useState(0);
  const [mySearch, setMySearch] = useState("");
  const [itemsLoading, setItemsLoading] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { setTitle, setSearchOptions, setBtnPrimary } =
    useContext(TopBarContext);
  const { setShowToast, setToastMessage } = useContext(ToastContext);
  const priceListService = useMemo(() => new PriceListService(), []);
  const navigate = useNavigate();
  const location = useLocation();
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 25,
    page: 0,
  });
  const columns = [
    {
      field: "productPrice",
      headerName: "Precios",
      minWidth: 200,
      flex: 1,
      sorting: false,
      editable: true,
      renderCell: (item) => {
        return (
          <ListItemText
            primary={getCurrency(item.row.productPrice)}
            primaryTypographyProps={{
              fontSize: "1.1rem",
            }}
            secondary={item.row.productReference}
          />
        );
      },
    },
    {
      field: "edit",
      headerName: "Acciones",
      sortable: false,
      width: 135,
      renderHeader: () => (
        <Button
          variant="text"
          size="small"
          color="success"
          onClick={() => setOpenModal(true)}
        >
          <Box component={FontAwesomeIcon} icon="plus" mr={1} /> Agregar
        </Button>
      ),
      renderCell: (row) => {
        const item = itemsLoading.find((i) => i.id === row.id);

        if (item?.id && item?.edit) {
          return (
            <Box mr={1}>
              Editando{" "}
              <CircularProgress
                color="primary"
                size={25}
                sx={{ verticalAlign: "text-bottom" }}
              />
            </Box>
          );
        }

        if (item?.id && !item?.edit) {
          return (
            <Box mr={1}>
              Eliminando{" "}
              <CircularProgress
                color="error"
                size={25}
                sx={{ verticalAlign: "text-bottom" }}
              />
            </Box>
          );
        }

        return (
          <>
            <IconButton
              onClick={() => focusToInput(row.id)}
              sx={{ float: "right" }}
            >
              {focuseds[row.id]?.productPrice?.mode === GridCellModes.Edit ? (
                <Box
                  component={FontAwesomeIcon}
                  icon="check"
                  fontSize="1rem"
                  color="success.main"
                />
              ) : (
                <Box
                  component={FontAwesomeIcon}
                  icon="pencil"
                  fontSize="1rem"
                  color="secondary.main"
                />
              )}
            </IconButton>
            <IconButton
              onClick={() => deletePrice(row.id, row.row.productPrice)}
              sx={{ float: "right", ml: 2 }}
            >
              <Box
                component={FontAwesomeIcon}
                icon="trash"
                fontSize="1rem"
                color="error.main"
              />
            </IconButton>
          </>
        );
      },
    },
  ];

  const getAll = useCallback(
    async (id = -1, skipCount = 0, search) => {
      try {
        setIsLoading(true);

        const skip = skipCount * 25;
        if (prices.length !== 0 && prices.length > skip) {
          return;
        }

        const res = await priceListService.getProducts(id, 25, skip, search);
        const totalCount = res.data.result.totalCount;
        const items = res.data.result.items;

        if (items.length === 0) {
          return;
        }

        setCount(totalCount);
        setPrices([
          ...prices,
          ...items.map((item) => ({
            ...item,
            id: Number(item.productId),
            selectable: true,
          })),
        ]);
      } catch {
        setError(true);
      } finally {
        setIsLoading(false);
      }
    },
    [priceListService, setPrices, prices]
  );

  const focusToInput = (id) => {
    setFocuseds((oldFocuseds) => ({
      ...oldFocuseds,
      [id]: {
        productPrice: {
          mode:
            oldFocuseds[id]?.productPrice?.mode === GridCellModes.Edit
              ? GridCellModes.View
              : GridCellModes.Edit,
        },
      },
    }));
  };

  const editPrice = async (product) => {
    try {
      var id = product.id;
      var oldValue = product.productPrice;
      const cell = await apiRef.current.getCellElement(id, "productPrice");
      const value = cell.firstChild.firstChild.value;

      if (value === oldValue) {
        return;
      }

      setItemsLoading((oldItems) => [
        ...oldItems,
        {
          id,
          edit: true,
        },
      ]);

      if (value < 0) {
        throw new Error("Pattern");
      }

      const payload = {
        priceListId: priceListId,
        productId: product.productId,
        price: value,
      };

      const res = await priceListService.updateProduct(payload);

      if (!res.data.success) {
        return;
      }

      setPrices((oldPrices) => {
        const idx = oldPrices.findIndex((price) => price.productId === id);
        oldPrices[idx].productPrice = value;
        return [...oldPrices];
      });
    } catch (error) {
      if (error.message === "Pattern") {
        await apiRef.current.setEditCellValue({
          id,
          field: "productPrice",
          value: oldValue,
        });
      } else {
        setPrices((oldPrices) => [...oldPrices]);
      }

      setToastMessage([
        {
          severity: "error",
          primary:
            error.message === "Pattern"
              ? "Entrada invalida"
              : `Error al intentar editar el precio`,
        },
      ]);
      setShowToast(true);
    } finally {
      setItemsLoading((oldItems) => [
        ...oldItems.filter((item) => item.id !== id),
      ]);
    }
  };

  const deletePrice = async (productId, oldValue) => {
    try {
      setItemsLoading((oldItems) => [
        ...oldItems,
        {
          id: productId,
          edit: false,
        },
      ]);

      const res = await priceListService.deleteProduct({
        productId,
        priceListId: priceListId,
      });

      if (!res.data.success) {
        return;
      }

      setPrices((oldPrices) => [
        ...oldPrices.filter((price) => price.productId !== productId),
      ]);
      setCount(count - 1);
    } catch (error) {
      setToastMessage((oldToasts) => [
        ...oldToasts,
        {
          severity: "error",
          primary: `Error al intentar eliminar el precio`,
        },
      ]);
      setShowToast(true);
    } finally {
      setItemsLoading((oldItems) => [
        ...oldItems.filter((item) => item.id !== productId),
      ]);
    }
  };

  const getPriceList = useCallback(async () => {
    const id = Number(location.search.split("=")[1]);
    setPriceListId(id);

    setIsLoading(true);

    try {
      const res = await priceListService.get(id);

      if (!res.data.success) {
        return;
      }

      setTitle(toCapitalize(res.data.result.name));
      getAll(id);
    } catch (error) {
      setToastMessage([
        {
          severity: "error",
          primary: "Error al intentar obtener los precios de la lista",
        },
      ]);
      setShowToast(true);
      navigate("/price-lists");
    }
  }, [
    location,
    getAll,
    priceListService,
    setTitle,
    setToastMessage,
    setShowToast,
    setPriceListId,
    navigate,
  ]);

  useEffect(() => {
    setTitle("Cargando...");
    setSearchOptions({
      label: "Buscar precio",
      action: (search) => {
        setMySearch(search);
        getAll(priceListId, 0, search);
      },
    });
    setBtnPrimary({
      label: "Ver clientes",
      action: () => navigate(`/price-lists/clients?id=${priceListId}`),
      icon: "address-book",
    });
  }, [setTitle, setSearchOptions, setBtnPrimary, priceListId, navigate]);

  useEffect(() => {
    getPriceList();
  }, [getPriceList]);

  useEffect(() => {
    if (prices.length > 0) {
      getAll(priceListId, paginationModel.page, mySearch);
    }
    setFocuseds({});
  }, [getAll, prices, paginationModel, priceListId]);

  useEffect(() => {
    const nPrices = prices.length;
    if (count < nPrices) {
      setCount(nPrices);
    }
  }, [prices]);

  const classes = {
    root: {
      height: "70vh",
      width: "100%",
      mt: 5,
    },
    datagrid: {
      marginTop: 5,
      fontSize: "1.1rem",
      ".MuiDataGrid-cell--editing .MuiListItemText-root": {
        fontSize: "1.1rem",
      },
      ".MuiDataGrid-cell--editing input": {
        fontSize: "1.1rem",
      },
      ".MuiDataGrid-cell:focus,.MuiDataGrid-cell:focus-within": {
        outline: "none",
      },
    },
  };

  return (
    <Box sx={classes.root}>
      <DataGrid
        sx={classes.datagrid}
        apiRef={apiRef}
        columns={columns}
        rows={prices}
        rowCount={count}
        editMode="cell"
        getRowId={(row) => row.id}
        rowHeight={68}
        cellModesModel={focuseds}
        disableColumnMenu
        disableRowSelectionOnClick
        isRowSelectable={(item) => item.row.selectable}
        isCellEditable={(item) => item.row.selectable}
        pageSizeOptions={[25]}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        loading={isLoading}
        localeText={{
          toolbarColumns: "Columnas",
          toolbarColumnsLabel: "Seleccionar columnas",
          noRowsLabel: "Sin Precios",
          footerRowSelected: (count) =>
            count !== 1
              ? `${count.toLocaleString()} filas seleccionadas`
              : `${count.toLocaleString()} fila seleccionada`,
          MuiTablePagination: {
            labelDisplayedRows: ({ from, to, count }) =>
              `${from} - ${to} de ${count}`,
          },
        }}
        slotProps={{
          pagination: {
            labelRowsPerPage: "Filas por página",
          },
        }}
        slots={{
          noRowsOverlay: () => (
            <CustomNoRows
              message={
                error
                  ? "Error intentando obtener los precios"
                  : "No hay precios registrados"
              }
            />
          ),
          noResultsOverlay: () => (
            <CustomNoRows
              message={
                error
                  ? "Error intentando obtener los precios"
                  : "No hay precios registrados"
              }
            />
          ),
        }}
        onCellEditStop={(item, event) => {
          if (focuseds[item.id]?.productPrice?.mode === GridCellModes.Edit) {
            focusToInput(item.id);
          }
          editPrice(item.row);
        }}
      />
      <PriceCreator
        open={openModal}
        priceListId={priceListId}
        handleClose={() => setOpenModal(false)}
        setRows={setPrices}
      />
    </Box>
  );
};

export default Prices;
