import HttpService from "./HttpService";
import { api } from "../common/enviroment";

export default class RoleService {
  httpService = new HttpService();

  getAll = (maxResultCount, skipCount, search) => {
    return this.httpService.get(
      `/services/app${api.ROLE_SERVICE}/GetAllRoles?${
        maxResultCount ? "MaxResultCount=" + maxResultCount + "&" : ""
      }${skipCount ? "SkipCount=" + skipCount + "&" : ""}${
        search ? "Search=" + search + "&" : ""
      }`
    );
  };

  getAllPermissions = () => {
    return this.httpService.get(
      `/services/app${api.ROLE_SERVICE}/GetAllPermissions`
    );
  };

  get = (id) => {
    return this.httpService.get(
      `/services/app${api.ROLE_SERVICE}/Get?${id ? "Id=" + id : ""}`
    );
  };

  getForEdit = (id) => {
    return this.httpService.get(
      `/services/app${api.ROLE_SERVICE}/GetRoleForEdit?${id ? "Id=" + id : ""}`
    );
  };

  create = (data) => {
    return this.httpService.post(
      `/services/app${api.ROLE_SERVICE}/Create`,
      data
    );
  };

  update = (data) => {
    return this.httpService.put(
      `/services/app${api.ROLE_SERVICE}/Update`,
      data
    );
  };

  delete = (id) => {
    return this.httpService.delete(
      `/services/app${api.ROLE_SERVICE}/Delete?${id ? "Id=" + id : ""}`
    );
  };
}
