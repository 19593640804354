import { useState } from "react"
import {  
  Box,
  IconButton,
  Popover,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText
} from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const UserPopoverOptions = ({ disabled, handleDelete, handleReset, handleEdit }) => {

  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  const myHandleDelete = () => {
    setAnchorEl(null);
    handleDelete()
  }

  const classes = {
    optionsBtn: {
      position: "absolute",
      top: 0,
      right: 5,
      ml: 5
    },
    itemIcon: {
      minWidth: "fit-content",
      mr: 2
    },
    itemText: {
      "& > span": {
        fontWeight: 500,
      }
    }
  }

  return <>
    <IconButton disabled={disabled} sx={classes.optionsBtn} onClick={handleClick}>
      <Box component={FontAwesomeIcon} icon="ellipsis" />
    </IconButton>
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: -145
      }}
    >
      <List sx={{paddingX: 1.5}}>
        <ListItemButton onClick={handleEdit} >
          <ListItemIcon sx={classes.itemIcon}>
            <Box component={FontAwesomeIcon} icon="pencil"  />
          </ListItemIcon>
          <ListItemText sx={classes.itemText} primary="Editar" />
        </ListItemButton>
        <ListItemButton onClick={handleReset} >
          <ListItemIcon sx={classes.itemIcon}>
            <Box component={FontAwesomeIcon} icon="unlock"  />
          </ListItemIcon>
          <ListItemText sx={classes.itemText} primary="Resetear Clave" />
        </ListItemButton>
        <ListItemButton sx={{color: "error.main"}} onClick={myHandleDelete} >
          <ListItemIcon sx={classes.itemIcon}>
            <Box component={FontAwesomeIcon} icon="trash" sx={{color: "error.main"}}  />
          </ListItemIcon>
          <ListItemText sx={classes.itemText} primary="Eliminar" />
        </ListItemButton>
      </List>
    </Popover>
  </>

}

export default UserPopoverOptions