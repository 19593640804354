import { useEffect, useMemo, useCallback, useContext } from "react";
import { Box, FormControl, TextField, useMediaQuery } from "@mui/material";
import { TopBarContext } from "../../providers/TopBarProvider";
import { useTheme } from "@mui/material/styles";
import { useForm } from "react-hook-form";
import { validateField, toCapitalize } from "../../common/globalFunctions";
import { LoadingContext } from "../../providers/LoadingProvider";
import { ToastContext } from "../../providers/ToastProvider";
import ClientService from "../../services/ClientService";
import { useNavigate, useLocation } from "react-router-dom";

const NewClient = ({ edit }) => {
  const { setTitle, setSearchOptions, setBtnPrimary } =
    useContext(TopBarContext);
  const { setOpenLoading, setTextLoading } = useContext(LoadingContext);
  const { setShowToast, setToastMessage } = useContext(ToastContext);
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const bpMdDown = useMediaQuery(theme.breakpoints.down("md"));
  const clientService = useMemo(() => new ClientService(), []);

  const onSubmit = useCallback(
    async (data) => {
      setTextLoading(edit ? "Actualizando cliente" : "Agregando cliente");
      setOpenLoading(true);

      data.companyName = toCapitalize(data.companyName);
      data.personInChargeName = toCapitalize(data.personInChargeName);

      try {
        edit
          ? await clientService.update(data)
          : await clientService.create(data);

        setToastMessage([
          {
            severity: "success",
            primary: `Cliente ${edit ? "editado" : "agregado"} exitosamente`,
          },
        ]);
      } catch {
        setToastMessage([
          {
            severity: "error",
            primary: `Error al ${
              edit ? "editar" : "agregar"
            } cliente, por favor intentelo nuevamente`,
          },
        ]);
      } finally {
        setOpenLoading(false);
        setShowToast(true);
        navigate("/clients");
      }
    },
    [
      edit,
      setTextLoading,
      setOpenLoading,
      clientService,
      setToastMessage,
      setShowToast,
      navigate,
    ]
  );

  const loadFields = useCallback(async () => {
    if (edit) {
      const id = Number(location.search.split("=")[1]);
      let client = location.state;

      if (!isNaN(id) && client?.id !== id) {
        try {
          const res = await clientService.get(id);

          client = { ...res.data.result };
        } catch {
          setToastMessage([
            {
              severity: "error",
              primary: `Error al intentar obtener los datos del usuario`,
            },
          ]);
          setShowToast(true);
          navigate("/clients");
        }
      }
      setValue("id", client.id);
      setValue("companyName", client.companyName);
      setValue("nit", client.nit);
      setValue("personInChargeName", client.personInChargeName);
      setValue("personInChargePhone", client.personInChargePhone);
      setValue("address", client.address);
    } else {
      reset();
    }
  }, [
    location,
    navigate,
    clientService,
    setToastMessage,
    setShowToast,
    edit,
    reset,
    setValue,
  ]);

  useEffect(() => {
    setTitle(edit ? "Editar Cliente" : "Agregar Cliente");
    setSearchOptions({
      action: null,
    });
    setBtnPrimary({
      label: edit ? "Actualizar cliente" : "Enviar datos",
      action: () => handleSubmit(onSubmit)(),
      icon: edit ? "rotate" : "paper-plane",
    });
  }, [edit, setTitle, setSearchOptions, setBtnPrimary, handleSubmit, onSubmit]);

  useEffect(() => {
    loadFields();
  }, [loadFields]);

  const classes = {
    root: {
      maxWidth: "md",
      ml: bpMdDown ? 0 : 8,
      mt: 8,
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      justifyContent: "space-between",
    },
    inputControl: {
      width: bpMdDown ? "100%" : "47%",
    },
  };

  return (
    <Box component="form" autoComplete="off" noValidate sx={classes.root}>
      <input type="hidden" {...register("id")} defaultValue={0} />
      <FormControl sx={classes.inputControl}>
        <TextField
          label="Nombre de la compañia"
          variant="filled"
          sx={{ marginBottom: 4 }}
          InputLabelProps={edit ? { shrink: true } : null}
          disabled={edit}
          {...register("companyName", { required: true })}
          error={errors["companyName"] !== undefined}
          helperText={validateField(errors["companyName"])}
        />
      </FormControl>
      <FormControl sx={classes.inputControl}>
        <TextField
          label="NIT"
          type="number"
          variant="filled"
          sx={{ marginBottom: 4 }}
          InputLabelProps={edit ? { shrink: true } : null}
          disabled={edit}
          {...register("nit", { required: true, minLength: 6, maxLength: 20 })}
          error={errors["nit"] !== undefined}
          helperText={validateField(errors["nit"], 6, 20)}
        />
      </FormControl>
      <FormControl sx={classes.inputControl}>
        <TextField
          label="Dirección"
          variant="filled"
          sx={{ marginBottom: 4 }}
          InputLabelProps={edit ? { shrink: true } : null}
          {...register("address", {
            required: true,
            pattern: /^[#.0-9a-zA-Z\s,-]+$/,
          })}
          error={errors["address"] !== undefined}
          helperText={validateField(errors["address"])}
        />
      </FormControl>
      <FormControl sx={classes.inputControl}>
        <TextField
          label="Persona encargada"
          variant="filled"
          sx={{ marginBottom: 4 }}
          InputLabelProps={edit ? { shrink: true } : null}
          {...register("personInChargeName", {
            required: true,
            pattern: /^[a-zA-Z][a-zA-Z0-9 ]?/,
          })}
          error={errors["personInChargeName"] !== undefined}
          helperText={validateField(errors["personInChargeName"])}
        />
      </FormControl>
      <FormControl sx={classes.inputControl}>
        <TextField
          label="Celular"
          type="number"
          variant="filled"
          sx={{ marginBottom: 4 }}
          InputLabelProps={edit ? { shrink: true } : null}
          {...register("personInChargePhone", {
            required: true,
            minLength: 6,
            maxLength: 20,
          })}
          error={errors["personInChargePhone"] !== undefined}
          helperText={validateField(errors["personInChargePhone"], 6, 20)}
        />
      </FormControl>
    </Box>
  );
};

export default NewClient;
