import { useState } from "react";
import {
  Box,
  Paper,
  Typography,
  Chip,
  Divider,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toCapitalize } from "../../common/globalFunctions";
import { useTheme } from "@mui/material/styles";
// import PopoverOptions from './PopoverOptions'
import ProductPopoverOptions from "./ProductPopoverOptions";

const ProductItem = ({
  reference,
  description,
  line,
  subline,
  sizes,
  colors,
  handleDelete,
  handleEdit,
  handleStock,
}) => {
  const [deleting, setDeleting] = useState(false);
  const theme = useTheme();

  const myHandleDelete = async () => {
    setDeleting(true);
    try {
      await handleDelete();
    } catch {
      setDeleting(false);
    }
  };

  const classes = {
    root: {
      width: "100%",
      borderRadius: "10px",
      padding: 1,
    },
    itemHeader: {
      display: "flex",
      position: "relative",
      bgColor: theme.palette.myGray.main,
      backgroundColor: theme.palette.myGray.main,
      padding: theme.spacing(1, 2),
      borderRadius: "10px",
    },
    itemAvatar: {
      width: 65,
      height: 65,
      borderRadius: 65,
      mr: 2,
      backgroundColor: theme.palette.tertiary.main,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    itemOptions: {
      position: "absolute",
      top: 0,
      right: 5,
      ml: 5,
    },
    itemBody: {
      padding: 2,
    },
    itemChip: {
      fontWeight: "bold",
      borderRadius: 2,
      fontSize: "1rem",
      marginBottom: 1,
    },
    progress: {
      ml: 2,
      verticalAlign: "text-bottom",
    },
    icon: {
      fontSize: 20,
      mr: 1,
      verticalAlign: "text-bottom",
    },
  };

  return (
    <Paper elevation={2} sx={classes.root}>
      <Box sx={classes.itemHeader}>
        <Box sx={classes.itemAvatar}>
          <Box
            component={FontAwesomeIcon}
            sx={{ color: "secondary.main" }}
            icon="shirt"
            size="2x"
          />
        </Box>
        <Box>
          <Tooltip title={reference} placement="top-start">
            <Typography component="h4" variant="h6" fontWeight="bold">
              {reference.length > 14
                ? toCapitalize(reference).substring(0, 14).concat("...")
                : toCapitalize(reference)}
            </Typography>
          </Tooltip>
          <Tooltip title={description} placement="bottom-start">
            <Typography component="p" variant="subtitle1">
              {description.length > 30
                ? description.substring(0, 27).concat("...")
                : description}
            </Typography>
          </Tooltip>
        </Box>
        <ProductPopoverOptions
          deleting={deleting}
          handleDelete={myHandleDelete}
          handleEdit={handleEdit}
          handleStock={handleStock}
        />
      </Box>
      <Box sx={classes.itemBody}>
        <Chip
          label={
            <>
              <Box component={FontAwesomeIcon} icon="cube" mr={1} />
              {line.length > 55 ? line.substring(0, 60).concat("...") : line}
            </>
          }
          variant="outlined"
          sx={[classes.itemChip, { mr: 1 }]}
        />
        <Chip
          label={
            <>
              <Box component={FontAwesomeIcon} icon="cubes" mr={1} />
              {subline.length > 55
                ? subline.substring(0, 60).concat("...")
                : subline}
            </>
          }
          variant="outlined"
          sx={classes.itemChip}
        />
        <Typography component="p" variant="subtitle1" my={1}>
          <b>Tallas:</b> {sizes.join(", ")}
        </Typography>
        <Typography component="p" variant="subtitle1" my={1} mb={2}>
          <b>Colores:</b> {colors.join(", ")}
        </Typography>
        <Divider sx={{ borderWidth: 1 }} />
        {deleting ? (
          <Typography component="p" variant="h6" mt={2} color="error">
            Eliminando{" "}
            <CircularProgress color="error" size={25} sx={classes.progress} />
          </Typography>
        ) : (
          <Typography
            component="p"
            variant="h6"
            fontSize="1rem"
            mt={2}
            color="light.main"
          >
            <Box
              component={FontAwesomeIcon}
              sx={classes.icon}
              icon="circle-info"
            />{" "}
            Barra Informativa
          </Typography>
        )}
      </Box>
    </Paper>
  );
};

export default ProductItem;
