import DrawerProvider from "../providers/DrawerProvider"
import ToastProvider from "../providers/ToastProvider"
import { Outlet } from "react-router-dom"

const MainLayout = () => {
  return <DrawerProvider>
    <ToastProvider>
      <Outlet />
    </ToastProvider>
  </DrawerProvider>
}

export default MainLayout