import { useEffect, useState } from "react";
import {
  Box,
  FormControl,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SearchableSelectOff = ({
  primaryKey = "description",
  label,
  placeholder = "Buscar",
  value,
  initialValue,
  disabled,
  handleChange,
}) => {
  const [ myValue, setMyValue ] = useState(value)
  const [options, setOptions] = useState([...initialValue]);
  const [search, setSearch] = useState("");

  const myHandleChange = (_, option) => {
    handleChange(option);
  };

  const myHandleSearch = (value) => {
    setOptions([
      ...initialValue.filter((op) => value === "" || op[primaryKey].substring(0, value.length).toLowerCase() === value.toLowerCase()),
    ]);
  };

  const onPressEnter = (e) => {
    if (e.keyCode === 13) {
      myHandleSearch(e.target.value);
    }
  };

  const onKeyPress = (e) => {
    e.stopPropagation();
  };

  useEffect(() => {
    setOptions([...initialValue]);
  }, [initialValue]);

  useEffect(()=>{
    setMyValue(value)
  },[value])

  const classes = {
    root: {
      textAlign: "left",
    },
    selectInput: {
      "& input": {
        height: 10,
      },
    },
    progress: {
      ml: 2,
      verticalAlign: "text-bottom",
    },
  };

  return (
    <>
      {label && <InputLabel id="label">{label}</InputLabel>}
      <Select
        labelId="label"
        value={myValue}
        defaultValue=""
        sx={classes.root}
        onChange={myHandleChange}
        disabled={disabled}
      >
        <FormControl
          onKeyDown={onKeyPress}
          key="searchInput"
          fullWidth
          sx={{ px: 1, mb: 2 }}
        >
          <TextField
            placeholder={placeholder}
            sx={classes.selectInput}
            onKeyDown={onPressEnter}
            autoComplete="off"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" onClick={myHandleSearch}>
                    <Box
                      component={FontAwesomeIcon}
                      icon="magnifying-glass"
                      fontSize="1rem"
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </FormControl>
        <MenuItem key="defaultValue" value="">
          <em>Ninguno</em>
        </MenuItem>
        {options.map((option) => (
          <MenuItem key={`option-${option.id}`} value={option.id}>
            {option[primaryKey]}
          </MenuItem>
        ))}
      </Select>
    </>
  );
};

export default SearchableSelectOff;
