import { useEffect, useCallback, useMemo, useState, useContext } from "react";
import { Box, IconButton, ListItemText, CircularProgress } from "@mui/material";
import { DataGrid, GridCellModes, useGridApiRef } from "@mui/x-data-grid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { TopBarContext } from "../../providers/TopBarProvider";
import { ToastContext } from "../../providers/ToastProvider";
import SublineService from "../../services/SublineService";
import SublineCreator from "../molecules/SublineCreator";
import CustomNoRows from "../atoms/CustomNoRows";

const Sublines = () => {
  const apiRef = useGridApiRef();
  const [openModal, setOpenModal] = useState(false);
  const [focuseds, setFocuseds] = useState({});
  const [sublines, setSublines] = useState([]);
  const [error, setError] = useState(false);
  const [count, setCount] = useState(0);
  const [mySearch, setMySearch] = useState("");
  const [itemsLoading, setItemsLoading] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { setTitle, setSearchOptions, setBtnPrimary } =
    useContext(TopBarContext);
  const { setShowToast, setToastMessage } = useContext(ToastContext);
  const sublineService = useMemo(() => new SublineService(), []);
  const navigate = useNavigate();
  const theme = useTheme();
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });
  const columns = [
    {
      field: "description",
      headerName: "Sublínea",
      minWidth: 200,
      // maxWidth: theme.breakpoints.values.xl,
      flex: 1,
      sorting: false,
      editable: true,
      renderCell: (item) => (
        <ListItemText
          primary={item.row.description}
          primaryTypographyProps={{
            fontSize: "1.1rem",
          }}
          secondary={item.row.lineName}
        />
      ),
    },
    {
      field: "edit",
      headerName: "Acciones",
      sortable: false,
      width: 135,
      renderCell: (row) => {
        const item = itemsLoading.find((i) => i.id === row.id);
        if (item?.id && item?.edit) {
          return (
            <Box mr={1}>
              Editando{" "}
              <CircularProgress
                color="primary"
                size={25}
                sx={{ verticalAlign: "text-bottom" }}
              />
            </Box>
          );
        }

        if (item?.id && !item?.edit) {
          return (
            <Box mr={1}>
              Eliminando{" "}
              <CircularProgress
                color="error"
                size={25}
                sx={{ verticalAlign: "text-bottom" }}
              />
            </Box>
          );
        }

        return (
          <>
            <IconButton
              onClick={() => focusToInput(row.id)}
              sx={{ float: "right" }}
            >
              {focuseds[row.id]?.description?.mode === GridCellModes.Edit ? (
                <Box
                  component={FontAwesomeIcon}
                  icon="check"
                  fontSize="1rem"
                  color="success.main"
                />
              ) : (
                <Box
                  component={FontAwesomeIcon}
                  icon="pencil"
                  fontSize="1rem"
                  color="secondary.main"
                />
              )}
            </IconButton>
            <IconButton
              onClick={() => deleteSubline(row.id, row.row.description)}
              sx={{ float: "right", ml: 2 }}
            >
              <Box
                component={FontAwesomeIcon}
                icon="trash"
                fontSize="1rem"
                color="error.main"
              />
            </IconButton>
          </>
        );
      },
    },
  ];

  const getAll = useCallback(
    async (skipCount = 0, search) => {
      try {
        setIsLoading(true);

        const skip = skipCount * 10;
        if (sublines.length !== 0 && sublines.length > skip) {
          return;
        }

        const res = await sublineService.getAll(10, skip, search);
        const totalCount = res.data.result.totalCount;
        const items = res.data.result.items;

        if (items.length === 0 && search === mySearch) {
          return;
        }

        setCount(totalCount);
        setSublines([
          ...(search === mySearch ? sublines : []),
          ...items.map((item) => ({
            ...item,
            id: Number(item.id),
            selectable: true,
          })),
        ]);
        setMySearch(search);
      } catch {
        setError(true);
      } finally {
        setIsLoading(false);
      }
    },
    [sublineService, setSublines, sublines]
  );

  const focusToInput = (id) => {
    setFocuseds((oldFocuseds) => ({
      ...oldFocuseds,
      [id]: {
        description: {
          mode:
            oldFocuseds[id]?.description?.mode === GridCellModes.Edit
              ? GridCellModes.View
              : GridCellModes.Edit,
        },
      },
    }));
  };

  const editSubline = async (id, oldValue, lineId) => {
    try {
      const cell = await apiRef.current.getCellElement(id, "description");
      const value = cell.firstChild.firstChild.value;

      if (value === oldValue) {
        return;
      }

      setItemsLoading((oldItems) => [
        ...oldItems,
        {
          id,
          edit: true,
        },
      ]);

      const regex = /^[a-zA-Z][a-zA-Z0-9 ]?/;
      if (!regex.test(value)) {
        throw new Error("Pattern");
      }

      const payload = {
        id,
        lineId,
        description: value,
      };

      const res = await sublineService.update(payload);

      if (!res.data.success) {
        return;
      }

      setSublines((oldLines) => {
        const idx = oldLines.findIndex((subline) => subline.id === id);
        oldLines[idx].description = value;
        return [...oldLines];
      });
    } catch (error) {
      if (error.message === "Pattern") {
        await apiRef.current.setEditCellValue({
          id,
          field: "description",
          value: oldValue,
        });
      } else {
        setSublines((oldLines) => [...oldLines]);
      }

      setToastMessage([
        {
          severity: "error",
          primary:
            error.message === "Pattern"
              ? "Entrada invalida"
              : error.response.data.error.message.includes("used")
              ? `La sublínea ${oldValue} esta en uso`
              : `Error al intentar editar la sublínea ${oldValue}`,
        },
      ]);
      setShowToast(true);
    } finally {
      setItemsLoading((oldItems) => [
        ...oldItems.filter((item) => item.id !== id),
      ]);
    }
  };

  const deleteSubline = async (id, oldValue) => {
    try {
      setItemsLoading((oldItems) => [
        ...oldItems,
        {
          id,
          edit: false,
        },
      ]);

      const res = await sublineService.delete(id);

      if (!res.data.success) {
        return;
      }

      setSublines((oldLines) => [
        ...oldLines.filter((subline) => subline.id !== id),
      ]);
      setCount(count - 1);
    } catch (error) {
      setToastMessage((oldToasts) => [
        ...oldToasts,
        {
          severity: "error",
          primary: error.response.data.error.message.includes("used")
            ? `La sublínea ${oldValue} esta en uso`
            : `Error al intentar eliminar la sublínea ${oldValue}`,
        },
      ]);
      setShowToast(true);
    } finally {
      setItemsLoading((oldItems) => [
        ...oldItems.filter((item) => item.id !== id),
      ]);
    }
  };

  useEffect(() => {
    setTitle("Sublíneas");
    setSearchOptions({
      label: "Buscar Sublínea",
      action: (search) => {
        getAll(0, search);
      },
    });
    setBtnPrimary({
      label: "Agregar Sublínea",
      action: () => setOpenModal(true),
      icon: "plus",
    });
  }, [setTitle, setSearchOptions, setBtnPrimary, navigate]);

  useEffect(() => {
    getAll();
  }, []);

  useEffect(() => {
    if (sublines.length > 0) {
      getAll(paginationModel.page, mySearch);
    }
    setFocuseds({});
  }, [getAll, sublines, paginationModel]);

  useEffect(() => {
    const nSublines = sublines.length;
    if (count < nSublines) {
      setCount(nSublines);
    }
  }, [sublines]);

  const classes = {
    root: {
      height: "70vh",
      width: "100%",
      mt: 5,
    },
    datagrid: {
      marginTop: 5,
      fontSize: "1.1rem",
      ".MuiDataGrid-cell--editing .MuiListItemText-root": {
        fontSize: "1.1rem",
      },
      ".MuiDataGrid-cell--editing input": {
        fontSize: "1.1rem",
      },
      ".MuiDataGrid-cell:focus,.MuiDataGrid-cell:focus-within": {
        outline: "none",
      },
    },
  };

  return (
    <Box sx={classes.root}>
      <DataGrid
        sx={classes.datagrid}
        apiRef={apiRef}
        columns={columns}
        rows={sublines}
        rowCount={count}
        editMode="cell"
        getRowId={(row) => row.id}
        rowHeight={68}
        cellModesModel={focuseds}
        disableColumnMenu
        disableRowSelectionOnClick
        isRowSelectable={(item) => item.row.selectable}
        isCellEditable={(item) => item.row.selectable}
        pageSizeOptions={[10]}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        loading={isLoading}
        localeText={{
          toolbarColumns: "Columnas",
          toolbarColumnsLabel: "Seleccionar columnas",
          noRowsLabel: "Sin Colores",
          footerRowSelected: (count) =>
            count !== 1
              ? `${count.toLocaleString()} filas seleccionadas`
              : `${count.toLocaleString()} fila seleccionada`,
          MuiTablePagination: {
            labelDisplayedRows: ({ from, to, count }) =>
              `${from} - ${to} de ${count}`,
          },
        }}
        slotProps={{
          pagination: {
            labelRowsPerPage: "Filas por página",
          },
        }}
        slots={{
          noRowsOverlay: () => (
            <CustomNoRows
              message={
                error
                  ? "Error intentando obtener las sublínea"
                  : "No hay sublínea registradas"
              }
            />
          ),
          noResultsOverlay: () => (
            <CustomNoRows
              message={
                error
                  ? "Error intentando obtener las sublínea"
                  : "No hay sublínea registradas"
              }
            />
          ),
        }}
        onCellEditStop={(item) => {
          if (focuseds[item.id]?.description?.mode === GridCellModes.Edit) {
            focusToInput(item.id);
          }
          editSubline(item.id, item.value, item.row.lineId);
        }}
      />
      <SublineCreator
        open={openModal}
        handleClose={() => setOpenModal(false)}
        setRows={setSublines}
      />
    </Box>
  );
};

export default Sublines;
