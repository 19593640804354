import { useEffect, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import {
  Modal,
  Paper,
  Typography,
  Box,
  FormControl,
  FormHelperText,
  Button,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ToastContext } from "../../providers/ToastProvider";
import { DrawerContext } from "../../providers/DrawerProvider";
import { useForm } from "react-hook-form";
import { useTheme } from "@mui/material/styles";
import SearchableSelectV2 from "../atoms/SearchableSelectV2";
import ClientService from "../../services/ClientService";

const OrderClient = ({ open, handleClose }) => {
  const navigate = useNavigate();
  const { setShowToast, setToastMessage } = useContext(ToastContext);
  const { drawerOpen } = useContext(DrawerContext);
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm();
  const clientService = useMemo(() => new ClientService(), []);
  const client = watch("client");
  const theme = useTheme();

  const onSubmit = async (data) => {
    const client = JSON.parse(data.client);
    const priceList = client.priceListId;

    if (priceList === undefined || priceList === null) {
      setToastMessage([
        {
          severity: "error",
          primary: `Este cliente aun no tiene una lista de precios asignado`,
        },
      ]);
      setShowToast(true);
      return;
    }

    navigate("/orders/new-order", {
      state: {
        client: { ...client },
      },
    });
  };

  const onClientSelect = (option) => {
    setValue("client", option.props.value);
  };

  const keyPress = (e) => {
    if (e.keyCode === 13) {
      handleSubmit(onSubmit)();
    }
  };

  useEffect(() => {
    reset();
  }, [reset, open]);

  const classes = {
    root: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      left: drawerOpen ? theme.drawerWidth : 0,

      "& .MuiModal-backdrop": {
        left: drawerOpen ? theme.drawerWidth : 0,
      },
    },
    paper: {
      width: "90%",
      maxWidth: 400,
      textAlign: "center",
      position: "relative",
      top: -50,
      pt: 3,
      pb: 5,
      px: 6,
    },
    actions: {
      display: "flex",
      justifyContent: "center",
      gap: 2,
    },
  };

  return (
    <Modal open={open} onClose={handleClose} sx={classes.root}>
      <Paper onKeyDown={keyPress} sx={classes.paper}>
        <Box
          component="form"
          autoComplete="off"
          noValidate
          onSubmit={handleSubmit(onSubmit)}
        >
          <Typography variant="h6" component="h3" mb={3}>
            <Box component={FontAwesomeIcon} icon="address-book" mr={2} />{" "}
            Agregar pedido
          </Typography>
          <FormControl
            fullWidth
            variant="filled"
            sx={{ mt: 3 }}
            {...register("client", { required: true })}
            error={errors["client"] !== undefined && client === ""}
          >
            <SearchableSelectV2
              service={clientService}
              label="Cliente"
              primaryKey="companyName"
              secondaryKey="personInChargeName"
              value={client}
              initialValue={[]}
              loadingMessage="Obteniendo clientes"
              errorMessage="Error obteniendo los clientes"
              handleChange={onClientSelect}
            />
            {errors["client"] !== undefined && client === "" && (
              <FormHelperText>Este campo es requerido</FormHelperText>
            )}
          </FormControl>
          <Box mt={5} sx={classes.actions}>
            <Button type="submit" variant="contained">
              Aceptar
            </Button>
            <Button variant="contained" color="error" onClick={handleClose}>
              Cancelar
            </Button>
          </Box>
        </Box>
      </Paper>
    </Modal>
  );
};

export default OrderClient;
