import { 
    Snackbar,
    Alert
} from '@mui/material';
import { useTheme } from "@mui/material/styles"


const Toast = ({ open, onClose, toasts, drawerOpen }) => {

    const theme = useTheme()

    const classes = {
        root: (idx) => ({
            marginBottom: idx*7,
            ml: drawerOpen ? `${theme.drawerWidth}px` : 0,
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
        })
    }

    return <>
        {toasts.map((toast, idx) => 
            (
                <Snackbar key={`snackbar-${idx}`} sx={classes.root(idx)} open={open} onClose={(_,reason)=>onClose(reason)} autoHideDuration={3000}>
                    <Alert variant="filled" severity={toast.severity} sx={{ width: '100%' }} >
                        {toast.primary}
                    </Alert>
                </Snackbar>
            )
        )}
    </>
}

export default Toast