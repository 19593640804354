import { useMemo } from "react"
import { Navigate } from "react-router-dom"
import AuthService from "../services/AuthService"

const InRoute = ({ element }) => {

  const authService = useMemo(()=>new AuthService(), [])

  return authService.isAuthenticated() ? element : <Navigate to="/login" />
}

export default InRoute