import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import OrderService from "../services/OrderService";
import PackageService from "../services/PackageService";
import { ToastContext } from "./ToastProvider";

export const packageContext = createContext([]);

const PackageProvider = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const orderService = useMemo(() => new OrderService(), []);
  const packageService = useMemo(() => new PackageService(), []);
  const { setShowToast, setToastMessage } = useContext(ToastContext);
  const [refOrder, setRefOrder] = useState("");
  const [packeds, setPackeds] = useState([]);
  const [total, setTotal] = useState(0);
  const [companyName, setCompanyName] = useState("");
  const [personInChargeName, setPersonInChargeName] = useState("");
  const [products, setProducts] = useState([]);
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const getOrder = useCallback(
    async (id) => {
      try {
        setIsLoading(true);

        const res = await orderService.get(id);

        setTotal(res.data.result.total ?? 0);
        setCompanyName(res.data.result.companyName);
        setPersonInChargeName(res.data.result.creatorUser.fullName);
        setProducts([...res.data.result.orderDetails]);
      } catch {
        setError(true);
      } finally {
        setIsLoading(false);
      }
    },
    [orderService, setProducts]
  );

  useEffect(() => {
    const id = Number(location.search.split("=")[1]);

    if (isNaN(id)) {
      navigate("/orders");
    }

    setRefOrder(id);
    getOrder(id);
  }, [getOrder, setRefOrder, location.search, navigate]);

  const packProduct = (product, oldQuantity) => {
    if (product.quantity > oldQuantity) {
      setToastMessage(() => [
        {
          severity: "error",
          primary: "La cantidad indicada no puede ser mayor a la ordenada",
        },
      ]);
      setShowToast(true);

      return false;
    }

    const totalQuantity = packeds.reduce((accumulator, currentPack) => {
      if (currentPack.productId === product.productId) {
        return accumulator + currentPack.quantity;
      }

      return accumulator;
    }, 0);

    if (totalQuantity + product.quantity > oldQuantity) {
      setToastMessage(() => [
        {
          severity: "error",
          primary: "La cantidad a empacar no puede ser mayor a la ordenada",
        },
      ]);
      setShowToast(true);

      return false;
    }

    const newKey = `${product.productId}-${product.sizeId}-${product.colorId}`;

    if (packeds.findIndex((p) => p.key === newKey) > -1) {
      setToastMessage([
        {
          severity: "error",
          primary: `Ya se agrego esta combinanción del producto`,
        },
      ]);
      setShowToast(true);
      return false;
    }

    const newPack = { ...product, key: newKey };

    setPackeds([...packeds, newPack]);

    setToastMessage(() => [
      {
        severity: "success",
        primary: "Producto agregado al paquete",
      },
    ]);
    setShowToast(true);

    return true;
  };

  const deletePack = (id) => {
    setPackeds((oldPackeds) => [
      ...oldPackeds.filter((pack) => pack.key !== id),
    ]);
  };

  const createPackage = useCallback(async () => {
    try {
      if (packeds.length === 0) {
        setToastMessage(() => [
          {
            severity: "info",
            primary: "No hay productos para empacar",
          },
        ]);
        setShowToast(true);
        return;
      }

      const payload = [
        ...packeds.map((pack) => ({
          orderDetailId: pack.id,
          quantity: pack.quantity,
          sizeId: pack.sizeId,
          colorId: pack.colorId,
        })),
      ];

      await packageService.create(payload);

      setPackeds([]);
      setToastMessage(() => [
        {
          severity: "success",
          primary: "Empacado exitosamente",
        },
      ]);
      setShowToast(true);
      navigate(`/orders/order?id=${refOrder}`);
    } catch (error) {
      if (error.response.data.error.message.includes("stock")) {
        setToastMessage(() => [
          {
            severity: "error",
            primary: "Stock insuficiente para el producto indicado",
          },
        ]);
      } else {
        setToastMessage(() => [
          {
            severity: "error",
            primary:
              "Error al empacar producto/s, por favor intentelo nuevamente",
          },
        ]);
      }

      setShowToast(true);
    }
  }, [
    packageService,
    packeds,
    setToastMessage,
    setShowToast,
    navigate,
    refOrder,
  ]);

  return (
    <packageContext.Provider
      value={{
        refOrder,
        products,
        packeds,
        packProduct,
        deletePack,
        createPackage,
        total,
        companyName,
        personInChargeName,
        error,
        isLoading,
      }}
    >
      <Outlet />
    </packageContext.Provider>
  );
};

export default PackageProvider;
