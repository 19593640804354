import HttpService from "./HttpService";
import { api } from "../common/enviroment";

export default class SublineService {
  httpService = new HttpService();

  getAll = (maxResultCount, skipCount, subline, line) => {
    return this.httpService.get(`/services/app${api.SUBLINE_SERVICE}/GetAll?${maxResultCount ? "MaxResultCount="+maxResultCount+"&" : ""}${skipCount ? "SkipCount="+skipCount+"&" : ""}${subline ? "SublineDescription="+subline+"&" : ""}${line ? "LineDescription="+line+"&" : ""}`);
  };

  delete = (id) => {
    return this.httpService.delete(`/services/app${api.SUBLINE_SERVICE}/Delete?Id=${id}`);
  }

  deleteAll = (datas) => {
    const reqs = datas.map(data=>this.delete(data.id))
    const reqsWithError = reqs.map((req, i) => req.then(res => ({...res, description: datas[i].description})).catch(error => ({...error, description: datas[i].description})) )
    return this.httpService.all(reqsWithError)
  }

  create = (data) => {
    return this.httpService.post(`/services/app${api.SUBLINE_SERVICE}/Create`, data);
  }

  update = (data) => {
    return this.httpService.put(`/services/app${api.SUBLINE_SERVICE}/Update`, data);
  }

}