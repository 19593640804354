import { useState } from "react";
import {
  Box,
  Paper,
  Typography,
  Button,
  Chip,
  Divider,
  Skeleton,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toCapitalize } from "../../common/globalFunctions";
import { useTheme } from "@mui/material/styles";
import PopoverOptions from "./PopoverOptions";

const ClientItem = ({
  id,
  name,
  nit,
  person,
  phone,
  address,
  priceListId,
  handleDelete,
  handleEdit,
}) => {
  const [deleting, setDeleting] = useState(false);
  const [showSkeleton, setShowSkeleton] = useState(true);
  const navigate = useNavigate();
  const theme = useTheme();

  const myHandleDelete = async () => {
    setDeleting(true);
    try {
      await handleDelete();
    } catch {
      setDeleting(false);
    }
  };

  const classes = {
    root: {
      width: "100%",
      // maxWidth: 360,
      borderRadius: "10px",
      padding: 1,
    },
    itemHeader: {
      display: "flex",
      position: "relative",
      bgColor: theme.palette.myGray.main,
      backgroundColor: theme.palette.myGray.main,
      padding: theme.spacing(1, 2),
      borderRadius: "10px",
    },
    itemAvatar: {
      width: 65,
      height: 65,
      borderRadius: 65,
      mr: 2,
    },
    itemOptions: {
      position: "absolute",
      top: 0,
      right: 5,
      ml: 5,
    },
    itemBody: {
      padding: 2,
    },
    itemChip: {
      fontWeight: "bold",
      borderRadius: 2,
      fontSize: "1rem",
      marginBottom: 1,
    },
    progress: {
      ml: 2,
      verticalAlign: "text-bottom",
    },
  };

  return (
    <Paper elevation={2} sx={classes.root}>
      <Box sx={classes.itemHeader}>
        {showSkeleton && (
          <Skeleton
            animation="wave"
            variant="circular"
            width={65}
            height={65}
            sx={[classes.itemAvatar, { position: "absolute" }]}
          />
        )}
        <Box
          sx={classes.itemAvatar}
          onLoad={() => setShowSkeleton(false)}
          component="img"
          src={`https://api.dicebear.com/6.x/thumbs/svg?backgroundColor=${theme.palette.primary.main.substring(
            1
          )}&seed=${id}${nit}-${name}`}
        />
        <Box>
          <Tooltip title={name} placement="top-start">
            <Typography component="h4" variant="h6" fontWeight="bold">
              {name.length > 14
                ? toCapitalize(name).substring(0, 14).concat("...")
                : toCapitalize(name)}
            </Typography>
          </Tooltip>
          <Typography component="p" variant="subtitle1">
            <b>NIT:</b> {nit}
          </Typography>
        </Box>
        <PopoverOptions
          deleting={deleting}
          handleDelete={myHandleDelete}
          handleEdit={handleEdit}
        />
      </Box>
      <Box sx={classes.itemBody}>
        <Chip
          label={
            <>
              <Box component={FontAwesomeIcon} icon="user-tie" mr={1} />
              {person.length > 10
                ? toCapitalize(person).substring(0, 24).concat("...")
                : toCapitalize(person)}
            </>
          }
          variant="outlined"
          sx={classes.itemChip}
        />
        <Typography component="p" variant="subtitle1" my={1}>
          <b>Celular:</b> {phone}
        </Typography>
        <Typography component="p" variant="subtitle1" my={1} mb={2}>
          <b>Dirección:</b> {address}
        </Typography>
        <Divider sx={{ borderWidth: 1 }} />
        {deleting ? (
          <Typography component="p" variant="h6" mt={2} color="error">
            Eliminando{" "}
            <CircularProgress color="error" size={25} sx={classes.progress} />
          </Typography>
        ) : (
          <Button
            sx={{ mt: 1 }}
            onClick={() => navigate(`/price-lists/prices?id=${priceListId}`)}
          >
            <Box
              component={FontAwesomeIcon}
              icon="hand-holding-dollar"
              mr={2}
              sx={{ fontSize: "1.4rem" }}
            />{" "}
            Ver lista de precios
          </Button>
        )}
      </Box>
    </Paper>
  );
};

export default ClientItem;
