import HttpService from "./HttpService";
import { api } from "../common/enviroment";

export default class PackageService {
  httpService = new HttpService();

  get = (packageId) => {
    return this.httpService.get(
      `/services/app${api.PACKAGE_SERVICE}/GetPackageDetails?packageId=${packageId}`
    );
  };

  getAll = (orderId) => {
    return this.httpService.get(
      `/services/app${api.PACKAGE_SERVICE}/GetAllPackagesForOrder?orderId=${orderId}`
    );
  };

  create = (data) => {
    return this.httpService.post(
      `/services/app${api.PACKAGE_SERVICE}/CreatePackageForOrder`,
      data
    );
  };
}
