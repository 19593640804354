import { useEffect, useMemo, useCallback, useContext, useState } from 'react'
import {
  Box,
  FormControl,
  FormHelperText,
  TextField,
  useMediaQuery
} from '@mui/material'
import { TopBarContext } from "../../providers/TopBarProvider"
import { useTheme } from "@mui/material/styles"
import { useForm } from "react-hook-form"
import { validateField } from '../../common/globalFunctions'
import { LoadingContext } from "../../providers/LoadingProvider";
import { ToastContext } from "../../providers/ToastProvider"
import ProductService from '../../services/ProductService'
import LineService from '../../services/LineService'
import SublineService from '../../services/SublineService'
import ColorService from '../../services/ColorService'
import SizeService from '../../services/SizeService'
import { useNavigate, useLocation } from 'react-router-dom'
import SearchableSelect from '../atoms/SearchableSelect'
import MultipleSelect from "../atoms/MultipleSelect"

const NewProduct = ({ edit }) => {

  const { setTitle, setSearchOptions, setBtnPrimary } = useContext(TopBarContext)
  const { setOpenLoading, setTextLoading } = useContext(LoadingContext)
  const { setShowToast, setToastMessage } = useContext(ToastContext)
  const { register, handleSubmit, reset, setValue, watch, formState: { errors } } = useForm({
    defaultValues: {
      id: "",
      lineId: "",
      sublineId: "",
      sizeIds: [],
      colorIds: []
    }
  })
  const theme = useTheme()
  const navigate = useNavigate()
  const location = useLocation()
  const bpMdDown = useMediaQuery(theme.breakpoints.down("md"))
  const productService = useMemo(()=>new ProductService(), [])
  const lineService = useMemo(()=>new LineService(), [])
  const sublineService = useMemo(()=>new SublineService(), [])
  const colorService = useMemo(()=>new ColorService(), [])
  const sizeService = useMemo(()=>new SizeService(), [])
  const [ lineName, setLineName ] = useState("")
  const lineId = watch("lineId")
  const sublineId = watch("sublineId")
  const sizeIds = watch("sizeIds")
  const colorIds = watch("colorIds")

  const onSubmit = useCallback(async(data) => {  
    try {
      setTextLoading(edit ? "Actualizando producto" : "Creando producto")
      setOpenLoading(true)

      if (edit) {
        data.sizes = [...data.sizeIds]
        data.colors = [...data.colorIds]
        delete data.sizeIds
        delete data.colorIds
        await productService.update(data)
      } else {
        data.sizeIds = data.sizeIds.map(size=>size.id)
        data.colorIds = data.colorIds.map(color=>color.id)
        await productService.create(data)
      }
      
      setToastMessage([
        {
          severity: "success",
          primary: `Producto ${edit ? "editado" : "creado"} exitosamente`
        }
      ])

    } catch (error) {

      if (error?.response?.data?.error?.message === "Other product already has that reference") {
        return setToastMessage([
          {
            severity: "error",
            primary: `Otro producto ya tiene esta referencia`
          }
        ])
      }

      setToastMessage([
        {
          severity: "error",
          primary: `Error al ${edit ? "editar" : "crear"} producto, por favor intentelo nuevamente`
        }
      ])
    } finally {
      setOpenLoading(false)
      setShowToast(true)
      navigate("/products")
    }

  },[edit, setTextLoading, setOpenLoading, productService, setToastMessage, setShowToast, navigate])

  const loadFields = useCallback(async()=>{
    if(edit) {
      const id = Number(location.search.split("=")[1])
      let product = location.state

      if (!isNaN(id) && product?.id !== id) {
        
        try {

          const res = await productService.get(id)

          product = {...res.data.result}

        } catch {
          setToastMessage([
            {
              severity: "error",
              primary: `Error al intentar obtener los datos del producto`
            }
          ])
          setShowToast(true)
          navigate("/products")
        }

      }

      setValue("id", product.id)
      setValue("reference", product.reference)
      setValue("description", product.description)
      setValue("lineId", product.lineId)
      setValue("sublineId", product.sublineId)
      setValue("sizeIds", [...product.sizes])
      setValue("colorIds", [...product.colors])
    } else {
      reset()
    }
  },[location, navigate, productService, setToastMessage, setShowToast, edit, reset, setValue])

  const onLineSelect = (option) => {
    setLineName(option.props.children)
    setValue("lineId",option.props.value)
  }

  const onSublineSelect = (option) => {
    setValue("sublineId",option.props.value)
  }

  const onSizesSelect = (selected) => {
    setValue("sizeIds", [...selected])
  }

  const onColorsSelect = (selected) => {
    setValue("colorIds", [...selected])
  }

  useEffect(()=>{
    setTitle(edit ? "Editar Producto" : "Crear Producto")
    setSearchOptions({
      action: null
    })
    setBtnPrimary({
      label: edit ? "Actualizar producto" : "Enviar datos",
      action: ()=>handleSubmit(onSubmit)(),
      icon: edit ? "rotate" : "paper-plane"
    })
  },[edit, setTitle, setSearchOptions, setBtnPrimary, handleSubmit, onSubmit])

  useEffect(()=>{
    loadFields()
  },[loadFields])

  const classes = {
    root: {
      maxWidth: "md",
      ml: bpMdDown ? 0 : 8,
      mt: 8,
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      justifyContent: "space-between"
    },
    inputControl: {
      width: bpMdDown ? "100%" : "47%",
    }
  }

return <Box component="form" autoComplete='off' noValidate sx={classes.root}>
    <FormControl sx={classes.inputControl}>
      <TextField 
        label="Nombre de la referencia" 
        variant="filled" 
        sx={{marginBottom: 4}}
        InputLabelProps={edit ? {shrink: true} : null}
        {...register("reference", { required: true })}
        error={errors["reference"] !== undefined}
        helperText={validateField(errors["reference"])}
      />
    </FormControl>
    <FormControl sx={classes.inputControl}>
      <TextField 
        label="Descripción" 
        variant="filled" 
        sx={{marginBottom: 4}}
        InputLabelProps={edit ? {shrink: true} : null}
        {...register("description", { required: true })}
        error={errors["description"] !== undefined}
        helperText={validateField(errors["description"])}
      />
    </FormControl>
    <FormControl 
      variant="filled" 
      sx={[classes.inputControl, { mb: 4 }]} 
      {...register("lineId", {required: true})}
      error={errors["lineId"] !== undefined && lineId === ""}
    >
      <SearchableSelect
        service={lineService}
        label="Línea"
        value={lineId}
        initialValue={[]}
        loadingMessage="Obteniendo líneas"
        errorMessage="Error obteniendo las líneas"
        disabled={false}
        handleChange={onLineSelect}
      />
      {errors["lineId"] !== undefined && lineId === "" && <FormHelperText>Este campo es requerido</FormHelperText>}
    </FormControl>
    <FormControl 
      variant="filled" 
      sx={[classes.inputControl, { mb: 4 }]} 
      {...register("sublineId", {required: true})}
      error={errors["sublineId"] !== undefined && sublineId === ""} 
    >
      <SearchableSelect 
        service={sublineService}
        label="Sublinea"
        value={sublineId}
        initialValue={[]}
        loadingMessage="Obteniendo sublineas"
        errorMessage="Error obteniendo las sublineas"
        disabled={lineId === ""}
        sub={lineName}
        handleChange={onSublineSelect}
      />
      {errors["sublineId"] !== undefined && sublineId === "" && <FormHelperText>Este campo es requerido</FormHelperText>}
    </FormControl>
    <FormControl 
      variant="filled" 
      sx={[classes.inputControl, { mb: 4 }]} 
      {...register("sizeIds", {required: true})}
      error={errors["sizeIds"] !== undefined && sizeIds.length === 0}
    >
      <MultipleSelect
        service={sizeService}
        label="Tallas"
        defaultValue={sizeIds}
        initialValue={[]}
        loadingMessage="Obteniendo tallas"
        errorMessage="Error obteniendo las tallas"
        disabled={false}
        handleChange={onSizesSelect}
      />
      {errors["sizeIds"] !== undefined && sizeIds.length === 0 && <FormHelperText>Este campo es requerido</FormHelperText>}
    </FormControl>
    <FormControl 
      variant="filled" 
      sx={[classes.inputControl, { mb: 4 }]} 
      {...register("colorIds", {required: true})}
      error={errors["colorIds"] !== undefined && colorIds.length === 0}
    >
      <MultipleSelect
        service={colorService}
        label="Colores"
        defaultValue={colorIds}
        initialValue={[]}
        loadingMessage="Obteniendo colores"
        errorMessage="Error obteniendo los colores"
        disabled={false}
        handleChange={onColorsSelect}
      />
      {errors["colorIds"] !== undefined && colorIds.length === 0 && <FormHelperText>Este campo es requerido</FormHelperText>}
    </FormControl>
  </Box>
}

export default NewProduct