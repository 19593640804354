import { useEffect, useContext, useCallback, useState } from "react";
import {
  Modal,
  Paper,
  Typography,
  FormControl,
  TextField,
  Box,
  Button,
  CircularProgress
} from "@mui/material";
import { DrawerContext } from "../../providers/DrawerProvider"
import { ToastContext } from "../../providers/ToastProvider"
import { validateField } from '../../common/globalFunctions'
import { useForm } from "react-hook-form"
import { useTheme } from "@mui/material/styles"

const RoleCreator = ({ open, handleClose, service, setRoles, editRole }) => {

  const [ isLoading, setIsLoading ] = useState(false)
  const { drawerOpen } = useContext(DrawerContext)
  const { setShowToast, setToastMessage } = useContext(ToastContext)
  const { register, handleSubmit, reset, setValue, formState: { errors } } = useForm({
    defaultValues: {
      name: ""
    }
  })
  const theme = useTheme()

  const onSubmit = async(data) => {

    try {
      
      data = {
        ...editRole,
        ...data,
        displayName: data.name
      }

      setIsLoading(true)

      const res = editRole.edit ? await service.update(data) : await service.create(data)
      if (!res.data.success) {
        return
      }

      setRoles(oldRoles=>{
        
        if (Array.isArray(oldRoles)) {
          if (editRole.edit) {
            return [...oldRoles.map(role=>{
              if (role.id === editRole.id) {
                return {
                  ...role,
                  ...data
                }
              } else {
                return role
              }
            })]
          } else {
            return [...oldRoles, {...res.data.result, nPer: 0}]
          }
        } else {
          return {
            ...oldRoles,
            ...data
          }
        }

      })

    } catch (error) {
      setToastMessage(oldToasts=>[...oldToasts,
        {
          severity: "error",
          primary: `Error ${editRole.edit ? "editando" : "agregando"} el rol ${editRole.name}`
        }
      ])
      setShowToast(true)
    } finally {
      setIsLoading(false)
      handleClose()
    }

  }

  const loadFields = useCallback(()=>{

    if (editRole.edit) {

      setValue("name", editRole.name)
      setValue("displayName", editRole.name)
      setValue("description", editRole.description)

    } else {
      reset()
    }

  },[editRole, setValue, reset])

  const keyPress = (e) => {
    if(e.keyCode === 13){
      handleSubmit(onSubmit)()
    }
  }

  useEffect(()=>{
    loadFields()
  },[loadFields])

  const classes = {
    root: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      left: drawerOpen ? theme.drawerWidth : 0,

      "& .MuiModal-backdrop": {
        left: drawerOpen ? theme.drawerWidth : 0, 
      }

    },
    paper: {
      width: "90%",
      maxWidth: 400,
      textAlign: "center",
      position: "relative",
      top: -50,
      pt: 3,
      pb: 5,
      px: 6,
    },
    actions: {
      display: "flex",
      justifyContent: "center",
      gap: 2
    }
  }

  return <Modal
    open={open}
    onClose={handleClose}
    sx={classes.root}
  >
    <Paper sx={classes.paper}>
      <Box component="form" autoComplete='off' noValidate onSubmit={handleSubmit(onSubmit)}>
        <Typography variant="h6" component="h3" mb={3}>
          Agregar Rol
        </Typography>
        <FormControl fullWidth>
          <TextField
            label="Nombre del rol"
            variant="filled"
            autoFocus
            autoComplete="off"
            disabled={isLoading}
            onKeyDown={keyPress}
            {...register("name", { required: true, pattern: /^[a-zA-Z][a-zA-Z0-9 ]?/ })}
            error={errors["name"] !== undefined}
            helperText={validateField(errors["name"])}
          />
        </FormControl>
        <FormControl fullWidth sx={{ mt: 3 }}>
          <TextField
            label="Descripción"
            variant="filled"
            autoComplete="off"
            disabled={isLoading}
            onKeyDown={keyPress}
            {...register("description", { required: true, pattern: /^[a-zA-Z][a-zA-Z0-9 ]?/ })}
            error={errors["description"] !== undefined}
            helperText={validateField(errors["description"])}
          />
        </FormControl>
        <Box mt={3} sx={classes.actions}>
          <Button
            type="submit"
            variant="contained" 
            disabled={isLoading}
            sx={{width: isLoading ? "100%" : "fit-content"}}
          >
            {isLoading ? 
              <>
                Agregando rol <CircularProgress sx={{ml: 1}} thickness={3} size={20} color='secondary' />
              </>:
              "Aceptar"
            }
          </Button>
          {!isLoading && <Button variant="contained" color="error" onClick={handleClose}>Cancelar</Button>}
        </Box>
      </Box>
    </Paper>
  </Modal>
}

export default RoleCreator