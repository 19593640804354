import { useEffect, useState, useContext, useCallback, useMemo } from "react";
import { useNavigate, useBlocker } from "react-router-dom";
import { orderContext } from "../../providers/OrderProvider";
import {
  Box,
  Stack,
  Typography,
  Button,
  IconButton,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
} from "@mui/material";
import { DataGrid, useGridApiRef } from "@mui/x-data-grid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TopBarContext } from "../../providers/TopBarProvider";
import { LoadingContext } from "../../providers/LoadingProvider";
import { ToastContext } from "../../providers/ToastProvider";
import CustomNoRows from "../atoms/CustomNoRows";
import ConfirmationModal from "../molecules/ConfirmationModal";
import { getCurrency } from "../../common/globalFunctions";
import OrderService from "../../services/OrderService";
import OrderFooter from "../atoms/OrderFooter";

const NewOrder = () => {
  const navigate = useNavigate();
  const orderService = useMemo(() => new OrderService(), []);
  const { setTextLoading, setOpenLoading } = useContext(LoadingContext);
  const { setShowToast, setToastMessage } = useContext(ToastContext);
  const { products, deleteProduct, client } = useContext(orderContext);
  const apiRef = useGridApiRef();
  const { setTitle, setSearchOptions, setBtnPrimary } =
    useContext(TopBarContext);
  const [blockerActive, setBlockerActive] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });
  const columns = [
    {
      field: "reference",
      headerName: "Producto",
      minWidth: 200,
      flex: 1,
      sorting: false,
      renderCell: (item) => (
        <ListItemText
          sx={{
            pl: 1,
          }}
          primary={item.row.reference}
          primaryTypographyProps={{
            fontSize: "1.1rem",
          }}
          secondary={item.row.description}
        />
      ),
    },
    {
      field: "size",
      headerName: "Talla",
      minWidth: 200,
      flex: 1,
      sorting: false,
      renderCell: (item) => (
        <Box pl={1} sx={{ width: "100%" }}>
          {item.row.size}
        </Box>
      ),
    },
    {
      field: "color",
      headerName: "Color",
      minWidth: 200,
      flex: 1,
      sorting: false,
      renderCell: (item) => (
        <Box pl={1} sx={{ width: "100%" }}>
          {item.row.color}
        </Box>
      ),
    },
    {
      field: "quantity",
      headerName: "Cantidad",
      minWidth: 200,
      flex: 1,
      sorting: false,
      renderCell: (item) => (
        <Box pl={1} sx={{ width: "100%" }}>
          {item.row.quantity}
        </Box>
      ),
    },
    {
      field: "total",
      headerName: "Subtotal",
      minWidth: 200,
      flex: 1,
      sorting: false,
      editable: true,
      renderCell: (item) => (
        <Box pl={1} sx={{ width: "100%" }}>
          {getCurrency(item.row.total)}
        </Box>
      ),
    },
    {
      field: "delete",
      headerName: "Acciones",
      sortable: false,
      width: 135,
      renderCell: (row) => {
        return (
          <IconButton
            onClick={() => deleteProduct(row.id)}
            sx={{ float: "right", ml: 2 }}
          >
            <Box
              component={FontAwesomeIcon}
              icon="trash"
              fontSize="1rem"
              color="error.main"
            />
          </IconButton>
        );
      },
    },
  ];
  const blocker = useBlocker(
    ({ nextLocation }) =>
      blockerActive && nextLocation.pathname !== "/orders/new-order/products"
  );

  const onSubmit = useCallback(async () => {
    setBlockerActive(false);
    setTextLoading("Agregando pedido");
    setOpenLoading(true);

    try {
      if (!client) {
        throw new Error("No client");
      }

      const payload = {
        clientId: client.id,
        products: [
          ...products.map((p) => ({
            productId: p.productId,
            sizeId: p.sizeId,
            colorId: p.colorId,
            quantity: p.quantity,
          })),
        ],
      };

      await orderService.create(payload);

      setToastMessage([
        {
          severity: "success",
          primary: `Pedido agregado exitosamente`,
        },
      ]);

      navigate("/orders");
    } catch (error) {
      setToastMessage([
        {
          severity: "error",
          primary: `Error al agregar el pedido, por favor intentelo nuevamente`,
        },
      ]);
    } finally {
      setOpenLoading(false);
      setShowToast(true);
      setBlockerActive(true);
    }
  }, [
    client,
    orderService,
    setTextLoading,
    setOpenLoading,
    products,
    setShowToast,
    setToastMessage,
    navigate,
    setBlockerActive,
  ]);

  const onCloseModal = () => {
    blocker.reset();
    setOpenModal(false);
  };

  useEffect(() => {
    setTitle("Crear Pedido");
    setSearchOptions({
      action: () => null,
      hidden: true,
    });
    setBtnPrimary({
      label: "Enviar datos",
      action: onSubmit,
      icon: "paper-plane",
    });
  }, [setTitle, setSearchOptions, setBtnPrimary, navigate, onSubmit]);

  useEffect(() => {
    if (blocker.state === "blocked") {
      setOpenModal(true);
    }
  }, [blocker.state]);

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      e.preventDefault();
      e.returnValue = "";
      return "";
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => window.removeEventListener("beforeunload", handleBeforeUnload);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const total = products.reduce((acc, cValue) => {
    return acc + cValue.total;
  }, 0);

  const classes = {
    root: {
      width: "100%",
      mt: 2,
    },
    clientSelect: { mt: 3, maxWidth: "sm", mb: 4 },
    stack: {
      padding: "0px 5px",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "flex-end",
      my: 1,
    },
    addProductBtn: {
      borderRadius: 100,
      height: 40,
    },
    datagrid: {
      height: "70vh",
      fontSize: "1.1rem",
      ".MuiDataGrid-cell--editing input": {
        fontSize: "1.1rem",
      },
      ".MuiDataGrid-cell:focus,.MuiDataGrid-cell:focus-within": {
        outline: "none",
      },
    },
  };

  return (
    <Box sx={classes.root} component="form" autoComplete="off" noValidate>
      <Stack direction={"row"} justifyContent={"start"}>
        <ListItem sx={{ width: "fit-content" }}>
          <ListItemAvatar>
            <Avatar sx={{ width: 50, height: 50 }}>
              <Box
                component={FontAwesomeIcon}
                icon="address-book"
                fontSize="1.8rem"
              />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={client?.companyName}
            secondary={client?.personInChargeName}
            primaryTypographyProps={{
              fontSize: "1.4rem",
              fontWeight: "500",
            }}
            secondaryTypographyProps={{
              fontSize: "1rem",
            }}
          />
        </ListItem>
      </Stack>
      <Stack sx={classes.stack}>
        <Typography variant="h5" fontWeight={"bold"}>
          Productos
        </Typography>
        <Button
          variant="contained"
          size="medium"
          color="info"
          sx={classes.addProductBtn}
          onClick={() =>
            navigate("/orders/new-order/products", {
              state: {
                byMe: true,
              },
            })
          }
        >
          <Box component={FontAwesomeIcon} icon={"plus"} mr={1} /> Agregar
          producto
        </Button>
      </Stack>

      <DataGrid
        sx={classes.datagrid}
        apiRef={apiRef}
        columns={columns}
        rows={products}
        getRowId={(row) => row.key}
        disableColumnMenu
        disableRowSelectionOnClick
        isRowSelectable={(item) => item.row.selectable}
        pageSizeOptions={[10]}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        localeText={{
          toolbarColumns: "Columnas",
          toolbarColumnsLabel: "Seleccionar columnas",
          noRowsLabel: "Sin Colores",
          footerRowSelected: (count) =>
            count !== 1
              ? `${count.toLocaleString()} filas seleccionadas`
              : `${count.toLocaleString()} fila seleccionada`,
          MuiTablePagination: {
            labelDisplayedRows: ({ from, to, count }) =>
              `${from} - ${to} de ${count}`,
          },
        }}
        slotProps={{
          pagination: {
            labelRowsPerPage: "Filas por página",
          },
          footer: {
            total,
          },
        }}
        slots={{
          noRowsOverlay: () => (
            <CustomNoRows message={"No hay productos agregados"} />
          ),
          noResultsOverlay: () => (
            <CustomNoRows message={"No hay productos agregados"} />
          ),
          footer: OrderFooter,
        }}
      />
      <ConfirmationModal
        icon="triangle-exclamation"
        open={openModal}
        handleClose={onCloseModal}
        handleAccept={() => blocker.proceed()}
        title="Olvidar pedido"
        message="Esta seguro de salir de la página?"
      />
    </Box>
  );
};

export default NewOrder;
