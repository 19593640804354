import { useState } from "react";
import {
  Box,
  Paper,
  Typography,
  Chip,
  Divider,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getStatuProps, toCapitalize } from "../../common/globalFunctions";
import { useTheme } from "@mui/material/styles";
import OrderPopoverOptions from "./OrderPopoverOptions";

const OrderItem = ({
  reference,
  company,
  person,
  status,
  handleDelete,
  handlePackages,
  handleOpen,
}) => {
  const [deleting, setDeleting] = useState(false);
  const theme = useTheme();

  const myHandleDelete = async () => {
    setDeleting(true);
    try {
      await handleDelete();
    } catch {
      setDeleting(false);
    }
  };

  const classes = {
    root: {
      width: "100%",
      borderRadius: "10px",
      padding: 1,
    },
    itemHeader: {
      display: "flex",
      position: "relative",
      bgColor: theme.palette.myGray.main,
      backgroundColor: theme.palette.myGray.main,
      padding: theme.spacing(1, 2),
      borderRadius: "10px",
    },
    itemAvatar: {
      width: 65,
      height: 65,
      borderRadius: 65,
      mr: 2,
      backgroundColor: theme.palette.tertiary.main,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    itemOptions: {
      position: "absolute",
      top: 0,
      right: 5,
      ml: 5,
    },
    itemBody: {
      padding: 2,
    },
    itemChip: {
      fontWeight: "bold",
      borderRadius: 2,
      fontSize: "1rem",
      marginBottom: 1,
    },
    itemChipStatus: {
      backgroundColor: getStatuProps(status)?.color,
      color: "white",
    },
    progress: {
      ml: 2,
      verticalAlign: "text-bottom",
    },
    icon: {
      fontSize: 20,
      mr: 1,
      verticalAlign: "text-bottom",
    },
  };

  return (
    <Paper elevation={2} sx={classes.root}>
      <Box sx={classes.itemHeader}>
        <Box sx={classes.itemAvatar}>
          <Box
            component={FontAwesomeIcon}
            sx={{ color: "secondary.main" }}
            icon="receipt"
            size="2x"
          />
        </Box>
        <Box>
          <Tooltip title={reference} placement="top-start">
            <Typography component="h4" variant="h6" fontWeight="bold">
              #
              {reference.length > 14
                ? toCapitalize(reference).substring(0, 14).concat("...")
                : toCapitalize(reference)}
            </Typography>
          </Tooltip>
          <Tooltip title={company} placement="bottom-start">
            <Typography component="p" variant="subtitle1">
              {company.length > 30
                ? company.substring(0, 27).concat("...")
                : company}
            </Typography>
          </Tooltip>
        </Box>
        <OrderPopoverOptions
          deleting={deleting}
          handleOpen={handleOpen}
          handleDelete={myHandleDelete}
          handlePackages={handlePackages}
        />
      </Box>
      <Box sx={classes.itemBody}>
        <Tooltip title={person} placement="top-start">
          <Chip
            label={
              <>
                <Box component={FontAwesomeIcon} icon="user-tie" mr={1} />
                {person?.length > 55
                  ? person.substring(0, 60).concat("...")
                  : person}
              </>
            }
            variant="outlined"
            sx={[classes.itemChip, { mr: 1 }]}
          />
        </Tooltip>
        <br />
        <Chip
          label={
            <>
              <Box
                component={FontAwesomeIcon}
                icon={getStatuProps(status)?.icon}
                mr={1}
              />
              {getStatuProps(status)?.name}
            </>
          }
          variant="outlined"
          sx={[classes.itemChip, classes.itemChipStatus]}
        />
        <Divider sx={{ borderWidth: 1 }} />
        {deleting ? (
          <Typography component="p" variant="h6" mt={2} color="error">
            Eliminando{" "}
            <CircularProgress color="error" size={25} sx={classes.progress} />
          </Typography>
        ) : (
          <Typography
            component="p"
            variant="h6"
            fontSize="1rem"
            mt={2}
            color="light.main"
          >
            <Box
              component={FontAwesomeIcon}
              sx={classes.icon}
              icon="circle-info"
            />{" "}
            Barra Informativa
          </Typography>
        )}
      </Box>
    </Paper>
  );
};

export default OrderItem;
