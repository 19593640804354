import { Modal, Paper, Typography, Box, Button } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toCapitalize } from "../../common/globalFunctions";

const ConfirmationModal = ({
  icon,
  open,
  handleClose,
  handleAccept,
  title,
  message,
}) => {
  const classes = {
    root: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      width: "90%",
      maxWidth: 400,
      textAlign: "center",
      position: "relative",
      top: -50,
      pt: 3,
      pb: 5,
      px: 6,
    },
    actions: {
      display: "flex",
      justifyContent: "center",
      gap: 2,
    },
  };

  return (
    <Modal open={open} onClose={handleClose} sx={classes.root}>
      <Paper sx={classes.paper}>
        <Typography variant="h5" component="h3" mb={3}>
          <Box component={FontAwesomeIcon} icon={icon} mr={2} />{" "}
          {toCapitalize(title)}
        </Typography>
        <Typography fontSize={"1.1rem"}>{message}</Typography>
        <Box mt={3} sx={classes.actions}>
          <Button variant="contained" onClick={handleAccept}>
            Aceptar
          </Button>
          <Button variant="contained" color="error" onClick={handleClose}>
            Cancelar
          </Button>
        </Box>
      </Paper>
    </Modal>
  );
};

export default ConfirmationModal;
