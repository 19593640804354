import { useEffect, useState, useCallback, useMemo, useContext } from "react";
import { Box, Pagination, Skeleton } from "@mui/material";
import { TopBarContext } from "../../providers/TopBarProvider";
import { useNavigate } from "react-router-dom";
import ClientItem from "../molecules/ClientItem";
import CustomNoRows from "../atoms/CustomNoRows";
import ClientService from "../../services/ClientService";
import { ToastContext } from "../../providers/ToastProvider";
import { PermissionsContext } from "../../providers/PermissionsProvider";

const Clients = () => {
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [clients, setClients] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const [mySearch, setMySearch] = useState("");
  const { setTitle, setSearchOptions, setBtnPrimary } =
    useContext(TopBarContext);
  const { pers } = useContext(PermissionsContext);
  const { setShowToast, setToastMessage } = useContext(ToastContext);
  const clientService = useMemo(() => new ClientService(), []);
  const navigate = useNavigate();
  const rowsPerPage = 25;

  const getAll = useCallback(
    async (skipCount = 0, search) => {
      try {
        setIsLoading(true);
        setClients([]);
        const res = await clientService.getAll(
          rowsPerPage,
          skipCount * rowsPerPage,
          search
        );
        const totalCount = res.data.result.totalCount;
        const items = res.data.result.items;

        if (items.length === 0) {
          return;
        }

        setCount(Math.ceil(totalCount / rowsPerPage));
        setClients([...items]);
      } catch {
        setError(true);
      } finally {
        setIsLoading(false);
      }
    },
    [clientService, setClients]
  );

  const deleteClient = (id, nit, idx) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await clientService.delete(id);

        if (!res.data.success) {
          return;
        }

        setTimeout(() => {
          setClients((oldClients) => {
            oldClients.splice(idx, 1);
            return [...oldClients];
          });
        }, 1500);

        resolve();
      } catch {
        setToastMessage([
          {
            severity: "error",
            primary: `Error al intentar eliminar el cliente con nit: ${nit}`,
          },
        ]);
        setShowToast(true);
        reject();
      }
    });
  };

  const editClient = (client) =>
    navigate(`/clients/edit-client?id=${client.id}`, { state: { ...client } });

  const onPagination = (_, value) => {
    setClients([]);
    setPage(value);
    getAll(value - 1, mySearch);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    setTitle("Clientes");
    setSearchOptions({
      label: "Buscar Cliente",
      action: (search) => {
        setMySearch(search);
        getAll(0, search);
      },
    });
    setBtnPrimary({
      label: "Agregar Cliente",
      action: () => navigate("/clients/new-client"),
      icon: "plus",
    });
  }, [setTitle, setSearchOptions, getAll, setBtnPrimary, navigate]);

  useEffect(() => {
    getAll();
  }, [getAll]);

  const classes = {
    root: {
      display: "grid",
      gridTemplateColumns: "repeat(auto-fill, 335px)",
      gridGap: 20,
      justifyContent: "center",
      my: 8,
    },
    skeleton: {
      width: "100%",
      height: 311,
    },
    customNoRows: {
      gridColumn: "6 span",
    },
    pagination: {
      width: "fit-content",
      margin: "0 auto",
    },
  };

  return (
    <>
      <Box sx={classes.root}>
        {isLoading ? (
          new Array(rowsPerPage)
            .fill(1)
            .map((_, idx) => (
              <Skeleton
                animation="wave"
                key={`skeleton-${idx}`}
                variant="rounded"
                sx={classes.skeleton}
              />
            ))
        ) : clients.length === 0 ? (
          <Box sx={classes.customNoRows}>
            <CustomNoRows
              message={
                error
                  ? "Error intentando obtener los clientes"
                  : "No hay clientes registrados"
              }
            />
          </Box>
        ) : (
          clients.map((client, idx) => (
            <ClientItem
              key={`client-${client.id}`}
              id={client.id}
              name={client.companyName}
              nit={client.nit}
              person={client.personInChargeName}
              phone={client.personInChargePhone}
              address={client.address}
              priceListId={client.priceListId}
              handleDelete={() => deleteClient(client.id, client.nit, idx)}
              handleEdit={
                pers.includes("pages.clients.update")
                  ? () => editClient(client)
                  : null
              }
            />
          ))
        )}
      </Box>
      {count > 1 && clients.length > 0 && (
        <Pagination
          count={count}
          defaultPage={page}
          sx={classes.pagination}
          color="primary"
          onChange={onPagination}
        />
      )}
    </>
  );
};

export default Clients;
