import { useEffect, useState, useCallback } from 'react'
import {
  Box,
  FormControl,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  InputAdornment,
  Typography,
  CircularProgress
} from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const SearchableSelect = ({ 
  primaryKey="description",
  service,
  label,
  placeholder="Buscar",
  value,
  initialValue,
  loadingMessage,
  errorMessage,
  disabled,
  sub,
  handleChange
}) => {
  const [ myValue, setMyValue ] = useState(value)
  const [ options, setOptions ] = useState([...initialValue])
  const [ isLoading, setIsLoading ] = useState(false)
  const [ dirty, setDirty ] = useState(false)
  const [ error, setError ] = useState(false)
  const [ search, setSearch ] = useState("")

  const getOptions = useCallback(async(search, sub)=>{
    try {
      setIsLoading(true)
      const res = await service.getAll(25, null, search, sub)

      if (!res.data.success) {
        return
      }

      setOptions([...res.data.result.items])

    } catch (error) {
      setError(true)
    } finally {
      setIsLoading(false)
    }
  },[service])

  const myHandleChange = (_, option) => {
    const id = option.props.value

    setMyValue(id)
    handleChange(option, options.find(op => op.id === id))
  }

  const onPressEnter = (e) => {
    if(e.keyCode === 13){
      handleSearch()
    }
  }

  const onKeyPress = (e) => {
    e.stopPropagation()
  }

  const handleSearch = () => {
    getOptions(search, sub)
  }

  useEffect(()=>{
    if(options.length === 0 && !dirty) {
      setDirty(true)
      getOptions()
    }
  },[options, dirty, getOptions])

  useEffect(()=>{
    if (sub && sub !== "") {
      setMyValue("")
      getOptions(search, sub)
    }
  },[sub, search, getOptions])

  useEffect(()=>{
    setMyValue(value)
  },[value])

  const classes = {
    root: {
      textAlign: "left"
    },
    selectInput: {
      "& input": {
        height: 10
      }
    },
    progress: {
      ml: 2, 
      verticalAlign: "text-bottom"
    }
  }

  return <>
    {label && <InputLabel id="label">{label}</InputLabel>}
    <Select
      labelId="label"
      value={myValue}
      defaultValue=""
      sx={classes.root}
      onChange={myHandleChange}
      disabled={disabled}
    >
      {isLoading ?
        <Typography component="p" variant='subtitle1' fontSize="1.1rem" textAlign="center" mt={5} ml={-1}>
          {loadingMessage} <CircularProgress size={25} sx={classes.progress} />
        </Typography> :
        error ? 
        <Typography component="p" variant='subtitle1' color="error" fontSize="1.1rem" textAlign="center" mt={5} ml={-1}>
          {errorMessage}
        </Typography> :
          [
            <FormControl onKeyDown={onKeyPress} key="searchInput" fullWidth sx={{px: 1, mb: 2}}>
              <TextField 
                placeholder={placeholder}
                sx={classes.selectInput}
                onKeyDown={onPressEnter}
                autoComplete='off'
                value={search}
                onChange={(e)=>setSearch(e.target.value)}
                InputProps={{
                  endAdornment: <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      onClick={handleSearch}
                    >
                      <Box component={FontAwesomeIcon} icon="magnifying-glass" fontSize="1rem" />
                    </IconButton>
                  </InputAdornment>
                }}
              />
            </FormControl>,
            <MenuItem key="defaultValue" value=""><em>Ninguno</em></MenuItem>,
            options.map(option=>(
              <MenuItem key={`option-${option.id}`} value={option.id}>{option[primaryKey]}</MenuItem>
            ))
          ]
      }
    </Select>
  </>

}

export default SearchableSelect