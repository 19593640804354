import { useEffect, useContext, useState, useMemo } from "react";
import {
  Modal,
  Paper,
  Typography,
  TextField,
  Box,
  FormControl,
  FormHelperText,
  Button,
  CircularProgress,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DrawerContext } from "../../providers/DrawerProvider";
import { ToastContext } from "../../providers/ToastProvider";
import { validateField } from "../../common/globalFunctions";
import { useForm } from "react-hook-form";
import { useTheme } from "@mui/material/styles";
import SearchableSelect from "../atoms/SearchableSelect";
import ProductService from "../../services/ProductService";
import PriceListService from "../../services/PriceListService";

const PriceCreator = ({ open, priceListId, handleClose, setRows }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { drawerOpen } = useContext(DrawerContext);
  const { setShowToast, setToastMessage } = useContext(ToastContext);
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm();
  const productService = useMemo(() => new ProductService(), []);
  const priceListService = useMemo(() => new PriceListService(), []);
  const productId = watch("productId");
  const theme = useTheme();

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);
      const payload = {
        priceListId,
        products: [
          {
            ...data,
          },
        ],
      };

      const res = await priceListService.createProduct(payload);
      if (!res.data.success) {
        return;
      }

      setRows((oldRows) => [
        {
          ...data,
          id: data.productId,
          productPrice: data.price,
          selectable: true,
        },
        ...oldRows,
      ]);
    } catch (error) {
      setToastMessage((oldToasts) => [
        ...oldToasts,
        {
          severity: "error",
          primary: `Error agregando el precio de ${data.productReference}`,
        },
      ]);
      setShowToast(true);
    } finally {
      setIsLoading(false);
      handleClose();
    }
  };

  const onLineSelect = (option) => {
    setValue("productId", option.props.value);
    setValue("productReference", option.props.children);
  };

  const keyPress = (e) => {
    if (e.keyCode === 13) {
      handleSubmit(onSubmit)();
    }
  };

  useEffect(() => {
    reset();
  }, [reset, open]);

  const classes = {
    root: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      left: drawerOpen ? theme.drawerWidth : 0,

      "& .MuiModal-backdrop": {
        left: drawerOpen ? theme.drawerWidth : 0,
      },
    },
    paper: {
      width: "90%",
      maxWidth: 400,
      textAlign: "center",
      position: "relative",
      top: -50,
      pt: 3,
      pb: 5,
      px: 6,
    },
    actions: {
      display: "flex",
      justifyContent: "center",
      gap: 2,
    },
  };

  return (
    <Modal open={open} onClose={handleClose} sx={classes.root}>
      <Paper sx={classes.paper}>
        <Box
          component="form"
          autoComplete="off"
          noValidate
          onSubmit={handleSubmit(onSubmit)}
        >
          <Typography variant="h6" component="h3" mb={3}>
            <Box component={FontAwesomeIcon} icon="plus" mr={2} /> Agregar
            Precio
          </Typography>
          <FormControl
            fullWidth
            variant="filled"
            {...register("productId", { required: true })}
            error={errors["productId"] !== undefined && productId === ""}
          >
            <SearchableSelect
              primaryKey="reference"
              service={productService}
              label="Productos"
              value={productId}
              initialValue={[]}
              loadingMessage="Obteniendo productos"
              errorMessage="Error obteniendo los productos"
              disabled={false}
              handleChange={onLineSelect}
            />
            {errors["productId"] !== undefined && productId === "" && (
              <FormHelperText>Este campo es requerido</FormHelperText>
            )}
          </FormControl>
          <FormControl sx={{ mt: 3 }} fullWidth>
            <TextField
              label="Precio"
              variant="filled"
              autoFocus
              autoComplete="off"
              disabled={isLoading}
              type="number"
              onKeyDown={keyPress}
              {...register("price", {
                required: true,
                validate: (value) => value > 0,
              })}
              error={errors["price"] !== undefined}
              helperText={validateField(errors["price"])}
            />
          </FormControl>
          <Box mt={5} sx={classes.actions}>
            <Button
              type="submit"
              variant="contained"
              disabled={isLoading}
              sx={{ width: isLoading ? "100%" : "fit-content" }}
            >
              {isLoading ? (
                <>
                  Agregando precio{" "}
                  <CircularProgress
                    sx={{ ml: 1 }}
                    thickness={3}
                    size={20}
                    color="secondary"
                  />
                </>
              ) : (
                "Aceptar"
              )}
            </Button>
            {!isLoading && (
              <Button variant="contained" color="error" onClick={handleClose}>
                Cancelar
              </Button>
            )}
          </Box>
        </Box>
      </Paper>
    </Modal>
  );
};

export default PriceCreator;
