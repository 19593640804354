import { useEffect, useState } from 'react'
import {
  Box,
  List,
  ListItemButton,
  Link,
  ListItemIcon,
  ListItemText,
  Collapse,
  Paper
} from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTheme } from "@mui/material/styles"
import { Link as ReactRouterLink } from "react-router-dom"

const MyCollapse = ({ active, title, icon, options, open, setOpen }) => {

  const theme = useTheme()
  const [ dirty, setDirty ] = useState(false)

  useEffect(()=>{
    const idx = options.findIndex(option=>option.path.substring(1) === active)

    if (idx !== -1 && !dirty) {
      setOpen(true)
      setDirty(true)
    }
  }, [options, dirty, active, setOpen])

  const classes = {
    root: {
      backgroundColor: open ? "white" : "transparent",
      mb: 1.5
    },
    itemButton: {
      pb: 2,
      mb: 1.5,
    },
    itemIcon: {
      minWidth: "fit-content",
      marginRight: 2,
      color: theme.palette.secondary.main,
      fontSize: "1.5rem"
    },
    itemText: {
      margin: 0,
      mb: -1.2,
      color: theme.palette.secondary.main,

      "& > span": {
        fontWeight: 500,
        fontSize: "1.1rem",
      }
    },
    innerButton: {
      mb: 1,
      pl: 4
    },
    active: {
      color: "primary.main"
    }
  }

  return options.length > 0 &&
    <Paper sx={classes.root} elevation={open ? 1 : 0}>
      <ListItemButton sx={classes.itemButton} onClick={()=>setOpen(!open)}>
        <ListItemIcon sx={classes.itemIcon}>
          <Box component={FontAwesomeIcon} icon={icon} />
        </ListItemIcon>
        <ListItemText sx={classes.itemText} primary={title} />
        {open ? <Box component={FontAwesomeIcon} icon="angle-up" /> : <Box component={FontAwesomeIcon} icon="angle-down" />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {options.map(item => (
            <Link key={`option-${item.name}`} underline='none' component={ReactRouterLink} to={item.path}>
              <ListItemButton sx={[classes.itemButton, classes.innerButton]} key={item.name}>
                <ListItemIcon sx={[classes.itemIcon, active === item.path.substring(1) && classes.active]}>
                  <Box component={FontAwesomeIcon} icon={item.icon} />
                </ListItemIcon>
                <ListItemText sx={[classes.itemText, active === item.path.substring(1) && classes.active]} primary={item.name} />
              </ListItemButton>
            </Link>
          ))}
        </List>
      </Collapse>
    </Paper>
  
}

export default MyCollapse