import { useEffect, useContext, useState, useMemo } from "react";
import {
  Modal,
  Paper,
  Typography,
  Box,
  FormControl,
  FormHelperText,
  Button,
  CircularProgress,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DrawerContext } from "../../providers/DrawerProvider";
import { ToastContext } from "../../providers/ToastProvider";
import { useForm } from "react-hook-form";
import { useTheme } from "@mui/material/styles";
import SearchableSelect from "../atoms/SearchableSelect";
import ClientService from "../../services/ClientService";
import PriceListService from "../../services/PriceListService";

const PriceClientCreator = ({ open, priceListId, handleClose, setRows }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { drawerOpen } = useContext(DrawerContext);
  const { setShowToast, setToastMessage } = useContext(ToastContext);
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm();
  const clientService = useMemo(() => new ClientService(), []);
  const priceListService = useMemo(() => new PriceListService(), []);
  const clientId = watch("clientId");
  const theme = useTheme();

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);
      const payload = {
        priceListId,
        clientId: data.clientId,
      };

      const res = await priceListService.createClient(payload);
      if (!res.data.success) {
        return;
      }

      setRows((oldRows) => [{ ...data, id: data.clientId }, ...oldRows]);
    } catch (error) {
      setToastMessage((oldToasts) => [
        ...oldToasts,
        {
          severity: "error",
          primary: `Error agregando el cliente ${data.clientName}`,
        },
      ]);
      setShowToast(true);
    } finally {
      setIsLoading(false);
      handleClose();
    }
  };

  const onLineSelect = (option) => {
    setValue("clientId", option.props.value);
    setValue("clientName", option.props.children);
  };

  const keyPress = (e) => {
    if (e.keyCode === 13) {
      handleSubmit(onSubmit)();
    }
  };

  useEffect(() => {
    reset();
  }, [reset, open]);

  const classes = {
    root: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      left: drawerOpen ? theme.drawerWidth : 0,

      "& .MuiModal-backdrop": {
        left: drawerOpen ? theme.drawerWidth : 0,
      },
    },
    paper: {
      width: "90%",
      maxWidth: 400,
      textAlign: "center",
      position: "relative",
      top: -50,
      pt: 3,
      pb: 5,
      px: 6,
    },
    actions: {
      display: "flex",
      justifyContent: "center",
      gap: 2,
    },
  };

  return (
    <Modal open={open} onClose={handleClose} sx={classes.root}>
      <Paper sx={classes.paper} onKeyDown={keyPress}>
        <Box
          component="form"
          autoComplete="off"
          noValidate
          onSubmit={handleSubmit(onSubmit)}
        >
          <Typography variant="h6" component="h3" mb={3}>
            <Box component={FontAwesomeIcon} icon="plus" mr={2} /> Agregar
            Cliente
          </Typography>
          <FormControl
            fullWidth
            variant="filled"
            {...register("clientId", { required: true })}
            error={errors["clientId"] !== undefined && clientId === ""}
          >
            <SearchableSelect
              primaryKey="companyName"
              service={clientService}
              label="Clientes"
              value={clientId}
              initialValue={[]}
              placeholder="Buscar Cliente"
              loadingMessage="Obteniendo clientes"
              errorMessage="Error obteniendo los clientes"
              disabled={false}
              handleChange={onLineSelect}
            />
            {errors["clientId"] !== undefined && clientId === "" && (
              <FormHelperText>Este campo es requerido</FormHelperText>
            )}
          </FormControl>
          <Box mt={5} sx={classes.actions}>
            <Button
              type="submit"
              variant="contained"
              disabled={isLoading}
              sx={{ width: isLoading ? "100%" : "fit-content" }}
            >
              {isLoading ? (
                <>
                  Agregando cliente
                  <CircularProgress
                    sx={{ ml: 1 }}
                    thickness={3}
                    size={20}
                    color="secondary"
                  />
                </>
              ) : (
                "Aceptar"
              )}
            </Button>
            {!isLoading && (
              <Button variant="contained" color="error" onClick={handleClose}>
                Cancelar
              </Button>
            )}
          </Box>
        </Box>
      </Paper>
    </Modal>
  );
};

export default PriceClientCreator;
