import { useState } from 'react'
import {  
  Box,
  Paper,
  Typography,
  Button,
  Chip,
  Divider,
  Skeleton,
  CircularProgress,
  Tooltip
} from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { toCapitalize } from '../../common/globalFunctions'
import { useTheme } from "@mui/material/styles"
import PopoverOptions from './PopoverOptions'

const RoleItem = ({ id, name, description, nPer, handleDelete, handleEdit, handleAction }) => {

  const [ deleting, setDeleting ] = useState(false)
  const [ showSkeleton, setShowSkeleton ] = useState(true)
  const theme = useTheme()

  const myHandleDelete = async() => {
    setDeleting(true)
    try {
      await handleDelete()
    } catch {
      setDeleting(false)
    }
  }

  const classes = {
    root: {
      width: "100%",
      // maxWidth: 360,
      borderRadius: "10px",
      padding: 1
    },
    itemHeader: {
      display: "flex",
      position: "relative",
      bgColor: theme.palette.myGray.main,
      backgroundColor: theme.palette.myGray.main,
      padding: theme.spacing(1,2),
      borderRadius: "10px"
    },
    itemAvatar: {
      width: 65,
      height: 65,
      borderRadius: 65,
      mr: 2
    },
    itemOptions: {
      position: "absolute",
      top: 0,
      right: 5,
      ml: 5
    },
    itemBody: {
      padding: 2
    },
    itemChip: {
      fontWeight: "bold",
      borderRadius: 2,
      fontSize: "1rem",
      marginBottom: 1
    },
    progress: {
      ml: 2, 
      verticalAlign: "text-bottom"
    }
  }

  return <Paper elevation={2} sx={classes.root} >
    <Box sx={classes.itemHeader}>
      {showSkeleton && <Skeleton animation="wave" variant="circular" width={65} height={65} sx={[classes.itemAvatar, { position: "absolute" }]} />}
      <Box sx={classes.itemAvatar} onLoad={()=>setShowSkeleton(false)} component="img" src={`https://api.dicebear.com/6.x/thumbs/svg?backgroundColor=${theme.palette.primary.main.substring(1)}&seed=${id}-${name}`} />
      <Box>
        <Tooltip title={name} placement="top-start">
          <Typography component="h4" variant='h6' fontWeight="bold">{name.length > 14 ? toCapitalize(name).substring(0,14).concat("...") : toCapitalize(name)}</Typography>
        </Tooltip>
        <Tooltip title={description} placement="bottom-start">
          <Typography component="p" variant='subtitle1'>
            {description && description.length > 30 ? description.substring(0,27).concat("...") : description}
          </Typography>
        </Tooltip>
      </Box>
      <PopoverOptions deleting={deleting} handleDelete={myHandleDelete} handleEdit={handleEdit} />
    </Box>
    <Box sx={classes.itemBody}>
      <Chip 
        label={
          <>
            {nPer} {nPer === 1 ? "permiso asignado" : "permisos asignados"}
          </>
        }
        variant="outlined" 
        sx={[classes.itemChip, {ml: 1, mb: 2}]}
      />
      <Divider sx={{borderWidth: 1}} />
      {deleting ?
        <Typography component="p" variant='h6' mt={2} color="error">
          Eliminando <CircularProgress color='error' size={25} sx={classes.progress} />
        </Typography>:
        <Button sx={{mt: 1}} onClick={handleAction}>
          <Box component={FontAwesomeIcon} icon="shield-halved" mr={2} fontSize="1.5rem" /> Ver permisos
        </Button>
      }     
    </Box>
  </Paper>

}

export default RoleItem