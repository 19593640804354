import HttpService from "./HttpService";
import { api } from "../common/enviroment";

export default class PriceListService {
  httpService = new HttpService();

  getAll = (maxResultCount, skipCount) => {
    return this.httpService.get(`/services/app${api.PRICE_LIST_SERVICE}/GetAll?${maxResultCount ? "MaxResultCount="+maxResultCount+"&" : ""}${skipCount ? "SkipCount="+skipCount : ""}`);
  };

  get = (id) => {
    return this.httpService.get(`/services/app${api.PRICE_LIST_SERVICE}/Get?${id ? "Id="+id : ""}`);
  }

  getProducts = (id, maxResultCount, skipCount, search) => {
    return this.httpService.get(`/services/app${api.PRICE_LIST_SERVICE}/GetProductByListId?${id ? "listId="+id+"&" : ""}${maxResultCount ? "MaxResultCount="+maxResultCount+"&" : ""}${skipCount ? "SkipCount="+skipCount+"&" : ""}${search ? "Search="+search+"&" : ""}`);
  }

  getClients = (id, maxResultCount, skipCount, search) => {
    return this.httpService.get(`/services/app${api.PRICE_LIST_SERVICE}/GetClientsByListId?${id ? "listId="+id+"&" : ""}${maxResultCount ? "MaxResultCount="+maxResultCount+"&" : ""}${skipCount ? "SkipCount="+skipCount+"&" : ""}${search ? "Search="+search+"&" : ""}`);
  }

  delete = (id) => {
    return this.httpService.delete(`/services/app${api.PRICE_LIST_SERVICE}/Delete?Id=${id}`);
  }

  deleteProduct = (data) => {
    return this.httpService.delete(`/services/app${api.PRICE_LIST_SERVICE}/DeleteProductFromList`, { data });
  }

  deleteClient = (data) => {
    return this.httpService.delete(`/services/app${api.PRICE_LIST_SERVICE}/DeleteClientFromList`, { data });
  }

  deleteAll = (datas) => {
    const reqs = datas.map(data=>this.delete(data.id))
    const reqsWithError = reqs.map((req, i) => req.then(res => ({...res, description: datas[i].description})).catch(error => ({...error, description: datas[i].description})) )
    return this.httpService.all(reqsWithError)
  }

  create = (data) => {
    return this.httpService.post(`/services/app${api.PRICE_LIST_SERVICE}/Create`, data);
  }

  createProduct = (data) => {
    return this.httpService.post(`/services/app${api.PRICE_LIST_SERVICE}/AssignProductsToPriceList`, data);
  }

  createClient = (data) => {
    return this.httpService.post(`/services/app${api.PRICE_LIST_SERVICE}/AssignClientToPriceList`, data);
  }

  update = (data) => {
    return this.httpService.put(`/services/app${api.PRICE_LIST_SERVICE}/Update`, data);
  }

  updateProduct = (data) => {
    return this.httpService.put(`/services/app${api.PRICE_LIST_SERVICE}/UpdateProductPriceForList`, data);
  }

}