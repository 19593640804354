import { createTheme } from "@mui/material/styles"

const primaryColor = {
    main: "#ffb957",
    dark: "#b2813c",
    light: "#ffc778",
    contrastText: "#fff"
}

const secondaryColor = {
    main: "#676E7C",
    dark: "#5b616d",
    light: "#767d89",
    contrastText: "#fff"
}

const tertiaryColor = {
    main: "#C4C8CD",
    dark: "#acb0b4",
    light: "#caced2",
    contrastText: "#000"
}

const myGray = {
    main: "#f6f8fa"
}

export default createTheme({
    drawerWidth: 300,
    palette: {
        primary: {
            ...primaryColor
        },
        secondary: {
            ...secondaryColor
        },
        tertiary: {
            ...tertiaryColor
        },
        text: {
            primary: "#464646"
        },
        myGray: {
            ...myGray
        }
    },
    typography: {
        // fontSize: 14
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                "*": {
                    scrollbarWidth: "thin",
                    scrollbarColor: `${secondaryColor.main} #ccc`,
                    scrollBehavior: "smooth"
                },
                "::-webkit-scrollbar": {
                    width: ".4rem",
                    height: ".4rem"
                },
                "::-webkit-scrollbar-thumb": {
                    backgroundColor: secondaryColor.main,
                    borderRadius: "100vh"
                },
                "::-webkit-scrollbar-track": {
                    backgroundColor: "#ccc"
                }
            }
        },
        MuiMenu:{
            styleOverrides: {
                paper: {
                    height: 300
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: "none",
                    fontSize: "1rem"
                }
            }
        }
    }
})