import { useMemo } from "react"
import { Navigate } from "react-router-dom"
import AuthService from "../services/AuthService"

const OutRoute = ({ element }) => {

  const authService = useMemo(()=>new AuthService(), [])

  return !authService.isAuthenticated() ? element : <Navigate to="/dashboard" />
}

export default OutRoute