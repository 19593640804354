import { createContext, useState } from "react";
import TopBar from "../components/molecules/TopBar";

export const TopBarContext = createContext({});

const TopBarProvider = ({ children }) => {
  const [title, setTitle] = useState("");
  const [searchOptions, setSearchOptions] = useState({
    label: "Buscar",
    action: () => null,
    hidden: false,
  });
  const [btnPrimary, setBtnPrimary] = useState({
    label: "Agregar",
    action: null,
    icon: "plus",
  });
  const [btnSecondary, setBtnSecondary] = useState({
    label: "Cancelar",
    action: null,
    icon: "ban",
    hidden: true,
  });

  return (
    <TopBarContext.Provider
      value={{
        setTitle,
        setSearchOptions,
        setBtnPrimary,
        setBtnSecondary,
      }}
    >
      <TopBar
        title={title}
        placeholder={searchOptions.label}
        handleSearch={searchOptions.action}
        searchHidden={searchOptions.hidden}
        primaryBtnLabel={btnPrimary.label}
        primaryHandleClick={btnPrimary.action}
        primaryBtnIcon={btnPrimary.icon}
        secondaryBtnLabel={btnSecondary.label}
        secondaryHandleClick={btnSecondary.action}
        secondaryBtnIcon={btnSecondary.icon}
      />
      {children}
    </TopBarContext.Provider>
  );
};

export default TopBarProvider;
