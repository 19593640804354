import { useEffect, useState, useCallback, useMemo, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Skeleton } from "@mui/material";
import { TopBarContext } from "../../providers/TopBarProvider";
import CustomNoRows from "../atoms/CustomNoRows";
import PackageService from "../../services/PackageService";
import PackageItem from "../molecules/PackageItem";

const Packages = () => {
  const [packages, setPackages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [orderId, setOrderId] = useState(undefined);
  const [error, setError] = useState(false);
  const { setTitle, setSearchOptions, setBtnPrimary } =
    useContext(TopBarContext);
  const packageService = useMemo(() => new PackageService(), []);
  const rowsPerPage = 25;
  const location = useLocation();
  const navigate = useNavigate();

  const getAll = useCallback(
    async (orderId) => {
      try {
        setIsLoading(true);
        setPackages([]);
        const res = await packageService.getAll(orderId);
        const items = res.data.result;

        if (items.length === 0) {
          return;
        }

        setPackages([...items]);
      } catch {
        setError(true);
      } finally {
        setIsLoading(false);
      }
    },
    [packageService, setPackages]
  );

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const localOrderId = searchParams.get("id");

    setOrderId(localOrderId);

    getAll(localOrderId);
  }, [location, getAll, setOrderId]);

  useEffect(() => {
    setTitle(`Paquetes de Orden #${orderId}`);
    setSearchOptions({
      action: () => null,
      hidden: true,
    });
    setBtnPrimary({
      action: null,
    });
  }, [setTitle, setSearchOptions, setBtnPrimary, orderId]);

  const classes = {
    root: {
      display: "grid",
      gridTemplateColumns: "repeat(auto-fill, 335px)",
      gridGap: 20,
      justifyContent: "center",
      my: 8,
    },
    skeleton: {
      width: "100%",
      height: 311,
    },
    customNoRows: {
      gridColumn: "6 span",
    },
    pagination: {
      width: "fit-content",
      margin: "0 auto",
    },
  };

  return (
    <>
      <Box sx={classes.root}>
        {isLoading ? (
          new Array(rowsPerPage)
            .fill(1)
            .map((_, idx) => (
              <Skeleton
                animation="wave"
                key={`skeleton-${idx}`}
                variant="rounded"
                sx={classes.skeleton}
              />
            ))
        ) : packages.length === 0 ? (
          <Box sx={classes.customNoRows}>
            <CustomNoRows
              message={
                error
                  ? "Error intentando obtener los paquetes"
                  : "No hay paquetes registrados"
              }
            />
          </Box>
        ) : (
          packages.map((packed) => {
            const packageId = packed.packageId;

            return (
              <PackageItem
                key={`package-${packageId}`}
                reference={String(packageId)}
                quantity={String(packed.quantity)}
                handleOpen={() =>
                  navigate(`/orders/packages/package?id=${packageId}`)
                }
                handleDelete={null}
              />
            );
          })
        )}
      </Box>
    </>
  );
};

export default Packages;
