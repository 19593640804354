import { useEffect, useState, useContext, useCallback, useMemo } from "react";
import { Box, ListItemText } from "@mui/material";
import { DataGrid, useGridApiRef } from "@mui/x-data-grid";
import { useLocation, useNavigate } from "react-router-dom";
import { TopBarContext } from "../../providers/TopBarProvider";
import CustomNoRows from "../atoms/CustomNoRows";
import { getCurrency } from "../../common/globalFunctions";
import PackageService from "../../services/PackageService";

const Package = () => {
  const apiRef = useGridApiRef();
  const [refPackage, setRefPackage] = useState("");
  const [products, setProducts] = useState([]);
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const packageService = useMemo(() => new PackageService(), []);

  const { setTitle, setSearchOptions, setBtnPrimary } =
    useContext(TopBarContext);

  const location = useLocation();
  const navigate = useNavigate();
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });
  const columns = [
    {
      field: "product",
      headerName: "Producto",
      minWidth: 200,
      flex: 1,
      sorting: false,
      renderCell: (item) => (
        <ListItemText
          sx={{
            pl: 1,
          }}
          primary={item.row.productReference}
          primaryTypographyProps={{
            fontSize: "1.1rem",
          }}
          secondary={item.row.productDescription}
        />
      ),
    },
    {
      field: "sizeDescription",
      headerName: "Talla",
      minWidth: 200,
      flex: 1,
      sorting: false,
      renderCell: (item) => (
        <Box pl={1} sx={{ width: "100%" }}>
          {item.row.sizeDescription}
        </Box>
      ),
    },
    {
      field: "colorDescription",
      headerName: "Color",
      minWidth: 200,
      flex: 1,
      sorting: false,
      renderCell: (item) => (
        <Box pl={1} sx={{ width: "100%" }}>
          {item.row.colorDescription}
        </Box>
      ),
    },
    {
      field: "quantity",
      headerName: "Cantidad",
      minWidth: 200,
      flex: 1,
      sorting: false,
      renderCell: (item) => (
        <Box pl={1} sx={{ width: "100%" }}>
          {item.row.quantity}
        </Box>
      ),
    },
    {
      field: "subtotal",
      headerName: "Subtotal",
      minWidth: 200,
      flex: 1,
      sorting: false,
      editable: true,
      renderCell: () => 0,
    },
  ];

  const getOrder = useCallback(
    async (id) => {
      try {
        setIsLoading(true);

        const res = await packageService.get(id);

        setProducts([{ ...res.data.result }]);
      } catch {
        setError(true);
      } finally {
        setIsLoading(false);
      }
    },
    [packageService]
  );

  useEffect(() => {
    const id = Number(location.search.split("=")[1]);

    if (isNaN(id)) {
      navigate("/orders");
    }

    setRefPackage(id);
    getOrder(id);
  }, [getOrder, setRefPackage, location.search, navigate]);

  useEffect(() => {
    setTitle(`Paquete #${refPackage}`);
    setSearchOptions({
      action: () => null,
      hidden: true,
    });
    setBtnPrimary({
      action: null,
    });
  }, [setTitle, setSearchOptions, refPackage, setBtnPrimary, navigate]);

  const classes = {
    root: {
      width: "100%",
      mt: 2,
    },
    stack: {
      padding: "0px 5px",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "flex-end",
      my: 1,
    },
    datagrid: {
      height: "70vh",
      fontSize: "1.1rem",
      ".MuiDataGrid-cell--editing input": {
        fontSize: "1.1rem",
      },
      ".MuiDataGrid-cell:focus,.MuiDataGrid-cell:focus-within": {
        outline: "none",
      },
    },
  };

  return (
    <Box sx={classes.root}>
      <DataGrid
        sx={classes.datagrid}
        apiRef={apiRef}
        columns={columns}
        rows={products}
        editMode="cell"
        getRowId={(row) => row.packageId}
        disableColumnMenu
        disableRowSelectionOnClick
        isRowSelectable={(item) => item.row.selectable}
        isCellEditable={(item) => item.row.selectable}
        pageSizeOptions={[10]}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        loading={isLoading}
        localeText={{
          toolbarColumns: "Columnas",
          toolbarColumnsLabel: "Seleccionar columnas",
          noRowsLabel: "Sin productos",
          footerRowSelected: (count) =>
            count !== 1
              ? `${count.toLocaleString()} filas seleccionadas`
              : `${count.toLocaleString()} fila seleccionada`,
          MuiTablePagination: {
            labelDisplayedRows: ({ from, to, count }) =>
              `${from} - ${to} de ${count}`,
          },
        }}
        slotProps={{
          pagination: {
            labelRowsPerPage: "Filas por página",
          },
          footer: {
            // total,
          },
        }}
        slots={{
          noRowsOverlay: () => (
            <CustomNoRows
              message={
                error
                  ? "Error intentando obtener los productos"
                  : "No hay productos registrados"
              }
            />
          ),
          noResultsOverlay: () => (
            <CustomNoRows
              message={
                error
                  ? "Error intentando obtener los productos"
                  : "No hay productos registrados"
              }
            />
          ),
          // footer: OrderFooter,
        }}
      />
    </Box>
  );
};

export default Package;
