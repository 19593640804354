import { useEffect, useContext, useCallback, useState, useMemo } from "react";
import {
  Modal,
  Paper,
  Typography,
  TextField,
  Box,
  FormControl,
  FormHelperText,
  Button,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DrawerContext } from "../../providers/DrawerProvider";
import { ToastContext } from "../../providers/ToastProvider";
import { useForm } from "react-hook-form";
import { useTheme } from "@mui/material/styles";
import ProductService from "../../services/ProductService";
import SearchableSelectOff from "../atoms/SearchableSelectOff";
import { packageContext } from "../../providers/PackageProvider";

const Packer = ({ open, handleClose, productSelected }) => {
  const { drawerOpen } = useContext(DrawerContext);
  const { packProduct } = useContext(packageContext);
  const { setShowToast, setToastMessage } = useContext(ToastContext);
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      colorId: "",
      sizeId: "",
    },
  });
  const productService = useMemo(() => new ProductService(), []);
  const productInitial = {
    sizes: [],
    colors: [],
  };
  const [product, setProduct] = useState(productInitial);
  const sizeId = watch("sizeId");
  const colorId = watch("colorId");
  const quantity = watch("quantity");
  const theme = useTheme();

  const onSubmit = async (data) => {
    const { colorId, sizeId, quantity } = data;

    const packageProduct = {
      ...productSelected,
      colorId,
      sizeId,
      quantity,
      colorDescription: product.colors.find((c) => c.id === colorId)
        ?.description,
      sizeDescription: product.sizes.find((s) => s.id === sizeId)?.description,
    };

    const oldQuantity = productSelected.quantity - productSelected.packed;

    const res = packProduct(packageProduct, oldQuantity);

    if (res) {
      handleClose();
    }
  };

  const onSizeSelect = (option) => {
    setValue("sizeId", option.props.value);
  };

  const onColorSelect = (option) => {
    setValue("colorId", option.props.value);
  };

  const getProduct = useCallback(async () => {
    try {
      if (productSelected === undefined) {
        return;
      }

      const res = await productService.get(productSelected.productId);
      setProduct(res.data.result);
    } catch {
      setToastMessage((oldToasts) => [
        ...oldToasts,
        {
          severity: "error",
          primary: "Error consultando producto",
        },
      ]);
      setShowToast(true);
    }
  }, [productService, productSelected, setToastMessage, setShowToast]);

  useEffect(() => {
    getProduct();
  }, [getProduct]);

  useEffect(() => {
    reset();
  }, [reset, open]);

  const classes = {
    root: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      left: drawerOpen ? theme.drawerWidth : 0,

      "& .MuiModal-backdrop": {
        left: drawerOpen ? theme.drawerWidth : 0,
      },
    },
    paper: {
      width: "90%",
      maxWidth: 400,
      textAlign: "center",
      position: "relative",
      top: -50,
      pt: 3,
      pb: 5,
      px: 6,
    },
    actions: {
      display: "flex",
      justifyContent: "center",
      gap: 2,
    },
  };

  return (
    <Modal open={open} onClose={handleClose} sx={classes.root}>
      <Paper sx={classes.paper}>
        <Box
          component="form"
          autoComplete="off"
          noValidate
          onSubmit={handleSubmit(onSubmit)}
        >
          <Typography variant="h6" component="h3" mb={3}>
            <Box component={FontAwesomeIcon} icon="plus" mr={2} /> Empacar
            producto
          </Typography>
          <FormControl
            fullWidth
            variant="filled"
            sx={{ mt: 3 }}
            {...register("sizeId", { required: true })}
            error={errors["sizeId"] !== undefined && sizeId === ""}
          >
            <SearchableSelectOff
              label="Talla"
              initialValue={product.sizes}
              disabled={product.sizes.length === 0}
              handleChange={onSizeSelect}
            />
            {errors["sizeId"] !== undefined && sizeId === "" && (
              <FormHelperText>Este campo es requerido</FormHelperText>
            )}
          </FormControl>
          <FormControl
            fullWidth
            variant="filled"
            sx={{ mt: 3 }}
            {...register("colorId", { required: true })}
            error={errors["colorId"] !== undefined && colorId === ""}
          >
            <SearchableSelectOff
              label="Color"
              initialValue={product.colors}
              disabled={product.colors.length === 0}
              handleChange={onColorSelect}
            />
            {errors["colorId"] !== undefined && colorId === "" && (
              <FormHelperText>Este campo es requerido</FormHelperText>
            )}
          </FormControl>
          <FormControl fullWidth sx={{ mt: 3 }}>
            <TextField
              label="Cantidad"
              variant="filled"
              sx={{ marginBottom: 4 }}
              {...register("quantity", {
                required: true,
                min: 1,
                valueAsNumber: true,
              })}
              error={errors["quantity"] !== undefined}
              helperText={
                quantity === ""
                  ? "Este campo es requerido"
                  : errors["quantity"]
                  ? "Esta cantidad es inválida"
                  : ""
              }
            />
          </FormControl>
          <Box mt={5} sx={classes.actions}>
            <Button
              type="submit"
              variant="contained"
              sx={{ width: "fit-content" }}
            >
              Aceptar
            </Button>
            <Button variant="contained" color="error" onClick={handleClose}>
              Cancelar
            </Button>
          </Box>
        </Box>
      </Paper>
    </Modal>
  );
};

export default Packer;
