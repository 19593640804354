import { useState } from 'react'
import {  
  Box,
  Paper,
  Typography,
  Divider,
  CircularProgress,
  Tooltip
} from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { toCapitalize } from '../../common/globalFunctions'
import { useTheme } from "@mui/material/styles"
import PriceListPopoverOptions from './PriceListPopoverOptions'

const PricesListItem = ({ name, handleDelete, handleEdit, handlePrices, handleClients }) => {

  const [ deleting, setDeleting ] = useState(false)
  const theme = useTheme()

  const myHandleDelete = async() => {
    setDeleting(true)
    try {
      await handleDelete()
    } catch {
      setDeleting(false)
    }
  }

  const classes = {
    root: {
      width: "100%",
      borderRadius: "10px",
      padding: 1
    },
    itemHeader: {
      display: "flex",
      position: "relative",
      bgColor: theme.palette.myGray.main,
      backgroundColor: theme.palette.myGray.main,
      padding: theme.spacing(1,2),
      borderRadius: "10px"
    },
    itemAvatar: {
      width: 65,
      height: 65,
      borderRadius: 65,
      mr: 2,
      backgroundColor: theme.palette.tertiary.main,
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    },
    itemOptions: {
      position: "absolute",
      top: 0,
      right: 5,
      ml: 5
    },
    itemBody: {
      padding: 2
    },
    itemChip: {
      fontWeight: "bold",
      borderRadius: 2,
      fontSize: "1rem",
      marginBottom: 1
    },
    progress: {
      ml: 2, 
      verticalAlign: "text-bottom"
    },
    icon: {
      fontSize: 20,
      mr: 1,
      verticalAlign: "text-bottom"
    }
  }

  return <Paper elevation={2} sx={classes.root} >
    <Box sx={classes.itemHeader}>
      <Box sx={classes.itemAvatar}>
        <Box component={FontAwesomeIcon} sx={{fontSize: "1.8rem", color: "secondary.main"}} icon="file-invoice-dollar" />
      </Box>
      <Box>
        <Typography component="p" variant='subtitle1'>
          Lista de precios
        </Typography>
        <Tooltip title={name} placement="top-start">
          <Typography component="h4" variant='h6' fontWeight="bold">{name.length > 14 ? toCapitalize(name).substring(0,14).concat("...") : toCapitalize(name)}</Typography>
        </Tooltip>
      </Box>
      <PriceListPopoverOptions disabled={deleting} handleDelete={myHandleDelete} handleEdit={handleEdit} handlePrices={handlePrices} handleClients={handleClients} />
    </Box>
    <Box sx={classes.itemBody}>
      <Divider sx={{borderWidth: 1}} />
      {deleting ?
        <Typography component="p" variant='h6' mt={2} color="error">
          Eliminando <CircularProgress color='error' size={25} sx={classes.progress} />
        </Typography>:
        <Typography component="p" variant='h6' fontSize="1rem" mt={2} color="light.main">
          <Box component={FontAwesomeIcon} sx={classes.icon} icon="circle-info" />  Barra Informativa
        </Typography>
      }     
    </Box>
  </Paper>

}

export default PricesListItem