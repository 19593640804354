import {
  Box
} from '@mui/material'
import MyDrawer from '../components/organisms/MyDrawer'
import Main from '../components/atoms/Main'

const DrawerLayout = ({ open, children }) => {
  return <Box sx={{ display: 'flex', overflow: "hidden" }}>
    <MyDrawer open={open} />
    <Main open={open}>
      {children}
    </Main>
  </Box>
}

export default DrawerLayout