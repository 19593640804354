import { createContext, useState } from 'react';
import { Backdrop } from '@mui/material';
import Loading from '../components/atoms/Loading';

export const LoadingContext = createContext({})

const LoadingProvider = ({ children }) => {
    
    const [openLoading, setOpenLoading] = useState(false)
    const [textLoading, setTextLoading] = useState("")

    return <LoadingContext.Provider value={{
        openLoading,
        setOpenLoading,
        setTextLoading
    }}>
        <Backdrop
            sx={{ color: '#fff', zIndex: "tooltip" }}
            open={openLoading}
        >
            <Loading message={textLoading} />
        </Backdrop>
        {children}
    </LoadingContext.Provider>

}

export default LoadingProvider