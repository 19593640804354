import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  Navigate,
} from "react-router-dom";
import OutRoute from "./OutRoute";
import InRoute from "./InRoute";
import LoginPage from "../pages/LoginPage";
import MainLayout from "../templates/MainLayout";
import MainPage from "../pages/MainPage";

const myRoutes = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/">
      <Route path="/login" element={<OutRoute element={<LoginPage />} />} />
      <Route path="*" element={<InRoute element={<MainLayout />} />}>
        <Route path="*" element={<MainPage />} />
      </Route>
      <Route path="" element={<Navigate to="/login" />} />
    </Route>
  )
);

export { myRoutes };
