import { Box, Paper, Alert, AlertTitle, Typography } from '@mui/material';

const MyAlert = ({ title, message, postdata }) => {

  const classes = {
    root: {
      display: "flex",
      width: "100%",
      height: "100vh",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    paper: {
      textAlign: "center",
      padding: 5,
    }
  }

  return <Box sx={classes.root} >
    <Paper sx={classes.paper}>
      <Alert severity="error">
        <AlertTitle fontSize="1.1rem">{title}</AlertTitle>
        <Typography fontSize="1rem" marginTop={2}>{message}</Typography>
        <br />
        {postdata}
      </Alert>
    </Paper>
  </Box>
}

export default MyAlert