import { useEffect, useState, useContext } from "react";
import {
  Avatar,
  Box,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import OrderFooter from "../atoms/OrderFooter";
import { DataGrid, useGridApiRef } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { TopBarContext } from "../../providers/TopBarProvider";
import CustomNoRows from "../atoms/CustomNoRows";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getCurrency } from "../../common/globalFunctions";
import Packer from "../molecules/Packer";
import { packageContext } from "../../providers/PackageProvider";

const Order = () => {
  const apiRef = useGridApiRef();
  const [openModal, setOpenModal] = useState(false);
  const [productSelected, setProductSelected] = useState(undefined);

  const { setTitle, setSearchOptions, setBtnPrimary } =
    useContext(TopBarContext);
  const {
    refOrder,
    products,
    total,
    companyName,
    personInChargeName,
    error,
    isLoading,
  } = useContext(packageContext);

  const navigate = useNavigate();
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });
  const columns = [
    {
      field: "product",
      headerName: "Producto",
      minWidth: 200,
      flex: 1,
      sorting: false,
      renderCell: (item) => (
        <ListItemText
          sx={{
            pl: 1,
          }}
          primary={item.row.productRef}
          primaryTypographyProps={{
            fontSize: "1.1rem",
          }}
          secondary={item.row.productDesc}
        />
      ),
    },
    {
      field: "sizeDescription",
      headerName: "Talla",
      minWidth: 200,
      flex: 1,
      sorting: false,
      renderCell: (item) => (
        <Box pl={1} sx={{ width: "100%" }}>
          {item.row.sizeDescription ?? "Sin seleccionar"}
        </Box>
      ),
    },
    {
      field: "colorDescription",
      headerName: "Color",
      minWidth: 200,
      flex: 1,
      sorting: false,
      renderCell: (item) => (
        <Box pl={1} sx={{ width: "100%" }}>
          {item.row.colorDescription ?? "Sin seleccionar"}
        </Box>
      ),
    },
    {
      field: "quantity",
      headerName: "Cantidad",
      minWidth: 200,
      flex: 1,
      sorting: false,
      renderCell: (item) => (
        <Box pl={1} sx={{ width: "100%" }}>
          {item.row.quantity}
        </Box>
      ),
    },
    {
      field: "subtotal",
      headerName: "Subtotal",
      minWidth: 200,
      flex: 1,
      sorting: false,
      editable: true,
      renderCell: (item) => (
        <Box pl={1} sx={{ width: "100%" }}>
          {getCurrency(item.row.subtotal)}
        </Box>
      ),
    },
    {
      field: "packed",
      headerName: "Empacados",
      minWidth: 200,
      flex: 1,
      sorting: false,
      editable: true,
      renderCell: (item) => (
        <Box pl={1} sx={{ width: "100%" }}>
          {item.row.packed}
        </Box>
      ),
    },
    {
      field: "dispatched",
      headerName: "Despachados",
      minWidth: 200,
      flex: 1,
      sorting: false,
      editable: true,
      renderCell: (item) => (
        <Box pl={1} sx={{ width: "100%" }}>
          {item.row.dispatched}
        </Box>
      ),
    },
    {
      field: "completed",
      headerName: "Completados",
      minWidth: 200,
      flex: 1,
      sorting: false,
      editable: true,
      renderCell: (item) => (
        <Box pl={1} sx={{ width: "100%" }}>
          {item.row.completed}
        </Box>
      ),
    },
    {
      field: "actions",
      headerName: "Acciones",
      sortable: false,
      width: 135,
      renderCell: (item) => {
        return (
          <Stack direction={"row"} justifyContent={"center"} gap={1}>
            <IconButton
              onClick={() => {
                setProductSelected(item.row);
                setOpenModal(true);
              }}
              color={"primary"}
            >
              <Box
                component={FontAwesomeIcon}
                icon="box-open"
                fontSize="1.1rem"
              />
            </IconButton>
            <IconButton onClick={() => null} color="primary">
              <Box
                component={FontAwesomeIcon}
                icon="rotate"
                fontSize="1.1rem"
              />
            </IconButton>
          </Stack>
        );
      },
    },
  ];

  useEffect(() => {
    setTitle("Pedido");
    setSearchOptions({
      action: () => null,
      hidden: true,
    });
    setBtnPrimary({
      label: "Ver paquete",
      action: () => navigate(`/orders/order/new-package?id=${refOrder}`),
      icon: "box-open",
    });
  }, [setTitle, setSearchOptions, setBtnPrimary, navigate, refOrder]);

  const classes = {
    root: {
      width: "100%",
      mt: 2,
    },
    stack: {
      padding: "0px 5px",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "flex-end",
      my: 1,
    },
    datagrid: {
      height: "70vh",
      fontSize: "1.1rem",
      ".MuiDataGrid-cell--editing input": {
        fontSize: "1.1rem",
      },
      ".MuiDataGrid-cell:focus,.MuiDataGrid-cell:focus-within": {
        outline: "none",
      },
    },
  };

  return (
    <Box sx={classes.root}>
      <Stack direction={"row"} justifyContent={"start"}>
        <ListItem sx={{ width: "fit-content" }}>
          <ListItemAvatar>
            <Avatar sx={{ width: 50, height: 50 }}>
              <Box
                component={FontAwesomeIcon}
                icon="address-book"
                fontSize="1.8rem"
              />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={companyName}
            secondary={personInChargeName}
            primaryTypographyProps={{
              fontSize: "1.4rem",
              fontWeight: "500",
            }}
            secondaryTypographyProps={{
              fontSize: "1rem",
            }}
          />
        </ListItem>
      </Stack>
      <Stack sx={classes.stack}>
        <Typography variant="h5" fontWeight={"bold"}>
          #{refOrder}
        </Typography>
        {/* <IconButton onClick={() => null}>
          <Box component={FontAwesomeIcon} icon="filter" fontSize="1.2rem" />
        </IconButton> */}
      </Stack>
      <DataGrid
        sx={classes.datagrid}
        apiRef={apiRef}
        columns={columns}
        rows={products}
        editMode="cell"
        getRowId={(row) => row.id}
        disableColumnMenu
        disableRowSelectionOnClick
        isRowSelectable={(item) => item.row.selectable}
        isCellEditable={(item) => item.row.selectable}
        pageSizeOptions={[10]}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        loading={isLoading}
        localeText={{
          toolbarColumns: "Columnas",
          toolbarColumnsLabel: "Seleccionar columnas",
          noRowsLabel: "Sin productos",
          footerRowSelected: (count) =>
            count !== 1
              ? `${count.toLocaleString()} filas seleccionadas`
              : `${count.toLocaleString()} fila seleccionada`,
          MuiTablePagination: {
            labelDisplayedRows: ({ from, to, count }) =>
              `${from} - ${to} de ${count}`,
          },
        }}
        slotProps={{
          pagination: {
            labelRowsPerPage: "Filas por página",
          },
          footer: {
            total,
          },
        }}
        slots={{
          noRowsOverlay: () => (
            <CustomNoRows
              message={
                error
                  ? "Error intentando obtener los productos"
                  : "No hay productos registrados"
              }
            />
          ),
          noResultsOverlay: () => (
            <CustomNoRows
              message={
                error
                  ? "Error intentando obtener los productos"
                  : "No hay productos registrados"
              }
            />
          ),
          footer: OrderFooter,
        }}
      />
      <Packer
        open={openModal}
        handleClose={() => {
          setProductSelected(undefined);
          setOpenModal(false);
        }}
        productSelected={productSelected}
      />
    </Box>
  );
};

export default Order;
