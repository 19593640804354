import { useEffect, useCallback, useMemo, useState, useContext } from "react";
import { Box, IconButton, Button, CircularProgress } from "@mui/material";
import { DataGrid, useGridApiRef } from "@mui/x-data-grid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate, useLocation } from "react-router-dom";
import { TopBarContext } from "../../providers/TopBarProvider";
import { ToastContext } from "../../providers/ToastProvider";
import PriceListService from "../../services/PriceListService";
import PriceClientCreator from "../molecules/PriceClientCreator";
import CustomNoRows from "../atoms/CustomNoRows";
import { toCapitalize } from "../../common/globalFunctions";

const PriceClients = () => {
  const apiRef = useGridApiRef();
  const [priceListId, setPriceListId] = useState(-1);
  const [openModal, setOpenModal] = useState(false);
  const [focuseds, setFocuseds] = useState({});
  const [clients, setClients] = useState([]);
  const [error, setError] = useState(false);
  const [count, setCount] = useState(0);
  const [ mySearch, setMySearch ] = useState("")
  const [itemsLoading, setItemsLoading] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { setTitle, setSearchOptions, setBtnPrimary } =
    useContext(TopBarContext);
  const { setShowToast, setToastMessage } = useContext(ToastContext);
  const priceListService = useMemo(() => new PriceListService(), []);
  const navigate = useNavigate();
  const location = useLocation();
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 25,
    page: 0,
  });
  const columns = [
    {
      field: "clientName",
      headerName: "Clientes",
      minWidth: 200,
      flex: 1,
      sorting: false,
      renderCell: (item) => {
        return item.row.productPrice;
      },
    },
    {
      field: "edit",
      headerName: "Acciones",
      sortable: false,
      width: 135,
      renderHeader: () => (
        <Button
          variant="text"
          size="small"
          color="success"
          onClick={() => setOpenModal(true)}
        >
          <Box component={FontAwesomeIcon} icon="plus" mr={1} /> Agregar
        </Button>
      ),
      renderCell: (row) => {
        const item = itemsLoading.find((i) => i.id === row.id);

        if (item?.id) {
          return (
            <Box mr={1}>
              Eliminando{" "}
              <CircularProgress
                color="error"
                size={25}
                sx={{ verticalAlign: "text-bottom" }}
              />
            </Box>
          );
        }

        return (
          <>
            <IconButton
              onClick={() => deletePrice(row.id, row.row.productPrice)}
              sx={{ float: "right", ml: 5 }}
            >
              <Box
                component={FontAwesomeIcon}
                icon="trash"
                fontSize="1rem"
                color="error.main"
              />
            </IconButton>
          </>
        );
      },
    },
  ];

  const getAll = useCallback(
    async (id = -1, skipCount = 0, search) => {
      try {
        setIsLoading(true);

        const skip = skipCount * 25;
        if (clients.length !== 0 && clients.length > skip) {
          return;
        }

        const res = await priceListService.getClients(id, 25, skip, search);
        const totalCount = res.data.result.totalCount;
        const items = res.data.result.items;

        if (items.length === 0) {
          return;
        }

        setCount(totalCount);
        setClients([
          ...clients,
          ...items.map((item) => ({
            ...item,
            id: Number(item.clientId),
          })),
        ]);
      } catch {
        setError(true);
      } finally {
        setIsLoading(false);
      }
    },
    [priceListService, setClients, clients]
  );

  const deletePrice = async (clientId, oldValue) => {
    try {
      setItemsLoading((oldItems) => [
        ...oldItems,
        {
          id: clientId,
        },
      ]);

      const res = await priceListService.deleteClient({
        clientId,
        priceListId: priceListId,
      });

      if (!res.data.success) {
        return;
      }

      setClients((oldPrices) => [
        ...oldPrices.filter((price) => price.clientId !== clientId),
      ]);
      setCount(count-1)

    } catch (error) {
      setToastMessage((oldToasts) => [
        ...oldToasts,
        {
          severity: "error",
          primary: `Error al intentar eliminar el cliente`,
        },
      ]);
      setShowToast(true);
    } finally {
      setItemsLoading((oldItems) => [
        ...oldItems.filter((item) => item.id !== clientId),
      ]);
    }
  };

  const getPriceList = useCallback(async () => {
    const id = Number(location.search.split("=")[1]);
    setPriceListId(id);

    setIsLoading(true);

    try {
      const res = await priceListService.get(id);

      if (!res.data.success) {
        return;
      }

      setTitle(toCapitalize(res.data.result.name));
      getAll(id);
    } catch (error) {
      setToastMessage([
        {
          severity: "error",
          primary: "Error al intentar obtener los clientes de la lista",
        },
      ]);
      setShowToast(true);
      navigate("/price-lists");
    }
  }, [
    location,
    getAll,
    priceListService,
    setTitle,
    setToastMessage,
    setShowToast,
    setPriceListId,
    navigate,
  ]);

  useEffect(() => {
    setTitle("Cargando...");
    setSearchOptions({
      label: "Buscar cliente",
      action: (search)=>{
        setMySearch(search)
        getAll(priceListId, 0, search)
      },
    });
    setBtnPrimary({
      label: "Ver precios",
      action: () => navigate(`/price-lists/prices?id=${priceListId}`),
      icon: "dollar-sign",
    });
  }, [setTitle, setSearchOptions, setBtnPrimary, priceListId, navigate]);

  useEffect(() => {
    getPriceList();
  }, [getPriceList]);

  useEffect(() => {
    if (clients.length > 0) {
      getAll(priceListId, paginationModel.page, mySearch);
    }
    setFocuseds({});
  }, [getAll, clients, paginationModel, priceListId]);

  useEffect(()=>{
    const nClients = clients.length
    if (count < nClients) {
      setCount(nClients)
    }
  },[clients])

  const classes = {
    root: {
      height: "70vh",
      width: "100%",
      mt: 5,
    },
    datagrid: {
      marginTop: 5,
      fontSize: "1.1rem",
    },
  };

  return (
    <Box sx={classes.root}>
      <DataGrid
        sx={classes.datagrid}
        apiRef={apiRef}
        columns={columns}
        rows={clients}
        rowCount={count}
        getRowId={(row) => row.id}
        rowHeight={60}
        cellModesModel={focuseds}
        disableColumnMenu
        disableRowSelectionOnClick
        pageSizeOptions={[25]}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        loading={isLoading}
        localeText={{
          toolbarColumns: "Columnas",
          toolbarColumnsLabel: "Seleccionar columnas",
          noRowsLabel: "Sin Clientes",
          footerRowSelected: (count) =>
            count !== 1
              ? `${count.toLocaleString()} filas seleccionadas`
              : `${count.toLocaleString()} fila seleccionada`,
          MuiTablePagination: {
            labelDisplayedRows: ({ from, to, count }) =>
              `${from} - ${to} de ${count}`,
          },
        }}
        slotProps={{
          pagination: {
            labelRowsPerPage: "Filas por página",
          },
        }}
        slots={{
          noRowsOverlay: () => (
            <CustomNoRows
              message={
                error
                  ? "Error intentando obtener los clientes"
                  : "No hay clientes registrados"
              }
            />
          ),
          noResultsOverlay: () => (
            <CustomNoRows
              message={
                error
                  ? "Error intentando obtener los clientes"
                  : "No hay clientes registrados"
              }
            />
          ),
        }}
      />
      <PriceClientCreator
        open={openModal}
        priceListId={priceListId}
        handleClose={() => setOpenModal(false)}
        setRows={setClients}
      />
    </Box>
  );
};

export default PriceClients;
