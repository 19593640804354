import { useEffect } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  IconButton,
  InputAdornment,
  Stack,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useForm } from "react-hook-form";

const TopBar = ({
  title,
  placeholder,
  handleSearch,
  searchHidden = false,
  primaryBtnLabel,
  primaryHandleClick,
  primaryBtnIcon,
  secondaryBtnLabel,
  secondaryHandleClick,
  secondaryBtnIcon,
}) => {
  const { register, handleSubmit, watch, reset } = useForm({
    defaultValues: {
      searchValue: "",
    },
  });
  const searchValue = watch("searchValue");

  const onSearch = (data) => {
    handleSearch(data.searchValue);
  };

  const onPressEnter = (e) => {
    if (e.keyCode === 13) {
      handleSubmit(onSearch)();
    }
  };

  useEffect(() => {
    reset();
  }, [placeholder, reset]);

  const classes = {
    root: {
      ml: 8,
      // pr: 15,
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      justifyContent: "space-between",
      maxWidth: handleSearch ? "none" : "md",
    },
    title: {
      fontWeight: "bold",
      fontSize: "1.8rem",
      mb: 1,
    },
    searchInput: {
      width: "100%",
      maxWidth: 400,
      mb: 1,

      "& input": {
        padding: "12px 14px !important",
      },

      "&[hidden]": {
        display: "none",
      },
    },
    creatorBtn: {
      borderRadius: 100,
      height: 40,
    },
  };

  return (
    <Box sx={classes.root}>
      <Typography component="h3" variant="h5" sx={classes.title}>
        {title}
      </Typography>
      {handleSearch && (
        <TextField
          placeholder={placeholder}
          hidden={searchHidden}
          variant="filled"
          sx={classes.searchInput}
          onKeyDown={onPressEnter}
          autoComplete="off"
          {...register("searchValue")}
          value={searchValue}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton edge="end" onClick={handleSubmit(onSearch)}>
                  <Box
                    component={FontAwesomeIcon}
                    icon="magnifying-glass"
                    fontSize="1.3rem"
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      )}
      <Stack gap={2}>
        {primaryHandleClick && (
          <Button
            variant="contained"
            size="medium"
            sx={classes.creatorBtn}
            onClick={primaryHandleClick}
          >
            <Box component={FontAwesomeIcon} icon={primaryBtnIcon} mr={1} />{" "}
            {primaryBtnLabel}
          </Button>
        )}
        {secondaryHandleClick && (
          <Button
            variant="contained"
            size="medium"
            color="error"
            sx={classes.creatorBtn}
            onClick={secondaryHandleClick}
          >
            <Box component={FontAwesomeIcon} icon={secondaryBtnIcon} mr={1} />{" "}
            {secondaryBtnLabel}
          </Button>
        )}
      </Stack>
    </Box>
  );
};

export default TopBar;
