import {
  createContext,
  useEffect,
  useCallback,
  useState,
  useMemo,
  useContext,
} from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import PriceListService from "../services/PriceListService";
import { ToastContext } from "../providers/ToastProvider";

export const orderContext = createContext([]);

const OrderProvider = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const priceListService = useMemo(() => new PriceListService(), []);
  const { setShowToast, setToastMessage } = useContext(ToastContext);
  const [client, setClient] = useState();
  const [products, setProducts] = useState([]);
  const [prices, setPrices] = useState([]);

  const addProduct = (product) => {
    const price = prices.find((p) => p.productId === product.id);

    if (!price) {
      setToastMessage([
        {
          severity: "error",
          primary: `No se encontró el precio para este producto`,
        },
      ]);
      setShowToast(true);
      return;
    }

    const newKey = `${product.id}-${product.size ?? "none"}-${
      product.color ?? "none"
    }`;

    if (products.findIndex((p) => p.key === newKey) > -1) {
      setToastMessage([
        {
          severity: "error",
          primary: `Ya se agrego esta combinanción del producto`,
        },
      ]);
      setShowToast(true);
      return;
    }

    const newProduct = {
      ...product,
      color: product.color ?? "Sin seleccionar",
      size: product.size ?? "Sin seleccionar",
      total: price.productPrice * product.quantity,
      key: newKey,
    };

    setProducts((oldProducts) => [...oldProducts, newProduct]);

    setToastMessage([
      {
        severity: "success",
        primary: `Producto/s agregado exitosamente`,
      },
    ]);
    setShowToast(true);
  };

  const deleteProduct = (id) => {
    setProducts((oldProducts) => [
      ...oldProducts.filter((product) => product.key !== id),
    ]);
  };

  const getPriceList = useCallback(
    async (priceListId) => {
      try {
        const res = await priceListService.getProducts(priceListId);

        const localPrices = res.data?.result?.items;

        if (!localPrices) {
          throw new Error();
        }

        setPrices(localPrices);
      } catch {
        setToastMessage((oldToasts) => [
          ...oldToasts,
          {
            severity: "error",
            primary: `Error obteniendo los precios`,
          },
        ]);
        setShowToast(true);
        navigate("/orders");
      }
    },
    [priceListService, setToastMessage, setShowToast, navigate]
  );

  useEffect(() => {
    const stateClient = location.state.client;

    if (!stateClient) {
      return setClient(undefined);
    }

    setClient(stateClient);
    getPriceList(stateClient.priceListId);
  }, [location.state.client, getPriceList]);

  return (
    <orderContext.Provider
      value={{
        products,
        addProduct,
        deleteProduct,
        client,
      }}
    >
      <Outlet />
    </orderContext.Provider>
  );
};

export default OrderProvider;
