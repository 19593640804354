import { useEffect, useMemo, useCallback, useContext, useState } from "react";
import { useBlocker, useNavigate } from "react-router-dom";
import { orderContext } from "../../providers/OrderProvider";
import {
  Box,
  FormControl,
  FormHelperText,
  TextField,
  useMediaQuery,
} from "@mui/material";
import { TopBarContext } from "../../providers/TopBarProvider";
import { useTheme } from "@mui/material/styles";
import { useForm } from "react-hook-form";
import ProductService from "../../services/ProductService";
import SearchableSelect from "../atoms/SearchableSelect";
import SearchableSelectOff from "../atoms/SearchableSelectOff";
import ConfirmationModal from "../molecules/ConfirmationModal";

const NewOrderProduct = ({ setShowOrder }) => {
  const navigate = useNavigate();
  const { addProduct } = useContext(orderContext);
  const { setTitle, setSearchOptions, setBtnPrimary, setBtnSecondary } =
    useContext(TopBarContext);
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      productId: "",
      colorId: "",
      sizeId: "",
      quantity: 0,
    },
  });
  const theme = useTheme();
  const bpMdDown = useMediaQuery(theme.breakpoints.down("md"));
  const productService = useMemo(() => new ProductService(), []);
  const productInitial = {
    sizes: [],
    colors: [],
  };
  const [product, setProduct] = useState(productInitial);
  const [openModal, setOpenModal] = useState(false);
  const productId = watch("productId");
  const sizeId = watch("sizeId");
  const colorId = watch("colorId");
  const quantity = watch("quantity");

  const onSubmit = useCallback(
    (data) => {
      const newProduct = {
        ...data,
        id: product.id,
        reference: product.reference,
        description: product.description,
        size: product.sizes.find((size) => size.id === sizeId)?.description,
        color: product.colors.find((color) => color.id === colorId)
          ?.description,
      };

      addProduct(newProduct);

      navigate(-1);
    },
    [product, sizeId, colorId, addProduct, navigate]
  );

  const onProductSelect = (option, productData) => {
    setProduct(productData ?? productInitial);
    setValue("productId", option.props.value);
    setValue("sizeId", "");
    setValue("colorId", "");
  };

  const onSizeSelect = (option) => {
    setValue("sizeId", option.props.value);
  };

  const onColorSelect = (option) => {
    setValue("colorId", option.props.value);
  };

  const blocker = useBlocker(
    ({ nextLocation }) => nextLocation.pathname !== "/orders/new-order"
  );

  const onCloseModal = () => {
    blocker.reset();
    setOpenModal(false);
  };

  useEffect(() => {
    setTitle("Agregar Producto");
    setSearchOptions({
      action: null,
    });
    setBtnPrimary({
      label: "Enviar datos",
      action: () => handleSubmit(onSubmit)(),
      icon: "paper-plane",
    });
    setBtnSecondary({
      label: "Cancelar",
      action: () => navigate(-1),
      icon: "ban",
    });

    return () => {
      setBtnSecondary({
        label: "Cancelar",
        action: null,
        icon: "ban",
      });
      setSearchOptions({
        action: () => null,
        hidden: true,
      });
    };
  }, [
    navigate,
    setTitle,
    setSearchOptions,
    setBtnPrimary,
    setBtnSecondary,
    setShowOrder,
    handleSubmit,
    onSubmit,
  ]);

  useEffect(() => {
    if (blocker.state === "blocked") {
      setOpenModal(true);
    }
  }, [blocker.state]);

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      e.preventDefault();
      e.returnValue = "";
      return "";
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => window.removeEventListener("beforeunload", handleBeforeUnload);
  }, []);

  const classes = {
    root: {
      maxWidth: "md",
      ml: bpMdDown ? 0 : 8,
      mt: 8,
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      justifyContent: "space-between",
    },
    inputControl: {
      width: bpMdDown ? "100%" : "47%",
    },
  };

  return (
    <Box component="form" autoComplete="off" noValidate sx={classes.root}>
      <FormControl
        variant="filled"
        sx={[classes.inputControl, { mb: 4 }]}
        {...register("productId", { required: true })}
        error={errors["productId"] !== undefined && productId === ""}
      >
        <SearchableSelect
          service={productService}
          label="Producto"
          value={productId}
          initialValue={[]}
          loadingMessage="Obteniendo productos"
          errorMessage="Error obteniendo los productos"
          disabled={false}
          handleChange={onProductSelect}
        />
        {errors["productId"] !== undefined && productId === "" && (
          <FormHelperText>Este campo es requerido</FormHelperText>
        )}
      </FormControl>
      <FormControl
        variant="filled"
        sx={[classes.inputControl, { mb: 4 }]}
        {...register("sizeId", { required: false })}
        error={errors["sizeId"] !== undefined && sizeId === ""}
      >
        <SearchableSelectOff
          label="Talla"
          initialValue={product.sizes}
          disabled={productId === ""}
          handleChange={onSizeSelect}
        />
        {errors["sizeId"] !== undefined && sizeId === "" && (
          <FormHelperText>Este campo es requerido</FormHelperText>
        )}
      </FormControl>
      <FormControl
        variant="filled"
        sx={[classes.inputControl, { mb: 4 }]}
        {...register("colorId", { required: false })}
        error={errors["colorId"] !== undefined && colorId === ""}
      >
        <SearchableSelectOff
          label="Color"
          initialValue={product.colors}
          disabled={productId === ""}
          handleChange={onColorSelect}
        />
        {errors["colorId"] !== undefined && colorId === "" && (
          <FormHelperText>Este campo es requerido</FormHelperText>
        )}
      </FormControl>
      <FormControl sx={classes.inputControl}>
        <TextField
          label="Cantidad"
          variant="filled"
          sx={{ marginBottom: 4 }}
          {...register("quantity", {
            required: true,
            min: 1,
            valueAsNumber: true,
          })}
          error={errors["quantity"] !== undefined}
          helperText={
            quantity === ""
              ? "Este campo es requerido"
              : errors["quantity"]
              ? "Esta cantidad es inválida"
              : ""
          }
        />
      </FormControl>
      <ConfirmationModal
        icon="triangle-exclamation"
        open={openModal}
        handleClose={onCloseModal}
        handleAccept={() => blocker.proceed()}
        title="Olvidar pedido"
        message="Esta seguro de salir de la página?"
      />
    </Box>
  );
};

export default NewOrderProduct;
