import jwt from "jwt-decode";

export default class TokenService {
  tokenKey = "jwt";

  getToken = () => {
    return localStorage.getItem("token");
  };

  // getUsername = () => {
  //   return localStorage.getItem("userName");
  // }

  // getName = () => {
  //   return localStorage.getItem("name");
  // }

  setToken = (newToken) => {
    localStorage.setItem("token", newToken);
  };

  clearToken = () => {
    localStorage.removeItem("token");
  };

  getAttribute = (atribute) => {
    try {
      return jwt(this.getToken())[atribute];
    } catch (error) {
      return ""
    }
  };

  getExpiresAt = () => {
    return this.getAttribute("exp");
  };
}
