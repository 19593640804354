import { useEffect, useMemo, useCallback, useContext } from 'react'
import {
  Box,
  FormControl,
  FormLabel,
  TextField,
  FormHelperText,
  useMediaQuery
} from '@mui/material'
import { TopBarContext } from "../../providers/TopBarProvider"
import { useTheme } from "@mui/material/styles"
import { useForm } from "react-hook-form"
import { validateField, validatePasswd, toCapitalize } from '../../common/globalFunctions'
import { LoadingContext } from "../../providers/LoadingProvider";
import { ToastContext } from "../../providers/ToastProvider"
import { useNavigate, useLocation } from 'react-router-dom'
import MultipleSelect from '../atoms/MultipleSelect'
import IconSwitch from '../atoms/IconSwitch'
import UserService from '../../services/UserService'
import RoleService from '../../services/RoleService'

const NewUser = ({ edit }) => {

  const { setTitle, setSearchOptions, setBtnPrimary } = useContext(TopBarContext)
  const { setOpenLoading, setTextLoading } = useContext(LoadingContext)
  const { setShowToast, setToastMessage } = useContext(ToastContext)
  const { register, handleSubmit, reset, setValue, watch, formState: { errors } } = useForm({
    defaultValues: {
      id: 0,
      isActive: true,
      roleNames: []
    }
  })
  const isActive = watch("isActive")
  const passwd = watch("password")
  const roles = watch("roleNames")
  const theme = useTheme()
  const navigate = useNavigate()
  const location = useLocation()
  const bpMdDown = useMediaQuery(theme.breakpoints.down("md"))
  const userService = useMemo(()=>new UserService(), [])
  const roleService = useMemo(()=>new RoleService(), [])

  const onSubmit = useCallback(async(data) => {

    setTextLoading(edit ? "Actualizando usuario" : "Agregando usuario")
    setOpenLoading(true)
  
    data.userName = data.userName.toLowerCase()
    data.emailAddress = data.emailAddress.toLowerCase()
    data.address = data.address.toLowerCase()
    data.name = toCapitalize(data.name)
    data.surname = toCapitalize(data.surname)

    try {

      if (edit) {
        data.roleNames = data.roleNames.reduce((itemLoaded, item) => 
          ({ ...itemLoaded, [item.id]: item.normalizedName })
        ,{})
        await userService.update(data)
      } else {
        data.roleNames = data.roleNames.map(rol=>rol.name)
        await userService.create(data)
      }

      setToastMessage([
        {
          severity: "success",
          primary: `Usuario ${edit ? "editado" : "agregado"} exitosamente`
        }
      ])

    } catch {
      setToastMessage([
        {
          severity: "error",
          primary: `Error al ${edit ? "editar" : "agregar"} usuario, por favor intentelo nuevamente`
        }
      ])
    } finally {
      setOpenLoading(false)
      setShowToast(true)
      navigate("/users")
    }

  },[edit, setTextLoading, setOpenLoading, userService, setToastMessage, setShowToast, navigate])

  const loadFields = useCallback(async()=>{
    if(edit) {
      const id = Number(location.search.split("=")[1])
      let user = location.state

      if (!isNaN(id) && user?.id !== id) {
        
        try {

          const res = await userService.get(id)

          user = {...res.data.result}

        } catch {
          setToastMessage([
            {
              severity: "error",
              primary: `Error al intentar obtener los datos del usuario`
            }
          ])
          setShowToast(true)
          navigate("/users")
        }

      }
      
      setValue("id", user.id)
      setValue("userName", user.userName)
      setValue("emailAddress", user.emailAddress)
      setValue("isActive", user.isActive)
      setValue("identification", user.identification)
      setValue("name", user.name)
      setValue("surname", user.surname)
      setValue("address", user.address)
      setValue("phoneNumber", user.phoneNumber)
      setValue("roleNames", [...Object.keys(user.roleNames).map(key=>({
        id: Number(key),
        normalizedName: user.roleNames[key]
      }))])
    } else {
      reset()
    }
  },[location, userService, navigate, setToastMessage, setShowToast, edit, reset, setValue])

  const onUserState = (value) => {
    setValue("isActive", value)
  }

  const onRolesSelect = (selected) => {
    setValue("roleNames", [...selected])
  }

  useEffect(()=>{
    setTitle(edit ? "Editar Usuario" : "Agregar Usuario")
    setSearchOptions({
      action: null
    })
    setBtnPrimary({
      label: edit ? "Actualizar usuario" : "Enviar datos",
      action: ()=>handleSubmit(onSubmit)(),
      icon: edit ? "rotate" : "paper-plane"
    })
  },[edit, setTitle, setSearchOptions, setBtnPrimary, handleSubmit, onSubmit])

  useEffect(()=>{
    loadFields()
  },[loadFields])

  const classes = {
    root: {
      maxWidth: "md",
      ml: bpMdDown ? 0 : 8,
      mt: 8,
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      justifyContent: "space-between"
    },
    switchInput: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "flex-end",
      marginBottom: 2
    },
    inputControl: {
      width: bpMdDown ? "100%" : "47%",
    }
  }

  return <Box component="form" autoComplete='off' noValidate sx={classes.root}>
    <FormControl fullWidth sx={classes.switchInput}>
      <FormLabel>
        Estado: 
      </FormLabel>
      <IconSwitch 
        checked={isActive}
        onChange={(_, value)=>onUserState(value)} 
        color="success"
      />
    </FormControl>
    <FormControl sx={classes.inputControl}>
      <TextField 
        label="Nombre de usuario" 
        variant="filled" 
        sx={{marginBottom: 4}}
        InputLabelProps={edit ? {shrink: true} : null}
        {...register("userName", { required: true, minLength: 5, pattern: /^[a-zA-Z0-9]([._-](?![._-])|[a-zA-Z0-9]){3,18}[a-zA-Z0-9]$/ })}
        error={errors["userName"] !== undefined}
        helperText={validateField(errors["userName"], 5)}
      />
    </FormControl>
    <FormControl sx={classes.inputControl}>
      <TextField 
        label="Correo"
        variant="filled"
        sx={{marginBottom: 4}}
        InputLabelProps={edit ? {shrink: true} : null}
        {...register("emailAddress", { required: true, pattern: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/ })}
        error={errors["emailAddress"] !== undefined}
        helperText={validateField(errors["emailAddress"])}
      />
    </FormControl>
    {!edit && <>
      <FormControl sx={classes.inputControl}>
        <TextField 
          label="Contraseña"
          variant="filled"
          type="password"
          sx={{marginBottom: 4}}
          InputLabelProps={edit ? {shrink: true} : null}
          {...register("password", { required: true, pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/ })}
          error={errors["password"] !== undefined}
          helperText={validatePasswd(errors["password"]?.type, passwd)}
        />
      </FormControl>
      <FormControl sx={classes.inputControl}>
        <TextField 
          label="Confirmar contraseña"
          variant="filled"
          type="password"
          sx={{marginBottom: 4}}
          InputLabelProps={edit ? {shrink: true} : null}
          {...register("passwordC", { required: true, shouldUnregister: true, validate: (passwdC) => passwd === passwdC  })}
          error={errors["passwordC"] !== undefined}
          helperText={validateField(errors["passwordC"])}
        />
      </FormControl>
    </>}
    <FormControl sx={classes.inputControl}>
      <TextField 
        label="Cédula" 
        variant="filled" 
        type='number'
        sx={{marginBottom: 4}}
        InputLabelProps={edit ? {shrink: true} : null}
        {...register("identification", { required: true, min: 0, minLength: 5 })} 
        error={errors["identification"] !== undefined}
        helperText={validateField(errors["identification"], 5)}
      />
    </FormControl>
    <FormControl sx={classes.inputControl}>
      <TextField 
        label="Nombres" 
        variant="filled" 
        sx={{marginBottom: 4}}
        InputLabelProps={edit ? {shrink: true} : null}
        {...register("name", { required: true, pattern: /^[A-Za-z ]+$/ })} 
        error={errors["name"] !== undefined}
        helperText={validateField(errors["name"])}
      />
    </FormControl>
    <FormControl sx={classes.inputControl}>
      <TextField 
        label="Apellidos" 
        variant="filled" 
        sx={{marginBottom: 4}}
        InputLabelProps={edit ? {shrink: true} : null}
        {...register("surname", { required: true, pattern: /^[A-Za-z ]+$/ })} 
        error={errors["surname"] !== undefined}
        helperText={validateField(errors["surname"])}
      />
    </FormControl>
    <FormControl sx={classes.inputControl}>
      <TextField 
        label="Dirección" 
        variant="filled" 
        sx={{marginBottom: 4}}
        InputLabelProps={edit ? {shrink: true} : null}
        {...register("address", { required: true, pattern: /^[#.0-9a-zA-Z\s,-]+$/ })} 
        error={errors["address"] !== undefined}
        helperText={validateField(errors["address"])}
      />
    </FormControl>
    <FormControl sx={classes.inputControl}>
      <TextField 
        label="Celular" 
        type='number'
        variant="filled" 
        sx={{marginBottom: 4}}
        InputLabelProps={edit ? {shrink: true} : null}
        {...register("phoneNumber", { required: true, minLength: 6, pattern: /^[0-9]{6,10}$/ })} 
        error={errors["phoneNumber"] !== undefined}
        helperText={validateField(errors["phoneNumber"], 6)}
      />
    </FormControl>
    <FormControl 
      variant="filled" 
      sx={[classes.inputControl, { mb: 4 }]} 
      {...register("roleNames", {required: true})}
      error={errors["roleNames"] !== undefined && roles.length === 0}
    >
      <MultipleSelect
        primaryKey="normalizedName"
        service={roleService}
        label="Roles"
        defaultValue={roles}
        initialValue={[]}
        loadingMessage="Obteniendo roles"
        errorMessage="Error obteniendo los roles"
        disabled={false}
        handleChange={onRolesSelect}
      />
      {errors["roleNames"] !== undefined && roles.length === 0 && <FormHelperText>Este campo es requerido</FormHelperText>}
    </FormControl>
  </Box>
}

export default NewUser